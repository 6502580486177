import React from 'react'
import Section1 from './images/RegisterNew.png'
import Section2 from './images/PlayNew.png'
import Section3 from './images/WinNew.png'
import { useTranslation } from "react-i18next";

const CardSection = () => {
  const {t} = useTranslation()

  return (
    <div class="container">
    <section class="section-connect">
      <div class="row flex-column ">
        <div class="col my-4">
          <h1 class="heading-primary text-center">{t('landing.how_it_Works')}</h1>
        </div>
        <div class="col mb-5 landing-rtl">
          <p class="text-white my-0">{t('landing.with_the_most_popular_esports')}</p>
          <p class="text-white">{t('landing.join_now_to_connect_compete')}</p>
        </div>
      </div>
      <div class="row connect-card gx-5 row-cols-1 row-cols-md-3 mb-5 g-4 landing-row-rtl">
        <div class="col">
          <div class="card">
            <img src={Section1} class="card-img-top" alt="..." />
            <div class="card-body">
              <h3 class="title_secondary text-white blue-line" >{t('landing.register')}</h3>
              <p class="text-white mt-4">{t('landing.register_mobily')}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <img src={Section2} class="card-img-top" alt="..." />
            <div class="card-body">
              <h3 class="text-white title_secondary blue-line" >{t('landing.play')}</h3>
              <p class="text-white mt-4" >{t('landing.play_and_compete')}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <img src={Section3} class="card-img-top" alt="..." />
            <div class="card-body">
              <h3 class="title_secondary text-white blue-line" >{t('landing.win')}</h3>
              <p class="text-white mt-4" >{t('landing.win_and_redeem')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

export default CardSection