import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../Utilities/logFunc";
import {
  getAllFreeAvatar,
  getHeaderApi,
  requestUpdateProfile,
} from "../../Utilities/controller";
import { errors } from "../../Utilities/error";
import { routes } from "../../Utilities/routesFb";
import { useNavigate } from "react-router";
import { url } from "../../Utilities/url";
import Loader from "../Common/Loader";
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import LoginLandingPage from "../LandingPage/LoginLandingPage";
import { useTranslation } from "react-i18next";
import { encryptKey } from "../../Utilities/encryptDecrypt";
import { useProfileDataContext } from "../HomePage/ProfileContext";

import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents"; 
const Avartar = () => {
  // console.log("************",typeof(localStorage.getItem('d11_username')),localStorage.getItem('d11_username') != 'undefined', localStorage.getItem('d11_username') != 'undefined' || localStorage.getItem('d11_username') !=undefined ? localStorage.getItem('d11_username') : "")
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState([]);
  const [show, setShow] = useState(true);
  const [userName, setUserName] = useState(localStorage.getItem('d11_username') !== 'undefined' && localStorage.getItem('d11_username') !==undefined ? localStorage.getItem('d11_username') : "" );
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [avatarId, setAvatarId] = useState("");
  const [avatarError, setAvatarError] = useState("");
  const [pinError, setPinError] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [emailError, setEmailError] = useState()
  const {t} = useTranslation();
  // const {updateProfileData} = useProfileDataContext()
  const onChangeHandler = (e) => {
    setUserName((prev) => e.target.value);
    setError("");
  };

  const handleClose = () => {
    setShow(false);
    window.location.href = "/";
    // logout();
  };
  const fetchAvatar = async () => {
    try {
      const res = await getAllFreeAvatar();
      func("avatar", res);
      setAvatar(res?.data?.list);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
      // logout()
      setError(error?.response?.data?.errors?.[0]?.msg);
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchAvatar();
  }, []);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let result = validateEmail(email);
    func("email result", result);
    // console.log(pin, confirmPin);
    if (userName == "") {
      setError(t("error_enter_gamerji_username"));
    } 
    else 
    if (userName != "" && userName?.length>16 )  {
       console.log("userName",userName?.length)
      setError(t('error_Please_enter_username_max_characters'));
      return;
    }
    else 
    if (!result)  {
      setEmailError(t('error_Please_enter_a_valid_email_address'));
      return;
    }
    else if (avatarId == "") {
      setAvatarError(t("error_Select_your_Gamerji_Avatar"));
    } else if (pin?.length < 6 || confirmPin?.length < 6) {
      setPinError(t("error_pin_length"));
    } else if (pin !== confirmPin) {
      setPinError(t("error_pin_mismatch"));
    } 
    
    else if (userName !== "" && avatarId !== "") {
      callCreateProfile();
    }
  };

  const callCreateProfile = async () => {
    // console.log(encryptKey(pin));
    const encPin = await encryptKey(pin);
    var payload = {
      gamerjiName: userName,
      isDetailsFilled: true,
      avatar: avatarId,
      pin: encPin,
      email:email
    };

    func("==========", payload);
    setLoading(true);
    try {
      const res = await requestUpdateProfile(payload);
      // console.log("callCreateProfile response:", res);
      if (res?.success) {
        // localStorage.setItem('userData', JSON.stringify(res?.item))
        // toastSuccess(res?.msg);
        // navigate("/home");
        // setLoading(false)
        eventTracking(events.SIGNUP_COMPLATE,{
          MG_GamerJiName: userName,
          MG_Avatar: avatarId,
          MG_Pin:encPin,
          MG_UserID : localStorage.getItem('user-id') ? localStorage.getItem('user-id') : ''
        });
 
        const data = await getHeaderApi();
        if (data?.data) {
          // updateProfileData(data?.data)
          // navigate("/home/get-premium");
          window.location.href = "/home/get-premium"

        }
        // setTimeout(()=>{
        //   navigate("/home/get-premium")
        // },1000)
      }
    } catch (error) {
      setLoading(false);
      func("error", error);
      if (
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
      )
        logout();
      setPinError(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const onClickHandler = (item,itemData) => {
    setAvatarId(item);
    eventTracking(events.AVATAR_SELECT,{
      MG_Avatar: item,
      MG_Avatar_Name:itemData?.name,
      MG_UserID : localStorage.getItem('user-id') ? localStorage.getItem('user-id') : ''
    });

    setAvatarError("");
  };
  // console.log(isLoading)
  if (isLoading) return <Loader />;
  return (
    <LoginLandingPage>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="auth-modal avatarModal modal fade "
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header> */}
        <Modal.Body class="mt-5">
          <button
            type="button"
            class="btn-close mt-4"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <div class="row justify-content-center py-4">
            <div class="col-12">
              <div class="tab-content auth-content text-center">
                <h1 class="h2 mb-4">{t("login.welcome_to_mobily")}</h1>
                <div class="row justify-content-center mb-5 mt-2">
                  <div class="col-lg-6 col-sm-6">
                    <input
                      type="text"
                      class="form-control"
                      id="inputCouponCode"
                      placeholder={t("login.mobily_name")}
                      value={userName}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    {error && <p className="error mb-0">{error}</p>}
                  </div>
                </div>
                <div class="row justify-content-center mb-5">
                  <div class="col-lg-6 col-sm-6">
                    <input
                      type="email"
                      class="form-control"
                      id="inputemail"
                      placeholder={t("Profile.enter_your_email_id")}
                      value={email}
                      onChange={(e) =>{ setEmail(e.target.value); setEmailError('')}}
                    />
                    {emailError && <p className="error">{emailError}</p>}
                  </div>
                </div>
                <h4 class="mb-3">
                  {t("login.now_choose_cool_avatar_to_go_with_your_profile")}
                </h4>
                <h4 class="mb-3">
                  {t("login.you_can_always_change_this_or_unlock_new_ones")}
                </h4>
                <div class="row justify-content-center ">
                  <div class="col-lg-6 col-md-8 px-lg-0">
                    <div class="radioBlock">
                      <div class="radioInner">
                        {avatar?.map((item, index) =>
                          item?.isBuy ? (
                            <div class="form-check" key={index}>
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={`radio${index}`}
                                checked={item?._id == avatarId}
                                onChange={(e) => onClickHandler(item?._id,item)}
                              />
                              <label
                                class="form-check-label"
                                for={`radio${index}`}
                              >
                                <img
                                  src={url.imageUrl + item?.img?.default}
                                  alt=""
                                  style={{ width: "60px", height: "120px" }}
                                />
                                <span class="d-block mt-2">{item?.name}</span>
                              </label>
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                      {avatarError && <p className="error">{avatarError}</p>}
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center mt-2">
                  <div class="col-lg-6 col-md-8 px-lg-0">
                    <div class="d-grid">
                      <label
                        for="inputMobileNumber text-align-left"
                        className="form-label"
                        style={{ textAlign: "left" }}
                      >
                        {t("login.add_your_pin")}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        style={{
                          // "-webkit-text-security": "circle",
                          fontSize: "1.1rem",
                        }}
                        id="inputMobileNumber"
                        placeholder={t("login.enter_pin")}
                        name="pin"
                        onKeyDown={(e) => {
                          const isNumericKey = !isNaN(parseInt(e.key));
                          const isControlKey =
                            e.key === "Backspace" || e.key === "Delete" || e.key === "ArrowLeft" || e.key === "ArrowRight";
                          if (!isNumericKey && !isControlKey) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setPin(e.target.value)}
                        maxLength={6}
                        value={pin.slice(0, 6)}
                      />
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center mt-2" style={{marginBottom:"20px" }}>
                  <div class="col-lg-6 col-md-8 px-lg-0">
                    <div class="d-grid">
                      <label
                        for="inputMobileNumber"
                        className="form-label"
                        style={{ textAlign: "left" }}
                      >
                        {t("login.confirm_your_pin")}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        style={{
                          // "-webkit-text-security": "circle",
                          fontSize: "1.1rem",
                        }}
                        id="inputMobileNumber"
                        placeholder={t("login.enter_confirm_pin")}
                        name="pin"
                        onKeyDown={(e) => {
                          const isNumericKey = !isNaN(parseInt(e.key));
                          const isControlKey =
                            e.key === "Backspace" || e.key === "Delete" || e.key === "ArrowLeft" || e.key === "ArrowRight";
                          if (!isNumericKey && !isControlKey) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setConfirmPin(e.target.value)}
                        maxLength={6}
                        value={confirmPin.slice(0, 6)}
                      />
                    </div>
                  </div>
                  {pinError && <p className="error mb-0">{pinError}</p>}
                </div>

                <div class="row justify-content-center mt-2">
                  <div class="col-lg-5 mt-1">
                    <div class="d-grid">
                      <button
                        type="submit"
                        class="btn btn-primary btn-arrow"
                        onClick={(e) => submitHandler(e)}
                      >
                        {" "}
                        {t("login.submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </LoginLandingPage>
  );
};

export default Avartar;
