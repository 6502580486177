import React, { useEffect, useState } from 'react'
import LandingPage from './LandingPage'
import { url } from '../../Utilities/url'
import axios from 'axios'
import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents"; 
import { useTranslation } from 'react-i18next';
const TermsAndCondition = () => { 
    useEffect(()=>{
        EventTracking(); 
      },[]);
      const EventTracking=()=>{
        eventTracking(events.TERM_N_CONDITION);
      }
      const {t} = useTranslation()
    const [data, setData] = useState()
    const fetchData = async()=>{
        try{
            const res = await axios.post(url.apiUrl+'public/page/terms-and-conditions', {
                headers:{
                   
                   'company-code':'ME'
                }
            })
            setData(res?.data?.item?.description)
        }
        catch(error){
            console.log(error)
        }
    }
    useEffect(()=>{
        fetchData()
    },[])
  return (
    <LandingPage>
        <div style={{paddingTop:'7rem', paddingBottom:'1rem' , display:'flex',flexDirection:'column',  justifyContent:'center', alignItems:'center', textAlign:'left'}}>
            <h4>{t('right_hand_drop_down.terms_conditions')}</h4>
            <div className='col-10' dangerouslySetInnerHTML={{ __html: data }} >

            </div>
        </div>
    </LandingPage>
  )
}

export default TermsAndCondition