import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { getGameTypeList } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { useTranslation } from "react-i18next";

const GameTypeTab = ({
  handleClickGameType,
  gameId,
  setGameTypeLength,
  setActiveTab,
  setTournamentId,
  gameType,
  handlerActive
}) => {
  func("gameType", gameType);
  const {t} = useTranslation()
  // const [gameType, setGameType] = useState([]);

  const [active, setActive] = useState(0);
  const [typeLength, setTypeLength] = useState(0);
  const [activeId, setActiveId] = useState("tournament");

  // const handlerActive = (type, ind) => {
  //   func(type, "handlerActive type==", type?._id);
  //   setActive(ind);
  //   handleClickGameType(type, type?._id);
  //   setActiveTab(ind);
  // };

  const handlerGameTypeLength = (ind) => {
    func("handlerGameTypeLength index", ind)
    setGameTypeLength(ind);
    setTypeLength(ind);
  };

  // const getGameTypeByGameList = async () => {
  //   try {
  //     let data = {
  //       search: {
  //         gameId: gameId,
  //       },
  //       pagination: {
  //         sortby: "order",
  //         sort: "asc",
  //       },
  //     };

  //     const res = await getGameTypeList(data);

  //     if (res?.data?.data) {
  //       setGameType(res?.data?.data);
  //       // setActiveId(res?.data?.data[0]._id);
  //       setTypeLength(res?.data?.data?.length)
  //       setActive(res?.data?.data?.length)
  //       handlerGameTypeLength(res?.data?.data?.length || 0);
  //     }
  //   } catch (error) {
  //     func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
  //   }
  // };

  useEffect(() => {
    // getGameTypeByGameList();
    setActive(gameType?.length)

    handlerGameTypeLength(gameType?.length || 0);

  }, [gameType]);

  return (
    <ul className="nav nav-tabs row" id="tournamentTypeTab" role="tablist">
      {gameType.map((item, ind) => (
        <li
          className="nav-item col d-grid"
          role="presentation"
          onClick={() =>{
            
            handlerActive(ind)
            setActive(ind)
            // handlerActive(item, ind)
          }}
          key={ind}
        >
          <button
            className={`nav-link ${ind == active ? "active" : ""}`}
            role="tab"
            aria-controls="solo-tab-pane"
            aria-selected="true"
          >
            {item?.name}
          </button>
        </li>
      ))}
      <li
        className="nav-item col d-grid"
        role="presentation"
        onClick={() => handlerActive(gameType?.length)}
        key={typeLength}
      >
        <button
          className={`nav-link ${active == gameType?.length ? "active" : ""}`}
          role="tab"
          aria-controls="solo-tab-pane"
          aria-selected="true"
        >
          {t('header.tournaments')}
        </button>
      </li>
    </ul>
  );
};

export default GameTypeTab;
