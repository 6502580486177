import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { json, useNavigate } from "react-router";
import moment from "moment";
import { url } from "../../../Utilities/url";
import {
  gamerjiTournamentJoin,
  getHeaderApi,
  getUserInGameName
} from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PayIcon from "../../../assets/images/pay-icon-svg.svg";
import TeamRegisterModal from "./TeamRegisterModal";
import Loader from "../../Common/Loader";
import Master from "../../../assets/images/Master.png";
import Casual from "../../../assets/images/Casual.png";
import Pro from "../../../assets/images/Pro.png";
import { useTranslation } from "react-i18next";
import { useProfileDataContext } from "../ProfileContext";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 
import { logout } from "../../../Utilities/logout";
import { toastError } from "../../../Utilities/toast";
const TournamentConfirmationModal = ({
  showTournamentConfirmationModal,
  setShowTournamentConfirmationModal,
  tournaments,
  match,
  walletData,
  userInGameName,
  setShowPremiumPopupTournament,
  setShowJVC,
}) => {
  // console.log(userInGameName)
  let matchData = match;
  let premiumUser = JSON.parse(localStorage.getItem("premiumUser")) || false;
  const [show, setShow] = useState(showTournamentConfirmationModal);
  const navigate = useNavigate();
  const handleClose = () => {
    setShowTournamentConfirmationModal((prev) => false);
    setShow(false);
    if (setShowJVC) {
      setShowJVC(false);
    }
  };

  const [showTeamRegisterModal, setShowTeamRegisterModal] = useState(false);
  const [postData, setPostData] = useState(null);
  const [noOfPlayer, setNoOfPlayer] = useState(0);
  const [game, setGame] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { profileData, updateProfileData } = useProfileDataContext();
  const [activeInGameName, setActiveInGameName] = useState(userInGameName);
  const [isBusy, setIsBusy] = useState(false)
  // console.log(tournaments)

  const fetch = async() => {
    let data = {
      search: {
        game: localStorage.getItem("activeGameId"),
        "user": profileData?.item?.user_id,
      },
    };
    const res = await getUserInGameName(data);
    if (res?.data?.data && res?.data?.data?.length > 0) {
      setActiveInGameName(res?.data?.data?.[0]?.userINGameName);
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const handlerJoinTournament = () => {
    // console.log("clikced", userInGameName);
    eventTracking(events.JOIN_TOURNAMENT,{
      MG_GameName:tournaments?.gameAry?.[0]?.name,
      MG_GameID:tournaments?.gameAry?.[0]?._id,
      MG_TournamentID:tournaments?._id
    });
    //MG_GameName, MG_GameID, MG_TournamentID, MG_UserID, MG_Timestamp
    // setShowTournamentConfirmationModal(false)
    if (!premiumUser && walletData?.walletBalance < walletData?.toPay) {
      toastError("Insufficient Balance");
      navigate("/home/get-premium");
    } else {
      setLoading(true);
      setIsBusy(true)
      // let userData = localStorage.getItem("userData");
      // userData = JSON.parse(userData);
      let data = {
        contest: matchData?._id,
        currencyCode: tournaments?.currency?.[0]?.inCurrency?._id,
        event: tournaments?._id,
        inGameUserName: activeInGameName,
        isActive: true,
        type: "event",
        uniqueIGN: activeInGameName,
        user: profileData?.item?.user_id,
      };
      if (tournaments?.gameTypeAry?.players > 1) {
        setNoOfPlayer(tournaments?.gameTypeAry?.players || 0);
        setPostData(data);
        setGame(tournaments?.gameAry?.[0]?._id);
        setShowTeamRegisterModal(true);
        setIsBusy(false)
        setLoading(false);
      } else {
        joinTournament(data);
      }
    }
  };

  const joinTournament = async (data) => {
    try {
      const res = await gamerjiTournamentJoin(data);
      if (res?.data?.success) {
        setLoading(false);
        setIsBusy(false)
        const resProfile = await getHeaderApi();
        console.log(resProfile?.data);
        if (resProfile?.data) updateProfileData(resProfile?.data);
        setShowTournamentConfirmationModal((prev) => false);
        navigate("/home/tournament-details/" + data?.event);
      } else {
        setLoading(false);
        setIsBusy(false)
        toastError(res?.data?.errors?.[0]?.msg);
        setShowTournamentConfirmationModal((prev) => false);
      }
      func(res);
    } catch (error) {
      setLoading(false);
      setIsBusy(false)
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      if (
        error?.response?.data?.errors?.[0]?.data ==
        "events.validation.MAX_FREE_TRANSACTION"
      ) {
       
        setShowPremiumPopupTournament(true);
      } else toastError(error?.response?.data?.errors?.[0]?.msg);
      setShowTournamentConfirmationModal((prev) => false);
    }
  };

  // const handlerJoinTournament = () => {
  //   navigate('/home/tournament-details/' + '1328478')
  // }

  if (isLoading) return <Loader />;

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="tournamentModal modal fade"
        centered
      >
        <Modal.Body>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <div class="row justify-content-center">
            <div class="col-lg-11 col-12 px-lg-4">
              {premiumUser == true ? (
                <div className="text-center">
                  <img
                    style={{ width: "7.5rem", height: "7.5rem" }}
                    src={
                      localStorage.getItem("planName") ==
                      process.env.REACT_APP_BRONZE
                        ? Casual
                        : localStorage.getItem("planName") ==
                          process.env.REACT_APP_SILVER
                        ? Pro
                        : localStorage.getItem("planName") ==
                          process.env.REACT_APP_GOLD
                        ? Master
                        : ""
                    }
                  />
                  <h2 className="d11-premium mt-2  mb-4">
                    {t("tournaments.premium")}
                  </h2>
                </div>
              ) : (
                ""
              )}

              <div class="title ">
                <h3 class="text-center bodyBoldFont mb-4 pb-3">
                  {tournaments?.title}
                </h3>
              </div>
              <div class=" text-center">
                <div class="d-flex justify-content-between">
                  <h4 class="mb-3">
                    {tournaments?.gameAry?.[0]?.name} -{" "}
                    {tournaments?.gameTypeAry?.name}
                  </h4>
                  <h4 class="mb-3">
                    <span>{moment(matchData?.date).format("Do MMM YYYY")}</span>
                    <span> {moment(matchData?.time).format("hh:mm A")}</span>
                  </h4>
                </div>
                <div class="d11-round-text p-4 text-start">
                  <h3 class="mb-2 bodyBoldFont">{t("contest.confirmation")}</h3>
                  <h4 class="mb-0 d-flex align-items-center">
                    {tournaments?.currency?.[0]?.inCurrency?.code === "MOBD"
                      ? t("contest.diamond_balance")
                      : t("contest.coin_balance")}{" "}
                    =
                    <span class="icon px-2">
                      <img
                        class="icon-20 d11-tournament-coin-img"
                        src={
                          url?.imageUrl +
                          tournaments?.currency?.[0]?.inCurrency?.img?.default
                        }
                        alt=""
                      />
                    </span>
                    {walletData?.walletBalance}
                  </h4>
                </div>
                <div class="d-flex flex-column text-start mt-4 pt-2">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between px-0 py-3">
                      <h4 class="bodyBoldFont mb-0">
                        {t("contest.entry_fee")}
                      </h4>
                      <h4 class="mb-0">
                        <span class="icon me-2 d11-tournament-coin-img">
                          {tournaments?.entryFee > 0 ? (
                            <img
                              className="icon-20 d11-tournament-coin-img"
                              src={
                                url.imageUrl +
                                tournaments?.currency?.[0]?.inCurrency?.img
                                  ?.default
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                        </span>
                        {tournaments?.entryFee > 0
                          ? tournaments?.entryFee
                          : t("tournaments.free")}
                      </h4>
                    </li>
                    <li class="list-group-item d-flex justify-content-between px-0 py-3">
                      <h4 class="bodyBoldFont mb-0">
                        <span class="icon me-2">
                          <img class="icon-24" src={PayIcon} alt="" />
                        </span>
                        {t("contest.to_pay")}
                      </h4>
                      <h4 class="mb-0">
                        <span class="icon me-2 d11-tournament-coin-img">
                          {console.log("premi",premiumUser)}
                          {tournaments?.entryFee > 0 &&
                          premiumUser === false ? (
                            <img
                              className="icon-20 d11-tournament-coin-img"
                              src={
                                url.imageUrl +
                                tournaments?.currency?.[0]?.inCurrency?.img
                                  ?.default
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                        </span>
                        {/* {func(premiumUser, typeof premiumUser)} */}
                        {tournaments?.entryFee > 0 && premiumUser === false
                          ? walletData?.toPay
                          : t("tournaments.free")}
                      </h4>
                    </li>
                    {func(
                      "toucode 207",
                      tournaments?.currency?.[0]?.inCurrency?.code
                    )}
                    {premiumUser == true && (
                      <li class="list-group-item d-flex justify-content-between px-0 py-3">
                        <h4 class="bodyBoldFont mb-0">
                          {/* {tournaments?.currency?.[0]?.inCurrency?.code ===
                          "MOBC"
                            ? "Coin"
                            : "Diamond" //tournaments?.currency?.[0]?.inCurrency?.code
                          } */}
                            {" "}
                          {t('tournaments.saved', {coin:`${tournaments?.currency?.[0]?.inCurrency?.code ===
                          "MOBC"
                            ? "Coin"
                            : "Diamond"}`})}
                        </h4>
                        <h4 class="mb-0">
                          <span class="icon me-2 d11-tournament-coin-img">
                            {tournaments?.entryFee > 0 ? (
                              <img
                                className="icon-20 d11-tournament-coin-img"
                                src={
                                  url.imageUrl +
                                  tournaments?.currency?.[0]?.inCurrency?.img
                                    ?.default
                                }
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          {func("wallet data", walletData)}
                          {tournaments?.entryFee > 0
                            ? walletData?.toPay
                            : t("tournaments.free")}
                        </h4>
                      </li>
                    )}
                  </ul>
                </div>
                <div class="row justify-content-center mt-4 pt-2">
                  <div class="col-lg-7">
                    <div class="d-grid">
                      <button
                        class="btn btn-primary btn-arrow"
                        onClick={handlerJoinTournament}
                        disabled={isBusy}
                      >
                        {tournaments?.isJoined
                          ? t("tournaments.joined")
                          : t("tournaments.join_tournament")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showTeamRegisterModal && (
        <TeamRegisterModal
          showTeamRegisterModal={showTeamRegisterModal}
          setShowTeamRegisterModal={setShowTeamRegisterModal}
          postData={postData}
          type={"event"}
          noOfPlayer={noOfPlayer}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
          game={game}
          setShowPremiumPopupTournament={setShowPremiumPopupTournament}
          userInGameName={userInGameName}
        />
      )}
    </>
  );
};

export default TournamentConfirmationModal;
