import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Rules from "../../Common/Rules";

const RulesModal = ({
  showRulesModal,
  setShowRulesModal,
  selectedTournament,
}) => {
  const [show, setShow] = useState(showRulesModal);
  const handleClose = () => {
    setShowRulesModal((prev) => false);
    setShow(false);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="tournamentModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="card rulesCard">
              <div class="card-body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedTournament?.rules?.rules ? selectedTournament?.rules?.rules : selectedTournament?.rules,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RulesModal;
