import React, { useEffect, useState } from "react";
import {
  getLeaderBorad,
  getScrimUserDetails,
  updateScrimRating,
  viewScrimById,
} from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import Loader from "../../Common/Loader";
import Sad from "../../../assets/images/sad-icon-svg.svg";
import NotGood from "../../../assets/images/notGood-icon-svg.svg";
import Ok from "../../../assets/images/ok-icon-svg.svg";
import Happy from "../../../assets/images/happy-icon-svg.svg";
import VeryHappy from "../../../assets/images/veryHappy-icon-svg.svg";
import { url } from "../../../Utilities/url";
import Rank from "../../../assets/images/Rank.png";
import Gamer from "../../../assets/images/username-icon-svg.svg";

import moment from "moment";
import FriendsShare from "./FriendsShare";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import CopyIcon from "../../../assets/images/copy-icon-svg.svg";
import Casual from "../../../assets/images/d-casual.png";
import Master from "../../../assets/images/d-master.png";
import Pro from "../../../assets/images/d-pro.png";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import { logout } from "../../../Utilities/logout";
import { toastError, toastInfo, toastSuccess } from "../../../Utilities/toast";

const ScrimDetailInfo = ({ id }) => {
  const { profileData, updateProfileData } = useProfileDataContext()
  const {t} = useTranslation()

  const [scrimDetailData, setScrimDetailData] = useState([]);
  const [ScrimCaptainData, setScrimCaptianData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [eventRate, setEventRate] = useState(null);
  const [playerData, setPlayerdata] = useState([]);
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)

  const remainingPlayers =
    scrimDetailData?.totalPlayers - scrimDetailData?.totalJoinedPlayers;
  const premiumUser = JSON.parse(localStorage.getItem("premiumUser"));
  useEffect(() => {
    fetchScrimDetails();
    fetchScrimUserData(1);
  }, []);

  // useEffect(() => {
  //   fetchScrimDetails();
  //   fetchScrimUserData();

  // }, [eventRate])

  const fetchScrimDetails = async () => {
    setLoading(true);
    try {
      const res = await viewScrimById({ id: id });
      func("scrimData", res);

      if (res?.success) {
        
        setScrimDetailData(res?.data?.[0]?.param);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("fetchScrimDetails error", error?.response);
      setLoading(false);
    }
  };

  const fetchScrimUserData = async (page) => {
    setLoading(true);
    try {
      const payload = {
        scrimId: id,
        page: page,
        limit: 10,
        sortBy: "createdAt",
        sort: "asc",
      };
      let body = {
        scrimId: id,
        userId: profileData?.item?.user_id,
      };
      const userDetail = await getScrimUserDetails(body);
      func("userDetail", userDetail?.data?.data);
      setScrimCaptianData(userDetail?.data?.data);
      setEventRate(userDetail?.data?.data?.rating?.rate);
      
      func("rating", userDetail?.data?.data?.rating?.rate);
      const res = await getLeaderBorad(payload);
      func("players", res?.data);
      if (res?.data) {
        //   setScrimCaptianData(res?.data?.captainData);
        setPlayerdata([...playerData,...res?.data]);
        console.log(res?.totalPages)
        setTotalPage(res?.totalPages)
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("fetchScrimUserData error", error?.response);
      toastError(error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };
  const findPercentage = (data) => {
    let totalPlayer = data?.noOfPlayerCapacity || 0;
    let totalPlayerJoined = data?.noOfJoinedPlayer || 0;

    let percentage = (totalPlayerJoined / totalPlayer) * 100;

    return percentage + "%";
  };

  const rating = async (rate) => {
    func("called");
    try {
      let data = {
        rating: rate,
        scrim: id,
        user: profileData?.item?.user_id,
      };
      const res = await updateScrimRating(data);

      func("rating", res);
      setEventRate(rate);
      toastSuccess(t('success_rating_successfully'));
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("rating err", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const loadMore = ()=>{
    setPage(page + 1)
    fetchScrimUserData(page+1)
  }

  // useEffect(()=>{
  //   fetchScrimUserData()
  // },[eventRate])
  return (
    <>
      {" "}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div class="col-lg-5 pe-lg-5">
            <div class="title">
              <h3 class="bodyBoldFont mb-3">{t('private_contest.scrim_detail')}</h3>
            </div>
            <div class="card tournametParticipationCard tournamentSoloCard contestTournamentSoloCard">
              <div class="card-header">
                <h4 class="card-title mb-0">{scrimDetailData?.title}</h4>
                {/* <a href="#" class="rulesLink fs-5">ID: 2HWEJV</a> */}
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="card-content">
                      <div class="row justify-content-between py-2">
                        <div class="col-3">
                          <label for="" class="d-block h6">
                            {t('contest.date')}
                          </label>
                          <h6 class="bodyBoldFont mb-0">
                            {moment(scrimDetailData?.date).format("DD/MM/YYYY")}
                          </h6>
                        </div>
                        <div class="col-3 dividersX">
                          <label for="" class="d-block h6">
                          {t('contest.time')}
                          </label>
                          <h6 class="bodyBoldFont mb-0">
                            {moment(scrimDetailData?.time).format("hh:mm A")}
                          </h6>
                        </div>
                        <div class="col-3 dividersRight">
                          <label for="" class="d-block h6" style={{display:'block',width:'100%',whiteSpace: "nowrap", overflow: "hidden",
  textOverflow: "ellipsis"}}>
                            {/* {scrimDetailData?.titles[0]?.name} */}
                            {
                            // scrimDetailData?.titles?.[0]?.name?.length > 9
                            //   ? scrimDetailData?.titles?.[0]?.name?.substring(
                            //       0,
                            //       9 - 3
                            //     ) + "..."
                            //   : 
                              scrimDetailData?.titles?.[0]?.name}
                          </label>
                          <h6 class="bodyBoldFont mb-0" style={{display:'block',width:'100%',whiteSpace: "nowrap", overflow: "hidden",
  textOverflow: "ellipsis"}}>
                            {/* {item?.titles?.[0]?.value} */}
                            {
                            // scrimDetailData?.titles?.[0]?.value?.length > 9
                            //   ? scrimDetailData?.titles?.[0]?.value?.substring(
                            //       0,
                            //       9 - 3
                            //     ) + "..."
                            //   : 
                              scrimDetailData?.titles?.[0]?.value}
                          </h6>
                        </div>
                        <div class="col-3">
                          <label for="" class="d-block h6" style={{display:'block',width:'100%',whiteSpace: "nowrap", overflow: "hidden",
  textOverflow: "ellipsis"}}>
                            {/* {item?.titles?.[1]?.name} */}
                            {
                            // scrimDetailData?.titles?.[1]?.name?.length > 9
                            //   ? scrimDetailData?.titles?.[1]?.name?.substring(
                            //       0,
                            //       9 - 3
                            //     ) + "..."
                            //   : 
                              scrimDetailData?.titles?.[1]?.name}
                          </label>
                          <h6 class="bodyBoldFont mb-0">
                            {/* {item?.titles?.[1]?.value} */}
                            {
                            // scrimDetailData?.titles?.[1]?.value?.length > 9
                            //   ? scrimDetailData?.titles?.[1]?.value?.substring(
                            //       0,
                            //       9 - 3
                            //     ) + "..."
                            //   :
                               scrimDetailData?.titles?.[1]?.value}
                          </h6>
                        </div>
                      </div>
                      <div class="row justify-content-between py-2 mt-1">
                        <div class="col-4">
                          <label for="" class="d-block h6">
                          {t('contest_details.players')}
                          </label>
                          <div class="d-flex align-items-center poolPrizeBox">
                            <h6 class="bodyBoldFont mb-0">
                              {scrimDetailData?.noOfPlayerCapacity || 0}
                            </h6>
                          </div>
                        </div>
                        <div class="col-4 dividersX d-flex">
                          <div class="">
                            <label for="" class="d-block h6">
                              {t('contest.winners')}
                            </label>
                            <div class="d-flex align-items-center poolPrizeBox">
                              <h6 class="bodyBoldFont mb-0">
                                {scrimDetailData?.noOfWinner || 0}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-4 d-flex">
                          <div class="">
                            <label for="" class="d-block h6">
                            {t('contest.id')}
                            </label>
                            <div
                              class="d-flex align-items-center useCoinBox"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  scrimDetailData?.shortCode
                                );
                                toastInfo(t('info_Copied'));
                              }}
                            >
                              <h6 class="bodyBoldFont mb-0">
                                {scrimDetailData?.shortCode}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <div
                            class="progress"
                            role="progressbar"
                            aria-label="Basic example"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              class="progress-bar bg-info"
                              style={{ width: findPercentage(scrimDetailData) }}
                            ></div>
                          </div>
                          <div class="d-flex justify-content-between mt-2">
                            <h6>
                            {t('contest_details.players_remaining',{players:scrimDetailData?.noOfPlayerCapacity -
                                scrimDetailData?.noOfJoinedPlayer || 0})}
                            </h6>
                            <h6>
                            {t('contest_details.players_joined',{players:scrimDetailData?.noOfJoinedPlayer || 0})}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-white">
                <div class="bg-secondary-subtle pt-2 pb-1 box">
                  <div class="row justify-content-center py-2 mt-1">
                    <div class="col-4 text-center dividersRight">
                      <label for="" class="d-block h6 mb-10">
                      {t('contest.room_id')}
                      </label>
                      <h6 class="bodyBoldFont mb-0 d-flex align-items-center justify-content-center">
                      {scrimDetailData?.room?.id || "-"}
                                      {scrimDetailData?.room?.id ? (
                                        <span
                                          class="icon ms-2"
                                          onclick="copyText();"
                                        >
                                          <img
                                            src={CopyIcon}
                                            alt=""
                                            className="cursor"
                                            onClick={() => {
                                              navigator.clipboard.writeText(
                                                scrimDetailData?.room?.id
                                              );
                                              toastInfo(t('info_Copied'));
                                            }}
                                           
                                          />
                                        </span>
                                      ) : (
                                        ""
                                      )}
                      </h6>
                    </div>
                    <div class="col-4 text-center">
                      <label for="" class="d-block h6 mb-10">
                      {t('contest.password')}
                      </label>
                      <h6 class="bodyBoldFont d-flex mb-0 align-items-center justify-content-center">
                      {scrimDetailData?.room?.password || "-"}
                                      {scrimDetailData?.room?.password? (
                                        <span
                                          class="icon ms-2"
                                          onclick="copyText();"
                                        >
                                          <img
                                            src={CopyIcon}
                                            alt=""
                                            className="cursor"
                                            onClick={() => {
                                              navigator.clipboard.writeText(
                                                scrimDetailData?.room?.password
                                              );
                                              toastInfo(t('info_Copied'));
                                            }}
                                          />
                                        </span>
                                      ) : (
                                        ""
                                      )}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card userProfileCard mt-5">
              <div class="card-header">
                <div class="row justify-content-between align-items-center">
                  <div class="col-lg-5 col-4 d-flex" style={{ width: "70%" }}>
                    <div class="text-center">
                      <span class="icon mb-2 d-block">
                        <img src={Gamer} alt="" />
                      </span>
                      <h5 class="bodyNormalFont mb-0">{t('contest_details.mobily_username')}</h5>
                    </div>
                  </div>
                  <div class="col-lg-6 col-8" style={{ width: "30%" }}>
                    <div class="d-flex justify-content-between">
                      <div class="col text-center">
                        <span class="icon mb-2 d-block">
                          <img src={Rank} alt="" />
                        </span>
                        <h5 class="bodyNormalFont mb-0">{t('contest_details.rank')}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row justify-content-between align-items-center">
                  <div class="col-lg-5 col-4">
                    <div class="d-flex align-items-center">
                      <span class="icon me-2 d-block">
                        {func(
                          "images",
                          ScrimCaptainData?.levels?.featuredImage?.default
                        )}
                        <img
                          class="icon-50"
                          src={
                            url?.imageUrl +
                            ScrimCaptainData?.levels?.featuredImage?.default
                          }
                          alt=""
                        />
                      </span>
                      <span>
                        <div
                          // style={{
                          //   display: "flex",
                          //   justifyContent: "space-between",
                          //   width: "100%",
                          //   alignItems: "center",
                          // }}
                          className="d-flex justify-content-between w-100 align-items-center"
                        >
                          <span>
                            <h6
                              class="bodyNormalFont mb-0"
                              style={{
                                display: "block",
                                width: "6rem",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {ScrimCaptainData?.gamerJiName}
                            </h6>

                            {/* <p
                              class="bodyNormalFont mb-0"
                              style={{ fontSize: "10px" }}
                            >
                              {ScrimCaptainData?.usersInGameName}
                            </p> */}
                          </span>
                          {console.log("*******", premiumUser,localStorage.getItem("planName"))}
                          {premiumUser ? (
                            <img
                              // style={{
                              //   width: "30px",
                              //   height: "30px",
                              //   marginLeft: "20px",
                              // }}
                              className="icon-premium ms-1"
                              src={
                                localStorage.getItem("planName") ==
                                process.env.REACT_APP_BRONZE
                                  ? Casual
                                  : localStorage.getItem("planName") ==
                                    process.env.REACT_APP_SILVER
                                  ? Pro
                                  : localStorage.getItem("planName") ==
                                    process.env.REACT_APP_GOLD
                                  ? Master
                                  : ""
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-8">
                    <div class="d-flex justify-content-between">
                      <div class="col text-center">
                        <h5 class="bodyNormalFont mb-0">
                         
                        </h5>
                      </div>
                      <div class="col text-center">
                        <h5 class="bodyNormalFont mb-0">{ScrimCaptainData?.rank}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FriendsShare code={scrimDetailData?.shortCode} />
            <div class="card feedbackCard mt-4">
              <div class="card-title text-center mb-5">
                <h4 class="m-0">{eventRate ? t("contest_details.thanks_for_the_rating") : t('contest_details.rate_mobily')}</h4>
              </div>
              <ul class="d-flex align-items-center justify-content-center list-unstyled m-0">
                {eventRate ? (
                  eventRate === 1 ? (
                    <li class="mx-2">
                      <img className="icon-50" src={Sad} alt="" />
                    </li>
                  ) : (
                    <></>
                  )
                ) : (
                  <li class="mx-2">
                    <span>
                      <img
                        className="icon-50"
                        src={Sad}
                        alt=""
                        onClick={() => rating(1)}
                      />
                    </span>
                  </li>
                )}

                {eventRate ? (
                  eventRate === 2 ? (
                    <li class="mx-2">
                      <img className="icon-50" src={NotGood} alt="" />
                    </li>
                  ) : (
                    <></>
                  )
                ) : (
                  <li class="mx-2">
                    <span>
                      <img
                        className="icon-50"
                        src={NotGood}
                        alt=""
                        onClick={() => rating(2)}
                      />
                    </span>
                  </li>
                )}

                {eventRate ? (
                  eventRate === 3 ? (
                    <li class="mx-2">
                      <img className="icon-50" src={Ok} alt="" />
                    </li>
                  ) : (
                    <></>
                  )
                ) : (
                  <li class="mx-2">
                    <span>
                      <img
                        className="icon-50"
                        src={Ok}
                        onClick={() => rating(3)}
                      />
                    </span>
                  </li>
                )}

                {eventRate ? (
                  eventRate === 4 ? (
                    <li class="mx-2">
                      <img className="icon-50" src={Happy} alt="" />
                    </li>
                  ) : (
                    <></>
                  )
                ) : (
                  <li class="mx-2">
                    <span>
                      <img
                        className="icon-50"
                        src={Happy}
                        alt=""
                        onClick={() => rating(4)}
                      />
                    </span>
                  </li>
                )}

                {eventRate ? (
                  eventRate === 5 ? (
                    <li class="mx-2">
                      <img className="icon-50" src={VeryHappy} alt="" />
                    </li>
                  ) : (
                    <></>
                  )
                ) : (
                  <li class="mx-2">
                    <span>
                      <img
                        className="icon-50"
                        src={VeryHappy}
                        alt=""
                        onClick={() => rating(5)}
                      />
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="title">
              <h3 class="bodyBoldFont mb-3">{t('contest_details.players')}</h3>
            </div>
            <div class="card playersCard">
              <div class="card-header">
                <div class="row justify-content-between align-items-center">
                  <div class="col-lg-6 col-4 d-flex" style={{ width: "70%" }}>
                    <div class="text-center ps-lg-5 ps-4">
                      <span class="icon mb-2 d-block">
                        <img src={Gamer} alt="" />
                      </span>
                      <h5 class="bodyNormalFont mb-0">{t('contest_details.mobily_username')}</h5>
                    </div>
                  </div>
                  <div class="col-lg-6 col-8" style={{ width: "30%" }}>
                    <div class="d-flex justify-content-between">
                      <div class="col text-center">
                        <span class="icon mb-2 d-block">
                          <img src={Rank} alt="" />
                        </span>
                        <h5 class="bodyNormalFont mb-0">{t('contest_details.rank')}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card playersDetailCard mt-1 mb-4">
              <div class="card-body">
                <ul class="list-unstyled mb-0">
                  {func("palydncjdnjvndfj", playerData)}
                  {playerData?.map((item) => (
                    <li class="row justify-content-between align-items-center">
                      <div class="col-lg-6 col-4">
                        <div class="d-flex align-items-center ps-4">
                          <span class="icon me-2 d-block">
                            <img
                              class="icon-50"
                              src={
                                url?.imageUrl +
                                item?.levels?.featuredImage?.default
                              }
                              alt=""
                            />
                          </span>
                          {func("premium  ---------", item?.planName)}
                          <div
                          
                       className="d-flex justify-content-between w-100 align-items-center"
                            // style={{
                            //   display: "flex",
                            //   justifyContent: "space-between",
                            //   width: "100%",
                            // }}
                          >
                            <h6
                              class="bodyNormalFont mb-0"
                              style={{
                                display: "block",
                                width: "6rem",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.gamerJiName}
                            </h6>
                            {console.log(item?.isPremium, item?.planName,process.env.REACT_APP_SILVER)}
                            {item?.isPremium ? (
                              <img
                                // style={{
                                //   width: "30px",
                                //   height: "30px",
                                //   marginLeft: "20px",
                                // }}
                                className="icon-premium ms-1"
                                src={
                                  item?.planName == process.env.REACT_APP_BRONZE
                                    ? Casual
                                    : item?.planName ==
                                      process.env.REACT_APP_SILVER
                                    ? Pro
                                    : item?.planName == process.env.REACT_APP_GOLD
                                    ? Master
                                    : ""
                                }
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-8">
                        <div class="d-flex justify-content-between">
                          <div class="col text-center">
                            {/* <h5 class="bodyNormalFont mb-0">{item?.rank || '-'}</h5> */}
                          </div>
                          <div class="col text-center">
                            <h5 class="bodyNormalFont mb-0">
                              {item?.rank || "-"}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                  {console.log(totalPage, page)}
                  {page < totalPage && (
              <div className="load_more text-center">
                <button className="btn btn-primary" onClick={() => loadMore()}>
                  {isLoading === true ? t("tournaments.loading") : t("contest.load_more")}
                </button>
              </div>
            )}
                </ul>
              </div>
            </div>
            <div class="title">
              <h3 class="bodyBoldFont mb-3">{t('contest_details.rules')}</h3>
            </div>
            <div class="card rulesCard">
              <div class="card-body">
                <div
                  dangerouslySetInnerHTML={{ __html: scrimDetailData?.rules }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ScrimDetailInfo;
