import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CollegeCard from "./CollegeCard";
import {
  collegeList,
  collegeuserList,
  userProfileList,
} from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import Loader from "../Common/Loader";
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { toastError } from "../../Utilities/toast";


const CollegeModal = ({
  collegeDetails,
  showCollegeDetails,
  sethowCollegeDetails,
}) => {
  const [show, setShow] = useState(showCollegeDetails);
  const [members, setMembers] = useState([]);
  const [colllegeListData, setCollegeList] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalColleges, setTotalColleges] = useState(0);
  const {t} = useTranslation()

  const [loading, setLoading] = useState([]);
  const handleClose = () => {
    sethowCollegeDetails(false);
    setShow(false);
  };
  const getMembersList = async () => {
    try {
      let payload = {
        skip: members?.length,
        limit: 10,
        filter: { college: collegeDetails?._id },
      };

      const res = await collegeuserList(payload);
      setMembers([...members, ...res?.data?.list]);
      setTotalMembers(res?.data?.count);
      func("members list", res?.data);
      getCollegeList();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      func("error", error);
      if (
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
      )
        {logout();return;}
      toastError(error?.response?.data?.errors[0]?.msg);
    }
  };
  const getCollegeList = async () => {
    try {
      let payload = { skip: colllegeListData?.length, limit: 10 };
      const res = await collegeList(payload);
      setCollegeList([...colllegeListData, ...res?.data?.list]);
      setTotalColleges(res?.data?.count);
      func("college", res?.data);
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("error", error);
    }
  };
  useEffect(() => {
    setLoading(true);
    getMembersList();
  }, []);
  if (loading) return <Loader />;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header> */}
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div className="row justify-content-center">
          <div className="col-md-9 col-12">
            <div class="card collegiateCard bg-transparent mb-4">
              <div class="card-body p-0 pt-5">
                <CollegeCard collegeDetails={collegeDetails} />
              </div>
            </div>
            <div className="row justify-content-center ">
              <div class="col-xl-9 col-lg-7 col-md-9">
                <ul
                  class="nav nav-pills mx-xxl-4 mx-xl-5 mx-3 d-flex justify-content-between auth-nav"
                  id="myTab"
                  role="tablist"
                >
                  <li
                    class="nav-item"
                    role="presentation"
                    style={{ width: "50%" }}
                  >
                    <button
                      class="nav-link active"
                      id="login-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#login-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="login-tab-pane"
                      aria-selected="true"
                      style={{ width: "100%" }}
                    >
                      {t('Profile.members')}
                    </button>
                  </li>
                  <li
                    class="nav-item"
                    role="presentation"
                    style={{ width: "50%" }}
                  >
                    <button
                      class="nav-link"
                      id="register-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#register-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="register-tab-pane"
                      aria-selected="false"
                      style={{ width: "100%" }}
                    >
                      {t('Profile.college')}                      
                    </button>
                  </li>
                </ul>
                <div class="tab-content auth-content">
                  <div
                    class="tab-pane fade show active"
                    id="login-tab-pane"
                    role="tabpanel"
                    aria-labelledby="login-tab"
                    tabindex="0"
                  >
                    <div
                      class="row justify-content-between align-items-center"
                      style={{ padding: "10px" }}
                    >
                      <div class="col-3 d-flex justify-content-around">
                      {t('Profile.rank')}  
                      </div>
                      <div class="col-2 d-flex ">{t('Profile.level')}</div>
                      <div class="col-4 d-flex ">{t('Profile.username')}</div>
                      <div class="col-3 d-flex ">{t('Profile.points')}</div>
                    </div>
                    {func("member", members)}
                    {members?.length > 0 ? (
                      <>
                        <div className="row justify-content-center">
                          <div class="collegeDetailsCard mt-5 mb-4">
                            <div
                              class="card-body"
                              style={{ maxHeight: "30rem", padding: "0" }}
                            >
                              <ul class="list-unstyled mb-0">
                                {members?.map((list, ind) => (
                                  <li
                                    class="d-flex justify-content-between align-items-center"
                                    style={{
                                      backgroundColor: "var(--dark-color)",
                                    }}
                                  >
                                    <div class="col-3 d-flex justify-content-around">
                                      <h5 class="bodyNormalFont mb-0 d-flex  align-items-center">
                                        <span class="mx-2">{list?.rank}</span>
                                      </h5>
                                    </div>

                                    <div class="col-2 ">
                                      <h5 class="bodyNormalFont mb-0 d-flex  align-items-center">
                                        {" "}
                                        {list?.level?.num}{" "}
                                      </h5>
                                    </div>

                                    <div class="col-4 ">
                                      <div class="d-grid">
                                        <h6 class="text-white mx-2 my-1 ">
                                          {list?.gamerjiName}
                                        </h6>
                                      </div>
                                    </div>
                                    <div class="col-3 ">
                                      <div class="d-grid">
                                        <h6 class="text-white mx-2 my-1 ">
                                          {list?.points}
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          {members.length >= 10 &&
                          members.length != totalMembers ? (
                            <button
                              className="col-6 btn btn-primary"
                              onClick={() => getMembersList()}
                            >
                              {t('contest.load_more')}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      <h4 className="text-center">{t('notFound.no_records_found')}</h4>
                    )}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="register-tab-pane"
                    role="tabpanel"
                    aria-labelledby="register-tab"
                    tabindex="0"
                  >
                    <div
                      class="row justify-content-between align-items-center"
                      style={{ padding: "10px" }}
                    >
                      <div class="col-2 d-flex justify-content-around">
                      {t('Profile.rank')}
                      </div>
                      <div class="col-8 d-flex ">{t('Profile.college_name')}</div>
                      <div class="col-2 d-flex justify-content-around">
                      {t('Profile.points')}
                      </div>
                    </div>
                    {colllegeListData?.length > 0 ? (
                      <>
                        <div className="row justify-content-center">
                          <div class="collegeDetailsCard mt-5 mb-4">
                            <div
                              class="card-body"
                              style={{ maxHeight: "30rem", padding: "0" }}
                            >
                              <ul class="list-unstyled mb-0">
                                {colllegeListData?.map((list, ind) => (
                                  <li
                                    class="d-flex justify-content-between align-items-center"
                                    style={{
                                      backgroundColor: "var(--dark-color)",
                                    }}
                                  >
                                    <div class="col-2 d-flex justify-content-around">
                                      <h6 class="mb-1 h5 text-white d-flex justify-content-center align-items-center">
                                        <span class="mx-2">{list?.rank}</span>
                                      </h6>
                                    </div>
                                    <div class="col-8 d-flex justify-content-between align-items-center">
                                      <div class="col ">
                                        <h5 class="bodyNormalFont mb-0 d-flex  align-items-center">
                                          {" "}
                                          {list?.name}{" "}
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="col-2 ">
                                      <div class="d-grid">
                                        <h6 class="text-white mx-2 my-1 ">
                                          {list?.points}
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          {colllegeListData.length >= 10 &&
                          colllegeListData.length != totalColleges ? (
                            <button
                              className="col-6 btn btn-primary"
                              onClick={() => getCollegeList()}
                            >
                              {t('contest.load_more')}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      <h4 className="text-center">{t('notFound.no_records_found')}</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CollegeModal;
