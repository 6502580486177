import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { func } from "../../../Utilities/logFunc";
import { url } from "../../../Utilities/url";
import {
  getHtml5GameCategoryList,
  getHtml5GameList,
} from "../../../Utilities/controller";
import GamePlayed from "../../../assets/images/gamesPlayed-icon-svg.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { kFormatter, convertNumberToMillions } from "../../../Utilities/kFormatter";
import GameIcon from '../../../assets/images/game-icon-svg.svg'
import { useTranslation } from "react-i18next";
import test from "../../../assets/images/test.png"
import { logout } from "../../../Utilities/logout";
import GameConfirmationModel from "./GameConfirmationModel"
import FreeGamesModal from "./FreeGameModal";

const FreeGames = () => {
  const {t} = useTranslation()
  const [games, setgames] = useState([]);
  const [subGames, setSubGames] = useState([]);
  const [gameName, setGamename] = useState("");
  const [gameId, setGameId] = useState("");
  const [selectedGame, setSelectedGame] = useState("");
  const [selectedSubGame, setSelectedSubGame] = useState("");
  const [index, setIndex] = useState(null);
  const [showGameConfirmationModel, setShowGameConfirmationModel] =
  useState(false);
  const [joinGame, setJoinGame] =
    useState(false);
    const [showGameModal, setShowGameModal] = useState(false);
    const [joinId, setJoinId] = useState("");
    const [allowToView, setAllowToViewGame] = useState(false);

  const fetchGames = async () => {
    try {
      let payload = { sortby: "order", sort: "asc" };
      const res = await getHtml5GameCategoryList(payload);
      func("freee games", res?.data);
      setgames(res?.data?.list);
      setSubGames([]);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("freee games", error?.response?.data?.errors[0]?.msg);
    }
  };

  const fetchSubGames = async (game) => {
    try {
      let payload = {
        skip: 0,
        limit: 100,
        html5Category: game,
        sortby: "order",
        sort: "asc",
      };
      const res = await getHtml5GameList(payload);
      func("freee games", res?.data);
      setSubGames(res?.data?.list);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("freee games", error?.response?.data?.errors[0]?.msg);
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  const handleGameSelect = (item) => {
    setAllowToViewGame(false);
    setJoinGame(false);
    setGameId(item?._id);
    if (!item?.isDirect) {
      setSelectedGame(item);
      fetchSubGames(item?._id);
    } else {
      setSelectedGame(item);
      setSubGames([]);
      setSelectedSubGame("");
    }
  };

  const handleSubGameSelect = (item) => {
    setAllowToViewGame(false);
    setJoinGame(false);
    setSelectedSubGame(item);
    setShowGameConfirmationModel(true)
  };

  useEffect(() => {
    if(gameName && !selectedGame?.isDirect && selectedSubGame && joinGame && allowToView)
    {
      setShowGameModal(true)
    }
    else
    {
      setShowGameModal(false)
    }
  }, [allowToView])

  return (
    <>
      {showGameConfirmationModel && (
        <GameConfirmationModel
          showGameConfirmationModel={showGameConfirmationModel}
          setShowGameConfirmationModel={setShowGameConfirmationModel}
          game={selectedGame}
          subGame={selectedSubGame}
          joinGame={setJoinGame}
          setAllowToViewGame={setAllowToViewGame}
          setJoinId={setJoinId}
        />
      )}
      <div class="content">
      <div className="content-inner">

        <div class="row gy-5">
          <div class="col-12">
            <div class="title mb-3">
              <h2 class="h3 d-flex align-items-center">
                <span class="titleIcon me-3">
                  <img src={GameIcon} alt="" />
                </span>
                <span>{t('home.cloud_games')}</span>
              </h2>
            </div>

              <>
                <Swiper
                  className="commonSlider"
                  spaceBetween={20}
                  modules={[Navigation, Pagination]}
                  slidesPerView={2}
                  // onSlideChange={() => console.log("slide change")}
                  // onSwiper={(swiper) => console.log(swiper)}
                  navigation
                  pagination={{ clickable: true, el: ".swiper-pagination" }}
                  breakpoints={{
                    576: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    992: {
                      slidesPerView: 4,
                    },
                    1200: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {games.length > 0 &&
                    games.map((data, indp) => (
                      <SwiperSlide
                        class="swiper-slide"
                        key={indp}
                        onClick={() => {
                          setGamename(data?.name);
                          setIndex(indp);
                          handleGameSelect(data);
                        }}
                      >
                        <div
                          class="card gameCard"
                          // onClick={() => clickFreeHandler(data?.title)}
                        >
                          <div class="card-body" style={{ paddingTop: "1rem" }}>
                            <div class="card-title text-center">
                              <h4
                                class="bodyExtraBoldFont"
                                style={{ marginBottom: "1rem", display:'block',width:'100%',whiteSpace: "nowrap", overflow: "hidden",
                                textOverflow: "ellipsis" }}
                              >
                                {data?.name}{" "}
                              </h4>
                            </div>
                            <div
                              class="card-img"
                              style={{
                                cursor: "pointer",
                                borderRadius: "10px",
                              }}
                            >
                              <img
                                class="w-100"
                                style={{ borderRadius: "10px" }}
                                src={
                                  url.imageUrl + data?.featuredImage?.default
                                }
                                alt={data?.title}
                              />
                              
                            </div>
                            <div
                              class="card-content"
                              style={{ padding: "8px" }}
                            >
                              <div class="d-flex align-items-center">
                                <span class="me-2 card-icon">
                                  <img src={GamePlayed} alt="" />
                                </span>
                                <h4 class="mb-0">
                                  {" "}
                                  {convertNumberToMillions(data?.totalJoinedPlayers)} {t('home.played', {n:kFormatter(data?.totalJoinedPlayers)})}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  <div class="swiper-pagination"></div>
                </Swiper>
              </>

              {subGames.length > 0 && (
                <div className="mt-5">
                  <div class="title mb-3 ">
                    <h2 class="h3 d-flex align-items-center">
                      <span class="titleIcon me-3">
                        <img src={GamePlayed} alt="" />
                      </span>
                      <span className="h3 mb-0">{gameName}</span>
                    </h2>
                  </div>
                  <div className="col-12 mt-3">
                    <Swiper
                      className="commonSlider"
                      spaceBetween={20}
                      modules={[Navigation, Pagination]}
                      slidesPerView={2}
                      // onSlideChange={() => console.log("slide change")}
                      // onSwiper={(swiper) => console.log(swiper)}
                      navigation
                      pagination={{ clickable: true, el: ".swiper-pagination" }}
                      breakpoints={{
                        576: {
                          slidesPerView: 3,
                        },
                        768: {
                          slidesPerView: 3,
                        },
                        992: {
                          slidesPerView: 4,
                        },
                        1200: {
                          slidesPerView: 3,
                        },
                        1400: {
                          slidesPerView: 4,
                        },
                      }}
                    >
                      {subGames.map((data, indp) => (
                        <SwiperSlide
                          class="swiper-slide"
                          key={indp}
                          onClick={() => handleSubGameSelect(data)}
                        >
                          <div
                            class="card gameCard"
                            // onClick={() => clickFreeHandler(data?.title)}
                          >
                            <div
                              class="card-body"
                              style={{ paddingTop: "1rem" }}
                            >
                              <div class="card-title text-center">
                                {func("lengh", data?.name?.length > 5)}

                              <h4 class="bodyExtraBoldFont" style={{marginBottom:'1rem',display:'block',width:'100%',whiteSpace: "nowrap", overflow: "hidden",
  textOverflow: "ellipsis"}} >{data?.name}</h4>
                            </div>
                            <div class="card-img"  style={{cursor:'pointer', borderRadius:'10px'}}>
                              <img
                                class="w-100"
                      style={{borderRadius:'10px'}}

                                src={url.imageUrl + data?.thumbImage?.default}
                                alt={data?.title}
                              />
                            </div>
                            <div class="card-content" style={{padding:'8px'}}>
                              <div class="d-flex align-items-center">
                                <span class="me-2 card-icon">
                                  <img src={GamePlayed} alt="" />
                                </span>
                                <h4 class="mb-0">
                                  {" "}
                                  {convertNumberToMillions(data?.totalJoinedPlayers)} {t('home.played', {n:kFormatter(data?.totalJoinedPlayers)})}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                    <div class="swiper-pagination"></div>
                  </Swiper>
                </div>
              </div>
            )}

              {gameName && selectedGame?.isDirect && (
                <div className="mt-5">
                  <div class="title">
                    <h2 class="h3 d-flex align-items-center mt-1">
                      <span class="titleIcon me-3">
                        <img src={GamePlayed} alt="" />
                      </span>
                      <span className="h3 mb-0">{gameName}</span>
                    </h2>
                  </div>
                  <div className="col-12 d11-freeGame-rectangle p-3">
                    <iframe
                      className="h-100 w-100"
                      src={selectedGame?.link}
                    ></iframe>
                  </div>
                </div>
              )}

              {/* {gameName && !selectedGame?.isDirect && selectedSubGame && joinGame && (
                <div className="mt-5">
                  <div class="title mb-3 ">
                    <h2 class="h3 d-flex align-items-center">
                      <span class="titleIcon me-3">
                        <img src="/assets/images/game-icon-svg.svg" alt="" />
                      </span>
                      <span className="h3 mb-0">{selectedSubGame?.name}</span>
                    </h2>
                  </div>
                  <div className="col-12 d11-freeGame-rectangle p-3">
                    <iframe
                      className="h-100 w-100"
                      src={selectedSubGame?.url}
                    ></iframe>
                  </div>
                </div>
              )} */}

              {showGameModal && (
                <FreeGamesModal
                  selectedGame={selectedGame}
                  selectedSubGame={selectedSubGame}
                  showGameModal={showGameModal}
                  setShowGameModal={setShowGameModal}
                  joinId={joinId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeGames;
