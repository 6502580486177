import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getLevelsList } from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import { toast } from "react-toastify";
import { url } from "../../Utilities/url";
import { useTranslation } from "react-i18next";
import { logout } from "../../Utilities/logout";
import { toastError } from "../../Utilities/toast";

const LevelsModal = ({ showLevel, setShowLevel }) => {
  const [show, setShow] = useState(showLevel);
  const { t } = useTranslation();
  const [listData, setListData] = useState([]);
  const handleClose = () => {
    setShowLevel(false);
    setShow(false);
  };
  const fetchLevelList = async () => {
    try {
      let payload = { skip: 0, limit: 100, sort: "asc", sortBy: "num" };
      const res = await getLevelsList(payload);
      func("levels", res?.data?.list);
      setListData(res?.data?.list);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
    toastError(error?.response?.data?.errors[0]?.msg);
      func("error ", error?.response?.data?.errors[0]?.msg);
    }
  };
  useEffect(() => {
    fetchLevelList();
  }, []);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header> */}
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div className="row justify-content-center">
          <div className="col-md-9">
            <div class="ps-lg-3">
              <h2 class="h3 bodyBoldFont mb-3 text-center">
                {t("Profile.levels")}
              </h2>
              <div class="card dailyloginCard">
                <div class="card-header">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-4 d-flex justify-content-around">
                      <h5 class="bodyNormalFont h4 mb-0">
                        {t("Profile.level")}
                      </h5>
                    </div>
                    <div class="col-4 d-flex  align-items-center">
                      <div class="col">
                        <h5 class="bodyNormalFont h4 mb-0">
                          {t("Profile.badge")}
                        </h5>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="d-flex justify-content-between">
                        <div class="col ">
                          <h5 class="bodyNormalFont h4 mb-0">
                            {t("Profile.points")}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card dailyLoginDetailsCard mt-1 mb-4">
                <div class="card-body" style={{ maxHeight: "22rem" }}>
                  <ul class="list-unstyled mb-0">
                    {listData?.map((list, ind) => (
                      <li class="row justify-content-between align-items-center">
                        <div class="col-4 d-flex justify-content-around">
                          <h6 class="mb-1 h5 text-white d-flex justify-content-center align-items-center">
                            <span class="mx-2">{list?.num}</span>
                          </h6>
                        </div>
                        <div class="col-4 d-flex justify-content-between align-items-center">
                          <div class="col text-center">
                            <h5 class="bodyNormalFont mb-0 d-flex  align-items-center">
                              <img
                                className="icon-30 me-2"
                                // style={{
                                //   height: "30px",
                                //   width: "30px",
                                //   marginRight: "5px",
                                // }}
                                src={
                                  url?.imageUrl + list?.featuredImage?.default
                                }
                              />{" "}
                              {list?.name}{" "}
                            </h5>
                          </div>
                        </div>
                        <div class="col-4 ">
                          <div class="d-grid">
                            <h6 class="text-white mx-2 my-1 ">
                            {list?.startPoint} {t("Profile.to")} {list?.endPoint}
                            </h6>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LevelsModal;
