import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../../Utilities/routesFb";
import moment from "moment";

import { getScrimByUserId } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import Loader from "../../Common/Loader";
import NoContestFound from "../../../assets/images/noContestFound.png";
import { useTranslation } from "react-i18next";
import { useProfileDataContext } from "../ProfileContext";
import { logout } from "../../../Utilities/logout";

const Private = () => {
  const navigate = useNavigate();
  const [scrimList, setScrimList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const gameName = localStorage.getItem("activeGame");
  const gameId = localStorage.getItem("activeGameId");
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { profileData } = useProfileDataContext();
  const getScrimList = async (pageNo) => {
    setLoading(true);
    try {
      let userData = localStorage.getItem("userData");
      userData = JSON.parse(userData);

      let data = {
        userid: profileData?.item?.user_id,
        game: gameId,
        pagination: {
          pageNo: pageNo,
          recordPerPage: 10,
          sortBy: "createdAt",
          sortDirection: "asc",
        },
      };

      const res = await getScrimByUserId(data);

      if (res?.data?.data) {
        setScrimList(res?.data?.data);
        setTotalPage(res?.data?.totalPages);
        setLoading(false);
      }
      func(res);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  useEffect(() => {
    getScrimList(1);
  }, []);

  const loadMoreBtnClick = () => {
    let totalCount = parseInt(page) + 1;
    setPage(totalCount);
    getScrimList(totalCount);
  };

  const findPercentage = (data) => {
    let totalPlayer = data?.noOfPlayerCapacity || 0;
    let totalPlayerJoined = data?.noOfJoinedPlayer || 0;

    let percentage = (totalPlayerJoined / totalPlayer) * 100;

    return percentage + "%";
  };

  const handleClickContestCard = (item) => {
    navigate("/home/tournament-details/" + item?._id);
  };

  return (
    <>
  
    <>
      { isLoading ? (
           <Loader />) :
            
      
      <div class="row gx-3 gy-5">
        <div class="col-12">
          {scrimList.length > 0 ? (
            <>
              <div class="row gy-5 gy-100">
                {scrimList.map((item, ind) => (
                  <div className="col-lg-4 col-md-6 text-left px-4" key={ind}>
                    <div
                      class="card tournametParticipationCard tournamentSoloCard contestTournamentSoloCard mb-5"
                      onClick={() =>
                        navigate("/home/scrim-details/" + item?.scrimId)
                      }
                    >
                      <div class="card-header">
                        <div class="card-status">
                          {item?.status?.toUpperCase()}
                        </div>
                        <h4 class="card-title mb-0">{item?.title}</h4>
                        {/* <a href="#" class="rulesLink fs-5">ID: 2HWEJV</a> */}
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <div class="card-content">
                              <div class="row justify-content-between py-2">
                                <div class="col-3">
                                  <label for="" class="d-block h6">
                                    {t("contest.date")}
                                  </label>
                                  <h6 class="bodyBoldFont mb-0">
                                    {moment(item?.date).format("DD/MM/YYYY")}
                                  </h6>
                                </div>
                                <div class="col-3 dividersX">
                                  <label for="" class="d-block h6">
                                    {t("contest.time")}
                                  </label>
                                  <h6 class="bodyBoldFont mb-0">
                                    {moment(item?.time).format("hh:mm A")}
                                  </h6>
                                </div>
                                <div class="col-3 dividersRight">
                                  <label
                                    for=""
                                    class="d-block h6 mb-1 text-ellipsis_contest text-line-height"
                                  >
                                    {/* {item?.titles[0]?.name} */}
                                    {
                                      // item?.titles[0]?.name?.length > 9
                                      //   ? item?.titles[0]?.name?.substring(
                                      //       0,
                                      //       9 - 3
                                      //     ) + "..."
                                      //   :
                                      item?.titles[0]?.name
                                    }
                                  </label>
                                  <h6 class="bodyBoldFont mb-0 text-ellipsis_contest text-line-height">
                                    {/* {item?.titles[0]?.value} */}
                                    {
                                      // item?.titles[0]?.value?.length > 9
                                      //   ? item?.titles[0]?.value?.substring(
                                      //       0,
                                      //       9 - 3
                                      //     ) + "..."
                                      //   :
                                      item?.titles[0]?.value
                                    }
                                  </h6>
                                </div>
                                <div class="col-3">
                                  <label
                                    for=""
                                    class="d-block h6 mb-1 text-ellipsis_contest text-line-height"
                                  >
                                    {/* {item?.titles[1]?.name} */}
                                    {
                                      // item?.titles[1]?.name?.length > 9
                                      //   ? item?.titles[1]?.name?.substring(
                                      //       0,
                                      //       9 - 3
                                      //     ) + "..."
                                      //   :
                                      item?.titles[1]?.name
                                    }
                                  </label>
                                  <h6 class="bodyBoldFont mb-0 text-ellipsis_contest text-line-height">
                                    {/* {item?.titles[1]?.value} */}
                                    {
                                      // item?.titles[1]?.value?.length > 9
                                      //   ? item?.titles[1]?.value?.substring(
                                      //       0,
                                      //       9 - 3
                                      //     ) + "..."
                                      //   :
                                      item?.titles[1]?.value
                                    }
                                  </h6>
                                </div>
                              </div>
                              <div class="row justify-content-between py-2 mt-1">
                                <div class="col-4">
                                  <label for="" class="d-block h6">
                                    {t("contest_details.players")}
                                  </label>
                                  <div class="d-flex align-items-center poolPrizeBox">
                                    <h6 class="bodyBoldFont mb-0">
                                      {item?.noOfPlayerCapacity || 0}
                                    </h6>
                                  </div>
                                </div>
                                <div class="col-4 dividersX d-flex">
                                  <div class="">
                                    <label for="" class="d-block h6">
                                      {t("contest.winners")}
                                    </label>
                                    <div class="d-flex align-items-center poolPrizeBox">
                                      <h6 class="bodyBoldFont mb-0">
                                        {item?.noOfWinner || 0}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-4 d-flex">
                                  <div class="">
                                    <label for="" class="d-block h6">
                                      {t("contest.id")}
                                    </label>
                                    <div class="d-flex align-items-center useCoinBox">
                                      <h6 class="bodyBoldFont mb-0">
                                        {item?.shortCode}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="col-12">
                                  <div
                                    class="progress"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    <div
                                      class="progress-bar bg-info"
                                      style={{ width: findPercentage(item) }}
                                    ></div>
                                  </div>
                                  <div class="d-flex justify-content-between mt-2">
                                    <h6>
                                      {t("contest.players_remaining", {
                                        players:
                                          item?.noOfPlayerCapacity -
                                            item?.noOfJoinedPlayer || 0,
                                      })}
                                    </h6>
                                    <h6>
                                      {t("contest.players_joined", {
                                        players: item?.noOfJoinedPlayer || 0,
                                      })}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer text-white">
                        <div class="bg-secondary-subtle pt-2 pb-1 box">
                          <div class="row justify-content-center py-2 mt-1 ">
                            <div class="col-4 text-left border-end px-4">
                              <label for="" class="d-block h6 mb-10">
                                {t("contest.room_id")}
                              </label>
                              <h6 class="bodyBoldFont mb-0 d-flex align-items-center justify-content-left">
                                <span id="copyText">
                                  {item?.roomIdPassword?.id || "-"}
                                </span>
                                {item?.roomIdPassword?.id && (
                                  <span class="icon ms-2" onclick="copyText();">
                                    <img
                                      src="/assets/images/copy-icon-svg.svg"
                                      alt=""
                                    />
                                  </span>
                                )}
                              </h6>
                            </div>
                            <div class="col-4 text-left px-4">
                              <label for="" class="d-block h6 mb-10">
                                {t("contest.password")}
                              </label>
                              <h6 class="bodyBoldFont mb-0 d-flex align-items-center justify-content-left">
                                <span id="copyText1">
                                  {item?.roomIdPassword?.password || "-"}
                                </span>
                                {item?.roomIdPassword?.password && (
                                  <span
                                    class="icon ms-2"
                                    onclick="copyText1();"
                                  >
                                    <img
                                      src="/assets/images/copy-icon-svg.svg"
                                      alt=""
                                    />
                                  </span>
                                )}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {page < totalPage && (
                <div class="d-flex justify-content-left mt-5">
                  <button
                    class="btn btn-primary col-md-2 text-left mt-5"
                    onClick={() => loadMoreBtnClick()}
                  >
                    {t("contest.load_more")}
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              <div class="text-center">
                <img className="img-fluid" src={NoContestFound} alt="" />
              </div>
            </>
          )}
        </div>
      </div>
}
    </>
  
  </>
  )
};

export default Private;
