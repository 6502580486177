import React, { useEffect, useReducer, useState } from "react";
import BaseAuth from "./BaseAuth";
import {
  getCountryList,
  getJson,
  getLanguage,
  requestApplySignupCode,
  userRequestOTP,
  userRequestOTPSignUp,
} from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import Loader from "../Common/Loader";
import CustomSelect from "../Common/CustomSelect";
import { url } from "../../Utilities/url";
import { errors } from "../../Utilities/error";
import { useLocation, useNavigate } from "react-router";
import { routes } from "../../Utilities/routesFb";
import { INITIAL_STATE, loginReducer } from "./loginReducer";
import LandingPage from "../LandingPage/LandingPage";
import { INITIAL_STATE_REG, registerReducer } from "./SignupReducer";
import Select from "react-select";
import DropDown from "../Common/DropDown";
import CountryDropDown from "../Common/CountryListDropDown";
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import LoginLandingPage from "../LandingPage/LoginLandingPage";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import axios from "axios";
import i18next from "i18next";
import Cookies from "js-cookie";
import { async } from "q";
import { encryptKey } from "../../Utilities/encryptDecrypt";
import Error from "../Common/Error";

import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents"; 
import { toastError } from "../../Utilities/toast";

const Login = () => {
  func("login", "login page");
  const [countryList, setCountryList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loginInput, dispatchInput] = useReducer(loginReducer, INITIAL_STATE);
  const [registerInput, dispatchRegisterInput] = useReducer(
    registerReducer,
    INITIAL_STATE_REG
  );
  const [error, setError] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [appliedCouponCode, setAppliedCouponCode] = useState(undefined);
  const [couponError, setCouponError] = useState("");
  const [couponSuccess, setCouponSuccess] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [onAgree, setOnAgree] = useState(false);
  const [tncError, setTncError] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const [pin, setPin] = useState();
  const [languages, setLanguages] = useState();
  const [pinError, setPinError] = useState();
  
  const location = useLocation()
  const {state} = location
  const [active, setActive] = useState(state || 0)
   const [isBusy, setIsBusy] = useState(false);
  
  const fetchCountry = async () => {
    setLoader(true);
    try {
      const data = await getGeoInfo();
      const res = await getCountryList();
      let result = []
      if (res) 
      {
         result = res?.list?.filter((item,ind) => item?.dialingCode == '+973' || item?.dialingCode =='+966' )
        // console.log(result)
        // result = res?.list
        // let firstField ={
        //   _id:null, 

        // }
        // console.log([...result])
        // setCountryList((prev) => [firstField,...result]);
        setCountryList((prev) => result);
      }
      
      // setCountryOption(prev=> res?.list[2].dialingCode)
      let index = -1;
      // data.country_calling_code  = undefined
      // console.log("countryCode********", data.country_calling_code)
      result?.filter((item, ind) => {
        if (item?.dialingCode == (data?.country_calling_code) ) {
          index = ind;
          return index;
        }
      });
      console.log(index)
      if(index <=-1){
        result?.filter((item, ind) => {
          if (item?.dialingCode == "+973" ) {
            index = ind;
            return index;
          }
        });
      }
      console.log("index", index);
      setIndex(index);
      console.log( result?.[index]?.dialingCode)
      dispatchInput({
        type: "CHANGE_INPUT",
        payload: {
          name: "countryOption",
          value: result?.[index]?.dialingCode,
        },
      });
      dispatchInput({
        type: "CHANGE_INPUT",
        payload: { name: "code", value: res?.list?.[index]?._id },
      });
      dispatchRegisterInput({
        type: "CHANGE_INPUT",
        payload: {
          name: "countryOption",
          value: result?.[index]?.dialingCode,
        },
      });
      dispatchRegisterInput({
        type: "CHANGE_INPUT",
        payload: { name: "code", value: result?.[index]?._id },
      });
    } catch (error) {
      func("error", error);
      console.log(error)
    }
    setLoader(false);
  };
  const getGeoInfo = async () => {
    let data = {};
    await axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        data = response.data;
        // console.log(
        //       data.ip,
        //       data.country_name,
        //     data.country_calling_code,
        //      data.city,
        //     data.timezone)
        setCountryCode(data?.country_calling_code);
      })

      .catch((error) => {
        console.log(error);
      });
    return data;
  };
  const fetchLanguage = async () => {
    const body = {
      companyId: process.env.REACT_APP_COMPANY_ID, //"657178a70a3d9e6d7ca5292c" //"656dc321bd70fe027ee3f073"
    };
    try {
      const res = await getLanguage(body);
      // console.log(res)
      setLanguages(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const currentLang = Cookies.get("i18next");
  useEffect(() => {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(success, error);
    // } else {
    //   console.log("Geolocation not supported");
    // }

    // function success(position) {
    //   console.log("position", position)
    //   const latitude = position.coords.latitude;
    //   const longitude = position.coords.longitude;
    //   console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    // }

    // function error() {
    //   console.log("Unable to retrieve your location");
    // }

    fetchCountry();
    fetchLanguage();
    document.documentElement.dir = currentLang == "ar" ? "rtl" : "ltr";
    document.title = t("title");
  }, [currentLang]);
  const onChangeHandler = (e) => {
    setError('')
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const onChangePin = (e) => {
    // console.log(e.target.name);
    setPinError('')
    setError('')
    let text = "*";
  
    // const encPin = encryptKey(e.target.value.slice(0,6))
    // setPin(e.target.value);

    // console.log(e.target.value?.slice(0,6),encPin)
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const onSignupChangeHandler = (e) => {
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  const loginHandler = (e) => {
    e.preventDefault();
    // console.log(loginInput?.mobileNumber.length)
    // console.log(errors?.error_enter_phone, errors?.error_pin_length)
    setIsBusy(true)
    if (!loginInput?.mobileNumber || loginInput?.mobileNumber?.length == 0) {
      setError(t('error_enter_phone'));
    setIsBusy(false)
    } 
    else {
      setError("") 
    setIsBusy(false)
  }
    if (loginInput?.countryOption == "+91") {
      // console.log("--",t('error_enter_valid_phone'),loginInput?.mobileNumber.length != 10 )
      if (loginInput?.mobileNumber.length != 10) {
        setError(t('error_enter_valid_phone'));
    setIsBusy(false)
      } 
      else if (loginInput?.pin.length < 6) {
        setPinError(t('error_pin_length'));
    setIsBusy(false)
      } 
      else {
        callVerifyPin();
    setIsBusy(false)
      }
    } else {
      if (loginInput?.mobileNumber.length < 8) {
        setError(t('error_enter_valid_phone'));
        setIsBusy(false)
      } else {
        callVerifyPin();
    setIsBusy(false)
      }
    }

    // else if (loginInput?.mobileNumber?.length != 10) {
    //   setError(errors?.error_enter_valid_phone);
    // } else {
    //   callToOTPRequestAPI();
    // }
    // const onSignupChangeHandler = (e) => {
    //   dispatchRegisterInput({ type: 'CHANGE_INPUT', payload: { name: e.target.name, value: e.target.value } })
    // }
    // const loginHandler = (e) => {
    //   e.preventDefault()
    //   if (!loginInput?.mobileNumber || loginInput?.mobileNumber?.length == 0) {
    //     setError(t('error_enter_phone'))
    //   }
    //   else if (loginInput?.mobileNumber?.length != 10) {
    //     setError(errors?.error_enter_valid_phone)
    //   }
    //   else {
    //     callToOTPRequestAPI();

    //   }
    // }
  };

  // request OTP after login
  const callVerifyPin = async () => {
    
    const encPin = await encryptKey(loginInput?.pin);
    var payload = {
      type: "otpRequest",
      username: loginInput?.mobileNumber,
      phoneCode: loginInput?.countryOption,
      country: loginInput?.code,
      pin: encPin,
    };

    // const encPin = encryptKey(e.target.value.slice(0,6))

    // localStorage.setItem("loginInfo", JSON.stringify(payload));

    setLoading(true);
    try {
      const res = await userRequestOTP(payload);
      // func("login res", res?.response?.data?.errors?.[0]?.msg);
      localStorage.setItem("user-id", res?.item?.id);
      localStorage.setItem("country", res?.item?.address?.country);
      localStorage.setItem("token", res?.token);
      localStorage.setItem(
        "d11_username",
        res?.item?.gamerjiName
      );
      localStorage.setItem(
        "email",
        res?.item?.email
      );
      eventTracking(events.LOGIN.LOGIN,{
        MG_MobileNumber: loginInput?.mobileNumber,
        MG_CountryCode: loginInput?.countryOption,
        MG_UserID:res?.item?._id
      });
      // return;
        // console.log("RESPONSE",res)
      if (res?.item?.isDetailsFilled)
      {
        console.log(localStorage.getItem('code'))
        if (localStorage.getItem('code'))
        {
          window.location.href = "/" + routes.home + `?code=${localStorage.getItem('code')}`;
          
        }
         
          else 
          window.location.href = "/" + routes.home ;
      } 
      else window.location.href = "/avatar";
      //analytics Tracking
    
      // if (res?.success) {
      //   navigate("/" + routes.otp, { state: loginInput });
      // } else {
      //   setError(res?.response?.data?.errors?.[0]?.msg);
      // }
    } catch (error) {
      // console.log("userRequestOTP error", error?.response.data);
      if (error?.response?.data?.message) {
        setError(error?.response?.data?.message);
      } else if (error?.response?.data?.errors?.[0].msg) {
        setError(error?.response?.data?.errors[0].msg);
      }
      // console.log(error?.response?.data?.errors[0].msg)
      // setPinError(error?.response?.data?.errors[0].msg)
      // setError(error?.response?.data?.errors[0].msg);
    }
    setLoading(false);
  };

  const handlerSelect = (e) => {
    console.log("here",e)
    setCountryCode(e);
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: "code", value: e?._id },
    });
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: "countryOption", value: e?.dialingCode },
    });
  };
  const handlerSelectRegister = (e) => {
     eventTracking(events.COUNTRY_CODE,{
      MG_CountryCode: e?.dialingCode,
    });
    setCountryCode(e);
    func("onChange");
    setCouponError("");
    setCouponSuccess("");
    setCouponCode("");
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: "code", value: e?._id },
    });
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: "countryOption", value: e?.dialingCode },
    });
  };
  // request OTP after login

  // Registration
  const checkValidation = (e) => {
    e.preventDefault();
    setIsBusy(true)
    if (
      !registerInput?.mobileNumber ||
      registerInput?.mobileNumber?.length == 0
    ) {
      setSignupError(t('error_enter_phone'));
    setIsBusy(false)
      return;
    } 
   else{
    setSignupError("")
    setIsBusy(false)
   }
      if (registerInput?.countryOption == "+91") {
        if (registerInput?.mobileNumber.length != 10) {
          setSignupError(t('error_enter_valid_phone'));
    setIsBusy(false)
          return;
        } else {
          setError("");
        }
      } else {
        if (registerInput?.mobileNumber.length < 8) {
          setSignupError(t('error_enter_valid_phone'));
    setIsBusy(false)
          return;
        }
      }
    

    if (onAgree) {
      setTncError("");
    setIsBusy(false)
      callToOTPRequestAPISignup();
    } else {
      // console.log(errors?.error_agree_terms_and_conditions)
      setTncError(t('error_agree_terms_and_conditions'));
    setIsBusy(false)
    }
  };

  // Update the state based on whether the checkbox is checked or not
  const handleCheckboxChange = (e) => {
    setOnAgree(e.target.checked);
  };

  // call signup API
  const callToOTPRequestAPISignup = async () => {
    if (!registerInput?.countryOption) {
      setSignupError(t('error_select_country_code'));
      return;
    }
    var payload = {
      type: "otpRequest",
      username: registerInput?.mobileNumber,
      phoneCode: registerInput?.countryOption,
      country: registerInput?.code,
      code: couponCode,
    };

     //analytics Tracking
    //  eventTracking(events.COUNTRY_CODE,{
    //   MG_MobileNumber: loginInput?.mobileNumber,
    //   MG_CountryCode: loginInput?.countryOption,
    //   MG_UserID:res?.item?._id
    // });
    
    // console.log(registerInput)
    localStorage.setItem("country", registerInput?.code);
    if (appliedCouponCode) {
      payload.code = couponCode;
    }
    setLoading(true);
    func("signup payload", payload);
    try {
      const res = await userRequestOTPSignUp(payload);
      func("register res", res);
      eventTracking(events.REGISTER,{
        MG_Country_Code:registerInput?.countryOption,
        MG_Mobile_Number:registerInput?.mobileNumber,
        MG_Signupcode:couponCode,
      });

      if (res?.success) {

        navigate("/" + routes.signupotp, { state: registerInput });
      } else {
        setSignupError(res?.response?.data?.errors[0]?.msg);
      }
    } catch (error) {
      func("signup error", error);
      if (
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
      )
        logout();
      toastError(error?.response?.data?.errors?.[0]?.msg);
      setSignupError(error?.response?.data?.errors?.[0]?.msg);
    }
    setLoading(false);
  };

  // call coupon apply API
  const applySingupCode = async (e) => {
    e.preventDefault();
    if (couponCode == "") {
      setCouponError(t('error_enter_coupon_code'));
    } else {
      setLoading(true);
      try {
        const res = await requestApplySignupCode({
          code: couponCode,
          type: "signupCode",
          country: registerInput.code,
        });
        func("applySingupCode res", res);
        // setAppliedCouponCode(res.item)
        if (res?.success) {
          //google analytics
          eventTracking(events.APPLY_SIGNUP_CODE,{
            MG_CouponCode: couponCode,
          });

          setCouponError("");
          setCouponSuccess("Signup Code Applied Successfully!");
        }
        if (res?.response.data.errors) {
          setCouponError(res?.response?.data?.errors?.[0]?.msg);
        }
      } catch (error) {
        func("error", error);
        if (
          error?.response?.data?.errors?.[0]?.code ==
            "middlewares.token_parser.validation.token_invalid" ||
          error?.response?.data?.errors?.[0]?.code ==
            "middlewares.token_parser.validation.token_expired"
        )
          logout();
        setSignupError(error?.response?.data?.errors?.[0]?.msg);
      }
      setLoading(false);
    }
  };

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected
        ? "var(--secondary-color-emphasis)"
        : "var(--secondary-color-emphasis)",
      height: "34px",
      "&:hover": {
        color: "var(--accent-color)",
        backgroundColor:"var(--secondary-color-emphasis)"
      },
      padding: "0px 0px",
      borderRadius: "20px",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "var(--secondary-color-emphasis)",
      padding: "8px",
      border: "none",
      boxShadow: "none",
      borderRadius: "50px",
      display: "flex",
      alignItems: "center",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      width: "70px",
      marginRight: "5px",
      padding: "6px",
    }),
    indicatorsSeparator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "none",
    }),
    indicatorContainer: (defaultStyles) => ({
      ...defaultStyles,
      padding: "0",
      backgroundColor: "red",
    }),

    input: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "30px",
    }),
    dropdownIndicator: (defaultStyles) => ({
      color:'var(--color-white)',
      width:'1.5rem',
      height:'1.5rem',
      padding:'0',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    }),
  };

  const { t } = useTranslation();

  const onChangeLang = async (e) => {
    setLoader(true);
    const lang_code = e.toLowerCase();
    // console.log(lang_code)
    i18next.changeLanguage(lang_code);
    localStorage.setItem("i18nextLng", lang_code);
    const linkTag = document.getElementById("bootstrap_css");

    if (currentLang.toLowerCase() == "ar" || currentLang.toLowerCase() == "hi") {
      document.documentElement.setAttribute("lang", "ar");
      linkTag?.setAttribute(
        "href",
        `${process.env.REACT_APP_CLIENT_BASE_URL}assets/css/bootstrap.rtl.min.css`
      );
    }
    window.location.href = "/login";
    setLoader(false);
  };

  const translatedText = t("login.i_agree_to_mobily", {
    interpolation: { escapeValue: false },
  });
  const customFilterOption = (option, searchText) => {
    return option.data?.dialingCode?.toLowerCase()?.includes(searchText?.toLowerCase()) ||option.data?.name?.toLowerCase()?.includes(searchText?.toLowerCase());
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <LoginLandingPage>
          <BaseAuth>
            <div className="col-4 text-end">
              {/* <Select
                          styles={customStyles}
                          name="countryOption"
                          // value={countryCode}
                          defaultValue={languages?.[0]}
                          options={languages}
                          onChange={(e) => {
                            // localStorage.setItem("eng", e.value)
                            // console.log(e.value)
                            onChangeLang(e.code)
                            
                            // window.location.reload()
                          }}
                          getOptionLabel={(ele) => (
                            // <div
                            //   className="d11-menu"
                            //   style={{ display: "flex", alignItems: "center" }}
                            // >
                             
                              ele?.name
                            // </div> 
                          )}
                          /> */}
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-sm-9">
                <ul
                  className="nav nav-pills  d-flex justify-content-between auth-nav"
                  id="myTab"
                  role="tablist"
                >
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ width: "50%" }}
                  >
                    <button
                      className={`nav-link ${active === 0 ? 'active' : ""}`}
                      id="login-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#login-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="login-tab-pane"
                      aria-selected="true"
                      style={{ width: "100%" }}
                      onClick={()=>{
                        setError('')
                        dispatchInput({
                          type: "CHANGE_INPUT",
                          payload: { name: 'pin', value: '' },
                        });
                        dispatchInput({
                          type: "CHANGE_INPUT",
                          payload: { name: 'mobileNumber', value: '' },
                        });
                      
                        eventTracking(events.LOGIN_TAB);
                      }}
                    >
                      {t("login.login")}
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ width: "50%" }}
                  >
                    <button
                      className={`nav-link ${active === 1 ? 'active' : ""}`}
                      id="register-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#register-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="register-tab-pane"
                      aria-selected="false"
                      style={{ width: "100%" }}
                      onClick={()=>{
                        setError('')
                        eventTracking(events.REGISTER_TAB);

                      }}
                    >
                      {t("login.register")}
                    </button>
                    
                  </li>
                </ul>
                <div className="tab-content auth-content">
                  {/* LOGIN TAB*/}
                  <div
                     className={`tab-pane fade ${active === 0 ? 'show active' : ""}`}
                    id="login-tab-pane"
                    role="tabpanel"
                    aria-labelledby="login-tab"
                    tabindex="0"
                  >
                    <form className="row">
                      <div className="col-4">
                        <label
                          for="inputCoutryCode"
                          className="form-label text-nowrap"
                        >
                          {t("login.country_code")}
                        </label>

                        {/* <Select
                          styles={customStyles}
                          name="countryOption"
                          // value={countryCode}
                          defaultValue={countryList?.[index]}
                          options={countryList}
                          onChange={(e) => handlerSelect(e)}
                          filterOption={customFilterOption} 
                          getOptionLabel={(ele) => (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span class="icon">
                                <img
                                  class="icon-16 me-1 d11-country-flag"
                                  src={url.imageUrl + ele?.flag?.default}
                                  alt=""
                                />
                              </span>
                              {"  "}
                              <span>{ele?.dialingCode}</span>
                            </div>
                        
                
                          )}
                        /> */}
                        <CustomSelect countryList={countryList} index={index} handlerSelect={(e) => handlerSelect(e)} />
                      </div>

                      <div className="col-8">
                        <label for="inputMobileNumber" className="form-label">
                          {t("login.mobile_number")}
                      </label>
                        <input
                          type="number"
                          className="form-control"
                          id="inputMobileNumber"
                          placeholder={t("login.enter_mobile_number")}
                          name="mobileNumber"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(e) => onChangeHandler(e)}
                          maxLength={14}
                          value={loginInput?.mobileNumber?.slice(0, 14)}
                        />
                      </div>
                      <div className="col-12">
                        <label for="inputMobileNumber" className="form-label">
                          {t("login.enter_pin")}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          style={{
                            // "-webkit-text-security": "circle",
                            fontSize: "1.1rem",
                          }}
                          id="inputPin"
                          placeholder={t("login.enter_pin")}
                          name="pin"
                          onKeyDown={(e) => {
                            const isNumericKey = !isNaN(parseInt(e.key));
                            const isControlKey =
                              e.key === "Backspace" || e.key === "Delete" || e.key === "ArrowLeft" || e.key === "ArrowRight";
                            if (!isNumericKey && !isControlKey) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => onChangePin(e)}
                          maxLength={6}
                          value={loginInput?.pin}
                        />
                      </div>
                      <div className="col-12 d-grid">
                        <button
                          className="btn btn-primary btn-arrow"
                          onClick={(e) => loginHandler(e)}
                          disabled={isBusy}
                        >
                          {t("login.login")}
                        </button>
                        {/* {  console.log(error, pinError)} */}
                        {error && (
                          <p className="error" style={{ textAlign: "center" }}>
                            {error}
                          </p>
                        )}
                        {pinError && (
                          <p className="error" style={{ textAlign: "center" }}>
                            {pinError}
                          </p>
                        )}
                        <div className="text-center mt-3">
                          <p className="mb-1">{t("login.forget_your_pin")}</p>
                          <p>
                            <Link to={`/forget-pin`}>{t("login.reset")}</Link>{" "}
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* Registertion Tab */}
                  <div
                     className={`tab-pane fade ${active === 1 ? 'show active' : ""}`}
                    id="register-tab-pane"
                    role="tabpanel"
                    aria-labelledby="register-tab"
                    tabindex="0"
                  >
                    <form className="row">
                      <div className="col-4 ">
                        <label
                          for="inputCoutryCode"
                          className="form-label text-nowrap"
                        >
                          {t("login.country_code")}
                        </label>
                        {/* <Select
                          styles={customStyles}
                          name="countryOption"
                          // value={countryCode}
                          defaultValue={countryList?.[index]}
                          options={countryList}
                          onChange={(e) => handlerSelectRegister(e)}
                          filterOption={customFilterOption} 
                          getOptionLabel={(ele) => (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span class="icon">
                                <img
                                  class="icon-16 me-1 d11-country-flag"
                                  src={url.imageUrl + ele?.flag?.default}
                                  alt=""
                                />
                              </span>
                              {"  "}
                              <span>{ele?.dialingCode}</span>
                            </div>
                        
                
                          )}
                        /> */}
                        <CustomSelect countryList={countryList} index={index} handlerSelect={(e) => handlerSelectRegister(e)} />
                      </div>
                      <div className="col-8">
                        <label for="inputMobileNumber" className="form-label">
                          {t("login.mobile_number")}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="inputMobileNumber"
                          placeholder={t("login.enter_mobile_number")}
                          name="mobileNumber"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(e) => {
                            onSignupChangeHandler(e);
                          }}
                          maxlength={14}
                          value={registerInput?.mobileNumber.slice(0, 14)}
                        />
                      </div>
                      <div className="col-md-12">
                        <label for="inputCouponCode" className="form-label">
                          {t("login.coupon_code")}
                        </label>
                        <div className="couponCode-block">
                          <input
                            type="text"
                            className="form-control"
                            id="inputCouponCode"
                            placeholder={t("login.enter_coupon_code")}
                            name="couponCode"
                            onChange={(e) =>{
                              setCouponCode((prev) => e.target.value);
                              setCouponError("");
                            }}
                            value={couponCode}
                            maxLength={16}
                          />
                          {couponSuccess ? (
                            <button
                              className="btn btn-primary"
                              // style={{ position: 'absolute', right: '0', top: '0', width: '120px', height: '100%' }}
                              onClick={(e) => {
                                eventTracking(events.REMOVE_SIGNUP_CODE,{
                                  MG_CouponCode: couponCode,
                                });
                                e.preventDefault();
                                setCouponError("");
                                setCouponCode("");
                                setCouponSuccess("");
                               
                              }}
                            >
                              {t("login.remove")}
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              // style={{ position: 'absolute', right: '0', top: '0', width: '120px', height: '100%' }}
                              onClick={(e) => applySingupCode(e)}
                            >
                              {t("login.apply")}
                            </button>
                          )}
                        </div>
                        {couponError && <p className="error">{couponError}</p>}
                        {couponSuccess && (
                          <p
                            style={{
                              color: "green",
                              marginTop: "20px",
                              marginLeft: "10px",
                            }}
                          >
                            {couponSuccess}
                          </p>
                        )}
                      </div>
                      <div className="col-12">
                        <div className="form-check d-flex justify-content-start">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="gridCheck"
                            name="checkBox"
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-terms "
                            for="gridCheck"
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: translatedText,
                              }}
                            />
                            {/* I agree to Mobily’s <Link to={'/'+ routes.termsAndCondition} target="_blank"><strong>Terms & Conditions</strong> {" "} </Link> and {" "}
                            <Link to={'/' + routes.policy}  target="_blank"><strong> Privacy Policy </strong></Link> */}
                          </label>
                        </div>
                        {tncError && <p className="error">{tncError}</p>}
                      </div>
                      <div className="col-12 d-grid">
                        <button
                          type="submit"
                          className="btn btn-primary btn-arrow"
                          onClick={(e) => checkValidation(e)}
                          disabled={isBusy}
                        >
                          {t("login.register")}
                        </button>
                      </div>
                      {/* {console.log("sigin", tncError)} */}
                      {signupError && (
                        <p className="error" style={{ textAlign: "center" }}>
                          {signupError}
                        </p>
                      )}
                       {/* {tncError && (
                        <p className="error" style={{ textAlign: "center" }}>
                          {tncError}
                        </p>
                      )} */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </BaseAuth>
        </LoginLandingPage>
      )}
    </>
  );
};
export default Login;
