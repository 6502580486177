import React, { useState,useEffect } from 'react';
import { url } from "../../Utilities/url"
import Downicon  from "../../assets/images/downArrow-svg.svg";

const CustomSelect = ({ countryList, index, handlerSelect }) => {
  const [searchInput, setSearchInput] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [listToggle, setListToggle] = useState(false);
  const [selectedCountry, setselectedCountry] = useState(false);
  const [countryListData, setCountryList] = useState([]);
//  console.log("country list",countryList[index],index)
 


useEffect(() => {
  if(index>=0){
    setselectedCountry(countryList[index])
   }
   if(countryList?.length>0){
    setCountryList(countryList)
   }
}, []);

useEffect(() => {
// console.log("searchInput",searchInput?.target?.value)
if(searchInput?.target?.value){
  let searchText=searchInput?.target?.value||""
  let filterData= countryList.filter((option)=> (option?.dialingCode?.toLowerCase()?.includes(searchText?.toLowerCase())) 
                                                ||(option?.name?.toLowerCase()?.includes(searchText?.toLowerCase()))
  )
  setCountryList(filterData)
}else{
  setCountryList(countryList)
}
}, [searchInput]);

  const handleSelectChange = (e) => {
    console.log("Selected",e)
    setselectedCountry(countryListData[e])
    setListToggle(false)
    handlerSelect(countryListData[e])
  };
  const toggleList = (e) => {
    setListToggle(prev=>!prev); // Close the search field when an option is selected
  };
 
  // const filteredOptions = countryList.filter(country =>
  //   country?.name?.toLowerCase()?.includes(searchInput?.toLowerCase())
  // );

  return (
    <>
      <div className="cuMainDiv">
        <div className="cuSubDiv">
          {/* {console.log("selectedCountry",selectedCountry)} */}
          <span className='cuSubDivSpan'>
            <img
              class="d11-country-flag countryFlag"
              src={url.imageUrl + selectedCountry?.flag?.default}
              alt=""
            />
            <span className='cuSubDivCode'>
              {selectedCountry?.dialingCode}
            </span>
          </span>
          <span className="iconDownBox" onClick={toggleList}>
              <img
                class="d11-country-flag iconDown"
                src={Downicon}
                alt=""
              />
          </span>
        </div>

        <div className='cuSubDiv2'>
          {listToggle &&
            <div className='cuDropDownBox'>
              {
                countryListData.length > 10 && 
                <div className="cuDropDownSearchBox">
                <input type='text' name='countryCode' onChange={setSearchInput} placeholder='Search' />
              </div>
              }
              
              <div className="cuDropDownList" key={countryListData} style={{width:'7rem'}}>
                {(countryListData && countryListData?.length > 0 )?
                  countryListData.map((ele, index) => {
                    return <div className="cuDropDownOption" key={index} onClick={(e) => { handleSelectChange(index) }}>
                      <span>
                        <img
                          class="d11-country-flag countryFlag2"
                          src={url.imageUrl + ele?.flag?.default}
                          alt=""
                        />
                      </span>
                      <span style={{fontSize: "0.8rem"}} value={ele.dialingCode} >{ele.dialingCode}</span></div>
                  }):
                  <div className="cuDropDownOption"><span style={{fontSize: "0.5rem"}}>Country Not Found</span></div>
                }
              </div>
            </div>
          }
        </div>

      </div>
    </>
  );

};

export default CustomSelect;

// return (
//   //   <div style={{ position: 'relative' }}>
//   //  { isSearchOpen &&
//   //     <input
//   //       type="text"
//   //       placeholder="Type to filter..."
//   //       value={searchInput}
//   //       onChange={(e) => setSearchInput(e.target.value)}
//   //     />
//   //  }
//   //     <select
//   //       style={{}}
//   //       name="countryOption"
//   //       value={countryList?.[index]}
//   //       onChange={handleSelectChange}
//   //       onFocus={() => setIsSearchOpen(true)}
//   //       onBlur={() => setIsSearchOpen(false)}
//   //     >
//   //       {filteredOptions.map((option, idx) => (
//   //         <option key={idx} value={option.dialingCode} style={{ position: 'relative' ,top:"15px"}}>
//   //           <div>
//   //             <span>
//   //               <img src={url.imageUrl + option?.flag?.default} alt="" />
//   //             </span>{" "}
//   //             {option?.dialingCode}
//   //           </div>
//   //         </option>
//   //       ))}
//   //     </select>
      
//   //   </div>
//   );