import React, { useEffect, useState } from "react";
import { func } from "../../../Utilities/logFunc";
import GameTypeTab from "./GameTypeTab";
import ContestConfirmationModel from "./ContestConfirmationModel";

const ContestList = (gameId) => {
  const [active, setActive] = useState(0);
  const [showContestModal, setShowContestModal] = useState(false);

  const handleClickGameType = (name) => {
    setActive(name);
  };

  const handlerJoin = () => {
    func("handlerjoin");
    setShowContestModal(true);
  };

  useEffect(() => {
    // console.log("gameId", gameId?.gameId);
  }, []);

  return (
    <>
      <div class="col-12 mt-5 pt-lg-4 commonTab tournamentTypeTab" style={{backgroundColor:"black"}}>
        <GameTypeTab
          handleClickGameType={handleClickGameType}
          gameId={gameId?.gameId || gameId}
        />
        <div class="tab-content" id="tournamentTypeTabContent">
          <div
            class="tab-pane fade show active"
            id="solo-tab-pane"
            role="tabpanel"
            aria-labelledby="solo-tab"
            tabindex="0"
          >
            <div class="row gx-3 gy-5">
              {active == 0 ? (
                <div class="col-lg-4">
                  <div class="card tournametParticipationCard tournamentSoloCard">
                    <div class="card-header">
                      <h4 class="card-title mb-0">First Come First Serve</h4>
                      <a href="#" class="rulesLink">
                        ID: 2HWEJV
                      </a>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="card-content">
                            <div class="row justify-content-between py-2">
                              <div class="col-3">
                                <label for="" class="d-block h6">
                                  Date
                                </label>
                                <h6 class="bodyBoldFont mb-0">06/09/2023</h6>
                              </div>
                              <div class="col-3 dividersX">
                                <label for="" class="d-block h6">
                                  Time
                                </label>
                                <h6 class="bodyBoldFont mb-0">3:30 PM</h6>
                              </div>
                              <div class="col-3 dividersRight">
                                <label for="" class="d-block h6">
                                  Map
                                </label>
                                <h6 class="bodyBoldFont mb-0">Sanhok</h6>
                              </div>
                              <div class="col-3">
                                <label for="" class="d-block h6">
                                  Perspective
                                </label>
                                <h6 class="bodyBoldFont mb-0">TPP</h6>
                              </div>
                            </div>
                            <div class="row justify-content-between py-2 mt-1">
                              <div class="col-4">
                                <label for="" class="d-block h6">
                                  Prize Pool
                                </label>
                                <div class="d-flex align-items-center poolPrizeBox">
                                  <span class="icon me-2">
                                    <img
                                      src="assets/images/diamond-icon.png"
                                      alt=""
                                    />
                                  </span>
                                  <h6 class="bodyBoldFont mb-0">12000</h6>
                                </div>
                              </div>
                              <div class="col-4 dividersX d-flex">
                                <div class="mx-auto">
                                  <label for="" class="d-block h6">
                                    Per Kill
                                  </label>
                                  <div class="d-flex align-items-center poolPrizeBox">
                                    <span class="icon me-2">
                                      <img
                                        src="assets/images/diamond-icon.png"
                                        alt=""
                                      />
                                    </span>
                                    <h6 class="bodyBoldFont mb-0">150</h6>
                                  </div>
                                </div>
                              </div>
                              <div class="col-4 d-flex">
                                <div class="">
                                  <label for="" class="d-block h6">
                                    Join Using
                                  </label>
                                  <div class="d-flex align-items-center useCoinBox">
                                    <span class="icon me-2">
                                      <img
                                        src="assets/images/coin-icon-svg.svg"
                                        alt=""
                                      />
                                    </span>
                                    <h6 class="bodyBoldFont mb-0">150</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-12">
                                <div
                                  class="progress"
                                  role="progressbar"
                                  aria-label="Basic example"
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    class="progress-bar bg-info"
                                    style={{ width: "25%" }}
                                  ></div>
                                </div>
                                <div class="d-flex justify-content-between mt-2">
                                  <h5>72 players remaining</h5>
                                  <h5>8 players joined</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer d-grid">
                      <a href="#" class="btn btn-primary" onClick={handlerJoin}>
                        JOIN NOW
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {showContestModal && (
        <ContestConfirmationModel
          showContestConfirmationModal={showContestModal}
          setShowContestConfirmationModal={setShowContestModal}
        />
      )}
    </>
  );
};

export default ContestList;
