import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { url } from "../../../Utilities/url";
import {
  applyReward,
  getAccountProfile,
  getHeaderApi,
} from "../../../Utilities/controller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errors } from "../../../Utilities/error";
import { func } from "../../../Utilities/logFunc";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import { logout } from "../../../Utilities/logout";
import { toastError, toastSuccess,  } from "../../../Utilities/toast";

const RedeemRewardModal = ({
  redeemRewardModal,
  setRedeemRewardModal,
  activeRedeemReward,
  setActiveTab,
}) => {
  const [show, setShow] = useState(redeemRewardModal);
  const { profileData, updateProfileData } = useProfileDataContext();
  const {t} = useTranslation()

  const handleClose = () => {
    setRedeemRewardModal(false);
    setShow(false);
  };


  const handleSubmit = async () => {
    try {
      let data = {
        product: activeRedeemReward?._id,
        code: activeRedeemReward?.currency?.code
      };

      const res = await applyReward(data);
      // console.log("res", res?.data?.success);
      if (res?.data?.success == true) {
        toastSuccess(t('success_Redeem_Reward_successfully'));

        const resProfile = await getHeaderApi();

        if (resProfile?.data) {
          // localStorage.setItem("profile", JSON.stringify(res?.data))
          updateProfileData(resProfile?.data);
          // localStorage.setItem("profile", JSON.stringify(resProfile?.data))
          // localStorage.setItem("currentD11", getTotalCoin(resProfile?.item?.wallet));
        }
        setRedeemRewardModal(false);
        setShow(false);
        setActiveTab(2);
        func("successssss", t('success_Redeem_Reward_successfully'));
        // window.location.reload(false);
      } else {
        setRedeemRewardModal(false);
        setShow(false);
        func("succe", t('success_Redeem_Reward_successfully'));

        toastError(res?.data?.errors?.[0]?.msg);
      }
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      setRedeemRewardModal(false);
      setShow(false);
      func("succe", t('success_Redeem_Reward_successfully'));

      toastError(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="row justify-content-center">
          <div
            class="col-12 justify-content-center align-items-center"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h3 class="bodyBoldFont mb-5 text-center">
              {activeRedeemReward?.name}
            </h3>
            <div className="col-6 d11-logout-text">
              <p>
                <img
                  src={
                    url?.imageUrl + activeRedeemReward?.currency?.img?.default
                  }
                  height={"20px"}
                />{" "}
                {activeRedeemReward?.coinAmount}
              </p>
              <p>{activeRedeemReward?.description}</p>
            </div>
            <div className="col-6 fl-center">
              <button
                className="btn btn-primary "
                style={{ width: "50%" }}
                onClick={handleSubmit}
              >
                <p>
                  <img
                    src={
                      url?.imageUrl + activeRedeemReward?.currency?.img?.default
                    }
                    height={"20px"}
                    style={{ verticalAlign: "middle" }}
                  />{" "}
                  {/* {activeRedeemReward?.coinAmount} Redeem */}
                  {t('reward_store.redeem_amount',{amount:activeRedeemReward?.coinAmount})}
                </p>
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RedeemRewardModal;
