import React, { useState } from 'react'
import LandingPage from './LandingPage'
import Slice1 from './images/slice-1.png';
import Slice2 from './images/slice-2.png';
import Slice3 from './images/slice-3.png';
// import Slice4 from './images/slice-4.png';
import Slice5 from './images/slice-5.png';
const About = () => {
   const [active, setActive] = useState(0)
  return (
   <LandingPage  active={active}>

<div class="container">
         <section class="section-explore">
            <div class="row ">
               <div class="explore-text-area">
                  <h1 class="heading-primary text-center">Explore the <span class="mr-2">Future</span>  of Gaming: <br />
                     of Gaming: <span>Metaverse!</span>
                  </h1>
                  <p class="text-center">Ready to take your gaming to the next level? D11 is the ultimate destination for competitive esports<br />
                     gaming in the Middle East. Come join us for daily tournaments, meet other gamers, and show off your<br />
                     skills for a chance to win prizes and get rewarded! So, what are you waiting for? Let’s get in the game<br />
                     and level up!
                  </p>
               </div>
            </div>
         </section>
      </div>
      <div class="container">
         <div class="d-flex box-card align-items-center justify-content-center" >
            <section class="row card-grid d-flex align-items-center">
               <div class="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-center">
                  <img src={Slice1} class="grid-img" alt="" />
               </div>
               <div class="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-start flex-column">
                  <h2 class="heading-primary mb-5 ">Revolutionizing the Gaming
                     Industry
                  </h2>
                  <p class="">Ready to take your gaming to the next level? D11 is the ultimate destination for competitive esports<br />
                     gaming in the Middle East. Come join us for daily tournaments, meet other gamers, and show off your<br />
                     skills for a chance to win prizes and get rewarded! So, what are you waiting for? Let’s get in the game<br />
                     and level up!
                  </p>
               </div>
            </section>
         </div>
         <div class="d-flex box-card align-items-center justify-content-center" >
            <section class="row card-grid d-flex align-items-center">
               <div class="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-end flex-column">
                  <h2 class="heading-primary mb-5 ">Revolutionizing the Gaming
                     Industry
                  </h2>
                  <p class="">Ready to take your gaming to the next level? D11 is the ultimate destination for competitive esports<br />
                     gaming in the Middle East. Come join us for daily tournaments, meet other gamers, and show off your<br />
                     skills for a chance to win prizes and get rewarded! So, what are you waiting for? Let’s get in the game<br />
                     and level up!
                  </p>
               </div>
               <div class="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-center ">
                  <img src={Slice2} class="grid-img" alt="" />
               </div>
            </section>
         </div>
         <div class="d-flex box-card align-items-center justify-content-center" >
          <section class="row card-grid d-flex align-items-center">
             <div class="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-center">
                <img src={Slice3} class="grid-img" alt="" />
             </div>
             <div class="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-start flex-column">
                <h2 class="heading-primary mb-5">Revolutionizing the Gaming
                   Industry
                </h2>
                <p class="">Ready to take your gaming to the next level? D11 is the ultimate destination for competitive esports<br />
                   gaming in the Middle East. Come join us for daily tournaments, meet other gamers, and show off your<br />
                   skills for a chance to win prizes and get rewarded! So, what are you waiting for? Let’s get in the game<br />
                   and level up!
                </p>
             </div>
          </section>
       </div>
       <div class="d-flex align-items-center justify-content-center" >
          <section class="row card-grid d-flex align-items-center">
             <div class="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-end flex-column">
                <h2 class="heading-primary mb-5 ">Revolutionizing the Gaming
                   Industry
                </h2>
                <p class="">Ready to take your gaming to the next level? D11 is the ultimate destination for competitive esports<br />
                   gaming in the Middle East. Come join us for daily tournaments, meet other gamers, and show off your<br />
                   skills for a chance to win prizes and get rewarded! So, what are you waiting for? Let’s get in the game<br />
                   and level up!
                </p>
             </div>
             <div class="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-center ">
                <img src={Slice5} class="grid-img" alt="" />
             </div>
          </section>
       </div>
      </div>
   </LandingPage>
  )
}

export default About