import React, { useEffect, useState } from 'react'
import BaseModalSubscription from './BaseModalSubscription'
import { Link, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from "react-i18next";
import { useProfileDataContext } from '../HomePage/ProfileContext'
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
const ShowFreeContestModal = ({ showPremiumPopupContest, setShowPremiumPopupContest, title, setShowJVC, contest, tournaments }) => {

  console.log(showPremiumPopupContest)
  
  useEffect(() => {
    EventTracking();
  }, []);

  const EventTracking = () => {
    console.log(title);
    try {
      if (title == "contest") {
        if (contest != undefined && contest != null) {
          eventTracking(events.FREE_CONTEST_NON_PREMIUM, {
            MG_GameName: contest?.gameAry?.[0]?.name,
            MG_ContestID: contest?._id,
            MG_GameID: contest?.gameAry?.[0]?._id,
          });
        }
      }
      else {
        console.log(tournaments);
        if (tournaments != undefined && tournaments != null) {
          eventTracking(events.FREE_TOURNAMENT_NON_PREMIUM, {
            MG_GameName: tournaments?.gameAry?.[0]?.name,
            MG_GameID: tournaments?.gameAry?.[0]?._id,
            MG_TournamentID: tournaments?._id
          });
        }
      }
    } catch (e) { }
  }
  const { t } = useTranslation()
  const { nonPremiumUser } = useProfileDataContext()
  const titleText = title == "contest" ? t('premium.contest') : t('premium.tournament')
  const [showModal, setShowModal] = useState(showPremiumPopupContest)
  const handleClose = () => {
    setShowModal(false)
    setShowPremiumPopupContest((prev) => false);
    if (setShowJVC)
      setShowJVC(false)
  };
  const navigate = useNavigate()
  const buttonHandler = () => {
    setShowPremiumPopupContest(false)
    setShowModal(false)
    navigate('/home/get-premium')
  }
  return (
    <BaseModalSubscription showModal={showModal} setShowModal={setShowModal} text={t('premium.get_mobily_premium')} handlerClose={handleClose} buttonHandler={buttonHandler}>
      <h6 className='mt-2 d11-premium-h6'>{t('premium.you_have_already_joined_today', { n: nonPremiumUser?.noOfFreeContest, title: titleText })}</h6>
      <h6 className='mt-3 d11-premium-h6'>
        <Trans
          i18nKey="premium.subscribe_to"
          t={t}
          components={[<Link to={'/home/get-premium'} style={{ color: "var(--accent-color)" }}> {t('premium.mobily_premium')} </Link>]}
        />
      </h6>
      <h6 className='d11-premium-h6'>{t('premium.premium_users_also_get_access_to_a_host_additional_benefits')}</h6>


    </BaseModalSubscription>
  )
}

export default ShowFreeContestModal