export const INITIAL_STATE = {
    mobileNumber: '',
    countryOption: '',
    code: '',
    pin:''
}

export const loginReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_INPUT':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
    }
}