
import i18next from 'i18next';

export const errors = {
    // Success Strings
    // edit-profile
    success_editprofile_updated_successfully: i18next.t('success_editprofile_updated_successfully'),
  error_Select_your_Gamerji_Avatar: i18next.t('error_Select_your_Gamerji_Avatar'),
  error_select_country_code : i18next.t('error_select_country_code'),
    // error
     error_enter_phone: i18next.t('error_enter_phone'),
     error_enter_valid_phone: i18next.t('error_enter_valid_phone'),
     error_enter_code: i18next.t('error_enter_code'),
    error_Select_date_of_birth: i18next.t('error_Select_date_of_birth'),
    error_Please_add_Gamerji_name: i18next.t('error_Please_add_Gamerji_name'),
    error_Please_enter_a_valid_email_address: i18next.t('error_Please_enter_a_valid_email_address'),
    error_Please_enter_a_valid_mobile_number: i18next.t('error_Please_enter_a_valid_mobile_number'),
    error_Please_enter_invite_code: i18next.t('error_Please_enter_invite_code'),
  error_Add_your_Gamerji_name: i18next.t('error_Add_your_Gamerji_name'),
    // withdrawal
    error_Please_Enter_Valid_Bank_Account_Details: i18next.t('error_Please_Enter_Valid_Bank_Account_Details'),
    error_Please_enter_a_valid_withdrawal_amount: i18next.t('error_Please_enter_a_valid_withdrawal_amount'),
    error_Minimum_withdrawal_limit: i18next.t('success_editprofile_updated_successfully'),
    error_Please_enter_your_account_number: i18next.t('error_Please_enter_your_account_number'),
    error_Please_enter_a_valid_number_while_confirming_account: i18next.t('error_Please_enter_a_valid_number_while_confirming_account'),
    error_Please_Select_withdrawal_method: i18next.t('error_Please_Select_withdrawal_method'),
    error_Please_Link_your_UPI_Account: i18next.t('error_Please_Link_your_UPI_Account'),
    error_Oops_something_went_wrong_please_try_again: i18next.t('error_Oops_something_went_wrong_please_try_again'),
    error_Please_Link_your_Bank_Account: i18next.t('error_Please_Link_your_Bank_Account'),
    error_Please_Enter_Maximum_Amount: i18next.t('error_Please_Enter_Maximum_Amount'),
    error_Please_Enter_Minimum_Amount_then: i18next.t('error_Please_Enter_Minimum_Amount_then'),
    error_Please_Select_At_leaset_one_Match: i18next.t('error_Please_Select_At_leaset_one_Match'),
    error_Please_add_required_player: i18next.t('error_Please_add_required_player'),
    error_Promo_Code_is_Empty: i18next.t('error_Promo_Code_is_Empty'),
    error_Please_select_at_least_one_more_game: i18next.t('error_Please_select_at_least_one_more_game'),
    error_Select_atleast_one_game: i18next.t('error_Select_atleast_one_game'),
    error_Please_enter_a_valid_UPI_ID: i18next.t('error_Please_enter_a_valid_UPI_ID'),
    error_Enter_UPI_ID: i18next.t('error_Enter_UPI_ID'),
    error_Please_Enter_Withdraw_Amount: i18next.t('error_Please_Enter_Withdraw_Amount'),
    error_Please_Enter_Minimum_Amount: i18next.t('error_Please_Enter_Minimum_Amount'),
    error_Please_confirm_the_account_number: i18next.t('error_Please_confirm_the_account_number'),
  success_Promo_code_applied_successfully: i18next.t('success_Promo_code_applied_successfully'),
    success_UPI_Details_successfully_Linked: i18next.t('success_UPI_Details_successfully_Linked'),
    success_Bank_Account_Details_successfully_Linked: i18next.t('success_Bank_Account_Details_successfully_Linked'),
    success_Withdrawal_request_submitted_successfully: i18next.t('success_Withdrawal_request_submitted_successfully'),
    success_Favourite_Game_updated_successfully: i18next.t('success_Favourite_Game_updated_successfully'),
    success_Payment_successfull: i18next.t('success_Payment_successfull'),
    error_Please_enter_the_phone_number: i18next.t('error_Please_enter_the_phone_number'),
    success_Avatar_buy_successfully: i18next.t('success_Avatar_buy_successfully'),
    //  tournament
    error_Please_ask_user_to_add_game_name: i18next.t('error_Please_ask_user_to_add_game_name'),
  success_Game_Username_updated_successfully: i18next.t('success_Game_Username_updated_successfully'),
    error_Oops_This_tournament_has_already_started: i18next.t('error_Oops_This_tournament_has_already_started'),
     error_enter_other_than_yours: i18next.t('error_enter_other_than_yours'),
    error_enter_already_added: i18next.t('error_enter_already_added'),
    error_enter_already_added_email: i18next.t('error_enter_already_added_email'),
    error_duo_squad_tournament_in_maintenance: i18next.t('error_duo_squad_tournament_in_maintenance'),
  
    error_enter_coupon_code : i18next.t('error_enter_coupon_code'),
    error_enter_gamerji_username : i18next.t('error_enter_gamerji_username'),
    error_agree_terms_and_conditions: i18next.t('error_agree_terms_and_conditions'),

    //scrim 
    error_select_game : i18next.t('error_select_game'),
    error_select_match : i18next.t('error_select_match'),
    error_describe_your_issue : i18next.t('error_describe_your_issue'),
    error_enter_game_name : i18next.t('error_enter_game_name'),
    error_enter_handle_id : i18next.t('error_enter_handle_id'),
    error_enter_invite_code : i18next.t('error_enter_invite_code '),
    
    error_enter_search_text : i18next.t('error_enter_search_text'),
    error_enter_valid_youtube_channel_link : i18next.t('error_enter_valid_youtube_channel_link'),
    error_you_already_have_submitted_issue : i18next.t('error_you_already_have_submitted_issue '),
    error_select_issue : i18next.t('error_select_issue'),
    error_enter_valid_card_details : i18next.t('error_enter_valid_card_details'),
    error_selecr_timeslot : i18next.t('error_selecr_timeslot'),
    error_enter_tournament_is_already_started : i18next.t('error_enter_tournament_is_already_started'),
    error_you_dont_have_sufficient_coins_to_purchase_avtar : i18next.t('error_you_dont_have_sufficient_coins_to_purchase_avta'),
    error_no_users_found_for_search_name : i18next.t('error_no_users_found_for_search_name'),
    error_insufficient_balance_in_your_wallet : i18next.t('error_insufficient_balance_in_your_wallet'),
    error_please_select_game_first : i18next.t('error_please_select_game_first'),
    error_please_enter_scrim_name : i18next.t('error_please_enter_scrim_name'),
    error_please_select_scrime_type : i18next.t('error_please_select_scrime_type'),
    error_please_select_date : i18next.t('error_please_select_date'),
    error_please_select_time : i18next.t('error_please_select_time'),
    error_please_select_title : i18next.t('error_please_select_title'),
    error_please_enter_player : i18next.t('error_please_enter_player'),

    error_please_enter_winner : i18next.t('error_please_enter_winner'),
    error_please_winner_not_more_then_player : i18next.t('error_please_winner_not_more_then_player'),
    error_special_characters_are_not_allowed : i18next.t('error_special_characters_are_not_allowed'),
    error_please_game_rules : i18next.t('error_please_game_rules'),
    error_please_game_rules_min_characters : i18next.t('error_please_game_rules_min_characters'),
    error_scrim_not_create_for_past_date_time : i18next.t('error_scrim_not_create_for_past_date_time'),
    error_screenshot_upload : i18next.t('error_screenshot_upload'),
    error_please_enter_roomID : i18next.t('error_please_enter_roomID'),
    error_please_enter_password : i18next.t('error_please_enter_password'),
    error_please_enter_rank : i18next.t('error_please_enter_rank'),
    error_please_enter_unique_rank : i18next.t('error_please_enter_unique_rank'),
    error_please_select_futur_time : i18next.t('error_please_select_futur_time'),
    error_pin_length: i18next.t('error_pin_length'),
    error_pin_mismatch: i18next.t('error_pin_mismatch'),

  };
  