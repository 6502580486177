import { toast } from "react-toastify";

export function toastSuccess(msg){
    toast.success(msg,{ 
        
        autoClose: false, // Disable auto-close
    // closeButton: false, // Hide close button
    onClick: () => toast.dismiss(), // Close on click outside
    
})
}

export function toastError(msg){
    toast.error(msg,{ 
        
        autoClose: false, // Disable auto-close

    onClick: () => toast.dismiss(), // Close on click outside
    
})
}
export function toastInfo(msg){
    toast.info(msg,{ 
        
        autoClose: false, // Disable auto-close

    onClick: () => toast.dismiss(), // Close on click outside
    
})
}