import React from "react";
import LeftArrow from "../../assets/images/left-arrow.png";
import RightArrow from "../../assets/images/right-arrow.png";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
 
  let count1 = totalPages < 5 ? totalPages : 4
  let count2 = totalPages < 5 ? totalPages : 5
  return (
    <div className="d11-pagination">
      <button
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <img src={LeftArrow} />
      </button>
      <ul className="pagination mb-0">

        { pageNumbers <= 10 ? 
        pageNumbers.map((page) => (
          <li key={page} className={currentPage === page ? "active" : ""}>
            <button onClick={() => onPageChange(page)}>{page}</button>
          </li>
        ))
      :
      (
        <>
       {currentPage < 4 ?  pageNumbers.slice(0,count1).map((page) => (
          <li key={page} className={currentPage === page ? "active" : ""}>
            <button onClick={() => onPageChange(page)}>{page}</button>
          </li>
        ))
      
      :
      pageNumbers.slice(0,count1).map((page) => (
        <li key={page} className={currentPage === page ? "active" : ""}>
          <button onClick={() => onPageChange(page)}>{page}</button>
        </li>
      ))
      }
       { totalPages > 5 && currentPage == 5  && pageNumbers.slice(currentPage-1,currentPage).map((page) => (
        <>
      
          <li key={page} className={currentPage === page ? "active" : ""}>
            <button onClick={() => onPageChange(page)}>{page}</button>
          </li>
          .........
          </>
        ))}
        {console.log()}
        { totalPages > 5 && currentPage > 5 && currentPage < pageNumbers.length - count1 && pageNumbers.slice(currentPage-1,currentPage).map((page) => (
        <>
        .....
          <li key={page} className={currentPage === page ? "active" : ""}>
            <button onClick={() => onPageChange(page)}>{page}</button>
          </li>
          .....
          </>
        ))}
         {totalPages > 5 &&  currentPage == pageNumbers.length - count1  && pageNumbers.slice(currentPage-1, currentPage).map((page) => (
        <>
      .........
          <li key={page} className={currentPage === page ? "active" : ""}>
            <button onClick={() => onPageChange(page)}>{page}</button>
          </li>
          
          </>
        ))}
{/* {console.log(currentPage, pageNumbers.length -4, pageNumbers.length)} */}
{ ((totalPages > 5 && (currentPage < count2 ||  (currentPage > pageNumbers.length - count1) && currentPage <= pageNumbers.length))) && 
  <>
.....................
  </>
       }
      {/* {console.log(pageNumbers.length, count1)} */}
        {totalPages > 5 && pageNumbers.slice(pageNumbers.length - count1).map((page) => (
          <li key={page} className={currentPage === page ? "active" : ""}>
            <button onClick={() => onPageChange(page)}>{page}</button>
          </li>
        ))}


        </>

       
      )


      }
      </ul>
      <button
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <img src={RightArrow} />
      </button>
    </div>
  );
};

export default Pagination;
