import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import {
  updateTelrTransaction,
  getHeaderApi,
} from "../../../Utilities/controller";
import "../../../assets/css/PaymentStatus.css";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useProfileDataContext } from "../ProfileContext";
// import Pending from "../../assets/images/pending-payment.png";
// import Fail from "../../assets/images/fail-payment.png";
// import Success from "../../assets/images/success-payment.png";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { logout } from "../../../Utilities/logout";
import { toastError } from "../../../Utilities/toast";

const PaymentStatus = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const [transactionData, setTansactionData] = useState({});
  const [show, setShow] = useState(true);
  const { profileData, updateProfileData } = useProfileDataContext();
  const {t} = useTranslation()

  const handleClose = () => {
    setShow(false);
    navigate("/home");
  };
  const handlerDone = async () => {
    const resProfile = await getHeaderApi();
    updateProfileData(resProfile?.data);
    setShow(false);
    navigate("/home/coin-store");
  };
  useEffect(() => {
    // let timer = setTimeout(() => {
      fetchCoinStoreData();
    // }, 6000);
    // return () => {
    //   clearTimeout(timer);
    // };
  }, [id]);

  const fetchCoinStoreData = async () => {
    try {
      let body = {
        transactionUniqueID: id
        // clientSecret: searchParams.get("payment_intent"),
      };
      const res = await updateTelrTransaction(body);
      if (res.data.success) {
        setTansactionData(res.data.data);
      } else {
      }
    } catch (error) {
      // console.log(error)
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      toastError(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="content text-center">
          <div class="row justify-content-center">
            <div class="col-12">
              <div className="pg_status_main_component">
                {/* <ToastContainer /> */}

                <div className="pg_status_body">
                  {/* <div className="pg_status_title_component">
                    <div className="pg_title">Payment Status</div>
                </div> */}
                  <div className="pg_ps_status_component">
                    <div
                      className={
                        transactionData?.paymentStatus === "SUCCESS"
                          ? "pg_status_background_success"
                          : transactionData?.paymentStatus === "FAILED"
                          ? "pg_status_background_failed"
                          : transactionData?.paymentStatus === "DECLINED" ||
                            transactionData?.paymentStatus === "CANCELLED"
                          ? "pg_status_background_failed"
                          : "pg_status_background_pending"
                      }
                    >
                      {/* Status Icon */}
                      {/* <img className="pg_status_icon" src={
                            transactionData?.paymentStatus === 'SUCCESS' ? Success
                                : transactionData?.paymentStatus === 'FAILED' ? Fail
                                    : transactionData?.paymentStatus === 'DECLINED' || transactionData?.paymentStatus === 'CANCELLED' ? Fail
                                        : Pending
                        } /> */}

                      {/* Title */}
                      <div className="pg_status_title">
                        {transactionData?.paymentStatus === "SUCCESS"
                          ? "Success!"
                          : transactionData?.paymentStatus === "FAILED"
                          ? "Failure!"
                          : transactionData?.paymentStatus === "DECLINED" ||
                            transactionData?.paymentStatus === "CANCELLED"
                          ? "User Cancelled"
                          : "Processing..."}
                      </div>

                      {transactionData?.paymentStatus === "SUCCESS" && (
                        <>
                          <div className="pg_status_amount">
                            {transactionData?.currency?.symbol}{" "}
                            {transactionData?.amount}
                          </div>

                          <div className="pg_status_date">
                            {/* {new Date(
                              transactionData?.paymentTimestamp
                            ).toLocaleString()} */}
                            {moment(transactionData?.paymentTimestamp).format("Do MMMM YYYY hh:mm A")}
                          </div>

                          <div className="pg_status_transaction">
                          {t('premium.transaction_id')} : {transactionData?.transactionId}
                          </div>
                        </>
                      )}

                      {transactionData?.paymentStatus === "SUCCESS" ? (
                        <div className="pg_status_transaction">
                         {t('premium.your_subscription_success',{amount:transactionData.coinAmount})}
                        </div>
                      ) : transactionData?.paymentStatus === "FAILED" ? (
                        <div className="pg_status_transaction">
                         {t('premium.cant_proceed')}
                        </div>
                      ) : (
                        t('premium.transaction_inprogess')
                      )}
                    </div>
                    <button
                      className={`btn btn-primary main-btn next mt-4 mb-4 ml-2 ${
                        transactionData?.paymentStatus !== "SUCCESS"
                        ? ""
                        : transactionData?.paymentStatus !== "FAILED"
                        ? ""
                        : transactionData?.paymentStatus !== "DECLINED" ||
                          transactionData?.paymentStatus !== "CANCELLED"
                        ? ""
                        : "disabled"
                      }`}
                      style={{ width: "100%", border: "1px solid" }}
                      onClick={handlerDone}
                    >
                      {t('premium.done')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default PaymentStatus;
