import React, { useState } from 'react'
import Background from './images/Rectangle.jpg'
import Player from './images/mobilylandingpage.png'
import CardSection from './CardSection'
import LandingPage from './LandingPage'
import { Link } from 'react-router-dom'
import OurPlanone from './images/CASUAL-2.png'
import IPAD from './images/mobilylandingpage.png'
import OurPlantwo from './images/PRO-2.png'
import OurPlanthree from './images/MASTER-2.png'
import { routes } from '../../Utilities/routesFb'
import { useTranslation } from 'react-i18next'

const LandingMain = () => {
  const {t} = useTranslation()
  const [active, setActive] = useState(5)
  return (
    <LandingPage active={active} setActive={setActive}>
    <main class="d-grid align-content-center">
    <section class="hero_section section-how">
          <div class="hero_section--card">
            <div class="container">
              <div class="row hero-area">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div class="banner-content">
                  <h1 class="mb-3">{t('landing.welcome_mobilt_gamers')}<br /></h1>
            <p>{t('landing.design_by_gamers')}<br />
             </p> <Link to={`/login`} class="btn1 btn1-primary margin-right-sm mt-5 hover-primary landing-rtl" style={{color:'#fff',border:'1px solid #fff', textDecoration:'none',padding:"10px"}}>{t('landing.lets_play')}</Link>
         
                  </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div class="hero_section-cardImage">
                    <img
                      src={IPAD}
                      class="img-fluid hero_section--image rounded-start"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  </main>
       <div class="container top-and-bottom"></div>
       <CardSection />
        <div class="container top-and-bottom"></div>
        <div class="container">
          <section class="section-connect">
            <div class="row flex-column text-align-center play_win_earn_img" style={{alignItems:'center'}}>
              {/* <div class="col d-flex justify-content-center my-4">
                <h1 class="heading-primary mt-3 mb-3 landing-rtl">{t('landing.play_win_earn')}</h1>
              </div>
              <div class="col-lg-8 col-12 d-flex justify-content-center landing-rtl">
                <p class="">{t('landing.register_for_free_today')}</p>
              </div> */}
              
            </div>
          </section>
          <div class="col d-flex justify-content-center mb-5 landing-rtl">
                <Link to={routes.login} class="btn1 btn1-primary margin-right-sm mt-5 hover-primary" style={{color:'#fff',border:'1px solid #fff', textDecoration:'none',}}>{t('landing.veiw_all')}</Link>
              </div>
        </div>
        <div class="container top-and-bottom"></div>
        <div class="container">
          <section class="section-how">
            <div class="row flex-column" style={{alignItems:'center'}}>
              <div class="col d-flex justify-content-center my-4">
                <h1 class="heading-primary mt-3 mb-3 landing-rtl">{t('landing.our_plan')}</h1>
              </div>
              <div class="col-lg-8 col-12 d-flex justify-content-center landing-rtl" >
                <p class="">{t('landing.register_for_free_today')}</p>
              </div>
              <div class="col d-flex justify-content-center" >
              <div class="row row-cols-1 row-cols-md-3 g-4">
  <div class="col">
    <div class=" h-100">
    <img src={OurPlanone} style={{width:'100%',height:'100%',objectFit:'cover'}} />
      
    </div>
  </div>
  <div class="col">
    <div class=" h-100">
    <img src={OurPlantwo} style={{width:'100%',height:'100%',objectFit:'cover'}} />
      
    </div>
  </div>
  <div class="col">
    <div class=" h-100">
    <img src={OurPlanthree} style={{width:'100%',height:'100%',objectFit:'cover'}} />
      
    </div>
  </div>
</div>
               
               
                
              </div>
              <div class="col d-flex justify-content-center mb-5 " >
                <Link to={`/login`} class="btn1 btn1-primary margin-right-sm mt-5 hover-primary landing-rtl" style={{color:'#fff',border:'1px solid #fff', textDecoration:'none'}}>{t('landing.go_premium')}</Link>
              </div>
            </div>
          </section>
        </div>
        {/* <div class="">
          <section class="section-subscribe">
            <div class="container d-flex align-items-center justify-content-center flex-column">
              <div class="row flex-column">
                <div class="col-12 d-flex justify-content-center my-5">
                  <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewbox="0 0 54 54" fill="none">
                  <g clip-path="url(#clip0_3879_96839)">
                    <path d="M48.5312 7.51562H5.46875C2.72455 7.51562 0.5 9.74018 0.5 12.4844V42.2969C0.5 45.0411 2.72455 47.2656 5.46875 47.2656H48.5312C51.2754 47.2656 53.5 45.0411 53.5 42.2969V12.4844C53.5 9.74018 51.2754 7.51562 48.5312 7.51562ZM48.5312 12.4844V16.7083C46.2102 18.5984 42.51 21.5374 34.5994 27.7317C32.8561 29.103 29.4028 32.3974 27 32.359C24.5976 32.3978 21.1432 29.1025 19.4006 27.7317C11.4913 21.5384 7.79009 18.5987 5.46875 16.7083V12.4844H48.5312ZM5.46875 42.2969V23.0842C7.84071 24.9734 11.2044 27.6246 16.3315 31.6393C18.594 33.4203 22.5563 37.3519 27 37.328C31.4219 37.3519 35.3339 33.4773 37.6676 31.6401C42.7945 27.6255 46.1592 24.9736 48.5312 23.0843V42.2969H5.46875Z" fill="#FFBD59"></path>
                  </g>
                  <defs>
                    <clippath id="clip0_3879_96839">
                      <rect width="53" height="53" fill="white" transform="translate(0.5 0.890625)"></rect>
                    </clippath>
                  </defs></svg>
                </div>
                <div class="col-12">
                  <h1 class="heading-primary text-center">Subscribe to our newsletter.</h1>
                </div>
                <div class="col-12 mt-2">
                  <p class="subheading text-center mb-5">Don't miss new updates on your email</p>
                </div>
                <div class="col-12">
                  <div class="input-group subscribe_input mb-5">
                    <input type="email" class="form-control text-white" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2" /> <span class="input-group-text" id="basic-addon2"><strong>Subscribe</strong></span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div> */}
       
        </LandingPage>
  )
}

export default LandingMain