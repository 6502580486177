import "./App.css";
import "../src/assets/css/style.css";
import "../src/assets/css/newStyle.css";

import LandingPage from "./Components/LandingPage/LandingPage";

import Login from "./Components/Auth/Login";
import Otp from "./Components/Auth/Otp";
import Avartar from "./Components/Auth/Avartar";
import { Navigate, Route, Routes } from "react-router";

import { routes } from "./Utilities/routesFb";
import HomePage from "./Components/HomePage/HomePage";
import GameTournament from "./Components/HomePage/Tournament/GameTournament";
import Main from "./Components/HomePage/Main";
import TournamentDetails from "./Components/HomePage/Tournament/TournamentDetails";
import { useEffect, useState } from "react";
import { func } from "./Utilities/logFunc";

import WorldOfEsports from "./Components/HomePage/WorldOfEsports/WorldOfEsports";
import WOEMain from "./Components/HomePage/WorldOfEsports/WOEMain";
import News from "./Components/HomePage/WorldOfEsports/AllNews";
import NewsDetails from "./Components/HomePage/WorldOfEsports/NewsDetails";
import AllPopularVideos from "./Components/HomePage/WorldOfEsports/AllPopularVideos";
import AllTopProfiles from "./Components/HomePage/WorldOfEsports/AllTopProfiles";
import LeaderBoard from "./Components/HomePage/LeaderBoard/LeaderBoard";
import PrivateContest from "./Components/HomePage/PrivateContest/PrivateContest";
import ManagePrivateContest from "./Components/HomePage/PrivateContest/ManagePrivateContest";
import MyContest from "./Components/HomePage/MyContest/MyContest";
import Contest from "./Components/HomePage/Contest/Contest";
import ContestDetails from "./Components/HomePage/Contest/ContestDetails";
import SignupOtp from "./Components/Auth/SignupOtp";
import Base from "./Components/HomePage/PrivateContest/Base";
import CreatePrivateContest from "./Components/HomePage/PrivateContest/CreatePrivateContest";
import Protected from "./Protected";
import CustomerCare from "./Components/HomePage/CustomerCare/CustomerCare";
import CustomerCareForm from "./Components/HomePage/CustomerCare/CustomerCareForm";
import CRMMain from "./Components/HomePage/CustomerCare/CRMMain";
import RewardStore from "./Components/HomePage/RewardStore/RewardStore";
import ScrimDetails from "./Components/HomePage/PrivateContest/ScrimDetails";
import FreeGames from "./Components/HomePage/FreeGames/FreeGames";

import Legality from "./Components/HomePage/Legality/Legality";
import CoinStore from "./Components/HomePage/CoinStore/CoinStore";
import MyProfile from "./Components/Profile/MyProfile";
import PaymentStatus from "./Components/HomePage/CoinStore/PaymentStatus";
import TicketDetails from "./Components/HomePage/CustomerCare/TicketDetails";
import SubscriptionTypeList from "./Components/Subscription/SubscriptionTypeList";
import PaymentStatusSubscription from "./Components/Subscription/PaymentStatusSubscription";
import UserProfile from "./Components/Profile/UserProfile";
import Contact from "./Components/LandingPage/Contact";
import About from "./Components/LandingPage/About";
import LandingMain from "./Components/LandingPage/LandingMain";
import Cookies from 'js-cookie'
import { useTranslation } from "react-i18next";
import ForgetPin from "./Components/Auth/ForgetPin";
import SetPin from "./Components/Auth/SetPin";
import TermsAndCondition from "./Components/LandingPage/TermsAndCondition";
import Policy from "./Components/LandingPage/Policy";
import PrivacyPolicy from "./Components/HomePage/Legality/PrivacyPolicy";
// Firebase SDKs
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
function App() {
  const [gameName, setGameName] = useState("");
  const [gameId, setGameId] = useState("");
  const [blog, setBlog] = useState(null);
  const token = localStorage.getItem("token");
const [currentLng, setcurrentLng] = useState(localStorage.getItem("i18nextLng"))
const urlParams = new URLSearchParams(window.location.search);
const codeFromURL = urlParams.get('code');
if(codeFromURL)
  localStorage.setItem('code', codeFromURL)
// console.log("codeFromURL", codeFromURL);
// console.log(token, window.location.pathname)
  const handleClickGame = (name, id = "") => {
    setGameName(name);
    setGameId(id);
  };

  const handleClickBlog = (item) => {
    setBlog(item);
  };
  const currentLang = Cookies.get('i18next')
  const {t} = useTranslation()
  useEffect(()=>{
    // console.log(localStorage.getItem("i18nextLng") == "ar" ? "rtl" : "ltr")
    // let currentLng = localStorage.getItem("i18nextLng")
    document.documentElement.dir = currentLang == 'ar' ? 'rtl':"ltr"
    document.title = t('title')
   
  },[currentLng,t])
  // console.log("83333",token, window.location.pathname)
  return (
    <>
    <Routes>
    <Route exact path="/" element={<LandingMain />} />
  <Route path={routes.contact} element={<Contact />} />
  <Route path={routes.about} element={<About />} />
  <Route path={routes.termsAndCondition} element={<TermsAndCondition />} />
  <Route path={routes.policy} element={<Policy />} />
  <Route
    
    path={routes.home}
    element={
      token != null || token != undefined || token != '' || token != 'null' || token != 'undefined' ? (
        <Navigate replace to="/home" />
      ) : (
        codeFromURL ? <Navigate replace to={`/login?code=${codeFromURL}`} /> : 
        <Navigate replace to="/login" />
      )
    }
  />
  <Route
   
    path={routes.login}
    element={token ? <Navigate replace to="/home" /> : <Login />}
  />
  <Route exact path={routes.forgetPin} element={<ForgetPin />} />
  <Route exact path={routes.setpin} element={<SetPin />} />
  <Route exact path={routes.otp} element={token ? <Navigate replace to="/home" /> : <Otp />} />
  <Route exact path={routes.signupotp} element={token ? <Navigate replace to="/home" /> : <SignupOtp />} />
  <Route exact path={routes.avatar} element={<Protected Component={Avartar} />} />

  <Route
    path={routes.home}
    element={<Protected Component={HomePage} />}
  >
    <Route index element={<Main />} />
    <Route path={routes.gameTournament} element={<GameTournament />} />
    <Route path={routes.tournamentDetails} element={<TournamentDetails />} />

    <Route path={routes.worldOfEsports} element={<WOEMain />}>
      <Route index element={<WorldOfEsports handleClickBlog={handleClickBlog} />} />
      <Route path={routes.news} element={<News />} />
      <Route path={routes.newsDetails} element={<NewsDetails />} />
      <Route path={routes.popularVideos} element={<AllPopularVideos />} />
      <Route path={routes.topProfiles} element={<AllTopProfiles />} />
    </Route>

    <Route path={routes.leaderBoard} element={<LeaderBoard />} />

    <Route path={routes.privateContest} element={<Base />}>
      <Route index element={<PrivateContest />} />
      <Route path={routes.managePrivateContest} element={<ManagePrivateContest />} />
      <Route path={routes.createPrivateContest} element={<CreatePrivateContest />} />
      <Route path={routes.editPrivateContest} element={<CreatePrivateContest />} />
    </Route>

    <Route path={routes.myContest} element={<MyContest />} />
    <Route path={routes.scrimDetails} element={<ScrimDetails />} />
    <Route path={routes.freeGames} element={<FreeGames />} />

    <Route path={routes.gameContest} element={<Contest />} />
    <Route path={routes.contestDetails} element={<ContestDetails />} />

    <Route path={routes.customerCare} element={<CRMMain />}>
      <Route index element={<CustomerCare />} />
      <Route path={routes.raiseACompaint} element={<CustomerCareForm />} />
      <Route path={routes.ticketDetails} element={<TicketDetails />} />
    </Route>

    <Route path={routes.rewardStore} element={<RewardStore />} />
    <Route path={routes.legality} element={<Legality />} />
    <Route path={routes.coinStore} element={<CoinStore />} />
    <Route path={routes.myProfile} element={<MyProfile />} />
    <Route path={routes.userProfile} element={<UserProfile />} />

    <Route path={routes.paymentStatus} element={<PaymentStatus />} />
    <Route path={routes.getSubscriptionTypeList} element={<SubscriptionTypeList />} />
    <Route path={routes.paymentStatusSubscription} element={<PaymentStatusSubscription />} />
  </Route>

 
</Routes>

    </>
  );
}

export default App;
