import React from 'react'
import BaseModalSubscription from './BaseModalSubscription'
import { useNavigate } from 'react-router'
import { func } from '../../Utilities/logFunc'
import moment from 'moment'
import { useTranslation } from "react-i18next";

const CallbackConfiramtion = ({showCallbackConfirmation,setShowCallbackConfirmation,data,date,time}) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  // console.log("data 7", data, date, time)
  const buttonHandler = ()=>{
    setShowCallbackConfirmation(false)
    navigate('/home/customer-care')
  }
  const handlerClose = ()=>{
    setShowCallbackConfirmation(false)
    navigate('/home/customer-care')
  }
  return (
    <BaseModalSubscription text={t('crm.done')} showModal={showCallbackConfirmation} setShowModal={setShowCallbackConfirmation} buttonHandler={buttonHandler} handlerClose={handlerClose}>
        <h6 className='mt-3 d11-premium-h6' style={{color:"var(--primary-color-emphasis)"}}>{t('crm.your_request_has_been_submitted_succesfully')}</h6>
     
      <h6 className='d11-premium-h6 mt-2'>{t('crm.you_will_receive_a_call_between')}</h6>
      <h6 className='d11-premium-h6 mt-2'>{time?.startTime + " - " + time?.endTime} {t('crm.on')} {moment(date).format("DD/MM/YYYY")}</h6>

    </BaseModalSubscription>
  )
}

export default CallbackConfiramtion