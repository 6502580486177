import React, { useCallback, useEffect, useState } from "react";
import Info from "../../../assets/images/info-icon-svg.svg";
import RoomIdPwdModal from "./RoomIdPwdModal";
import { func } from "../../../Utilities/logFunc";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  getLeaderBorad,
  getStatus,
  viewScrimById,
} from "../../../Utilities/controller";
import moment from "moment/moment";
import { url } from "../../../Utilities/url";
import { routesBackend } from "../../../Utilities/routesBackend";
import { useDropzone } from "react-dropzone";
import UploadSSModal from "./UploadSSModal";
import {LeaderBoard }from "./LeaderBoard";
import { routes } from "../../../Utilities/routesFb";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { logout } from "../../../Utilities/logout";
import { toastError, toastInfo } from "../../../Utilities/toast";

const ManagePrivateContest = () => {
  const [showRoomPwdModal, setShowRoomPwdModal] = useState("");
  const [showUploadSSModal, setShowUploadSSModal] = useState("");
  const [rankSubmitted, setRankSubmitted] = useState(false);
  const {t} = useTranslation()

  const [roomId, setRoomId] = useState("");
  const location = useParams();
  const { id } = location;
  func("sttaae", id);
  const [password, setPassword] = useState("");
  const [uploadSS, setUploadSS] = useState("");
  const [scrimData, setScrimData] = useState([]);
  const [status, setStatus] = useState([]);
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [files, setFiles] = useState([]);
  const [isSSUpload, setIsSSUpload] = useState(false);
  const [rmIdpWdSubmit, setRmIdpWdSubmit] = useState(false);
  const [disabled, setDisabled] = useState(!"disabled");
  const [ssTime, setSSTime] = useState(false);
  const navigate = useNavigate();

  const handlerAdd = () => {
    setShowRoomPwdModal(true);
  };

  const fetchScrimDetails = async () => {
    let payload = {
      scrimId: id,
      page: 1,
      limit: 10,
      sortBy: "createdAt",
      sort: "asc",
    };
    try {
      const resScrim = await viewScrimById({ id: id, game:localStorage.getItem('activeGameId') });
      let resStatus = await getStatus({ scrimId: id });
      const resLeaderBoard = await getLeaderBorad(payload);
      if (resScrim?.success) {
        setScrimData(resScrim?.data[0]?.param);
        var d = resScrim?.data[0].param.date;

        const newDate = moment(d).format("y-MM-DD");
        const newTime = moment(d).format("HH:mm:ss");
        const formate = "YYYY-MM-DD h:m A";
        var dateTime = moment(newDate + " " + newTime);
        const currentTime = moment();
        var RPEndTime = moment(moment(dateTime).subtract(15, "minutes"));
        var RPStartTime = moment(dateTime)
          .subtract(15, "minutes")
          .subtract(
            parseInt(resScrim.data[0].param?.gameType.settings.RPTime),
            "minutes"
          );
        let showRIDP = currentTime.isBetween(RPStartTime, RPEndTime);
        func("func", showRIDP);
        if (!showRIDP) {
          setDisabled("disabled");
        } else {
          setDisabled(!"disabled");
        }
        const startTime = moment(moment(dateTime), formate);
        const SSEndTime = moment(
          moment(dateTime).add(
            resScrim.data[0].param?.gameType.settings.SSUploadTime,
            "minutes"
          ),
          formate
        );
        const WDStartTime = moment(moment(dateTime), formate);
        const WDEndTime = moment(
          moment(dateTime).add(
            resScrim.data[0].param?.gameType.settings.winningsTime,
            "minutes"
          ),
          formate
        );
        let SSTime = currentTime.isBetween(startTime, SSEndTime);
        setSSTime(SSTime);
        let WDTime = currentTime.isBetween(WDStartTime, WDEndTime);
        localStorage.setItem("win", WDTime);
      }
      if (resStatus?.success) {
        setStatus(resStatus?.data);
      }
      if (resLeaderBoard?.success) {
        setLeaderBoardData(resLeaderBoard?.data);
      }
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      toastError(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    func(roomId, password);
    // fetchScrimDetails();
  }, []);

  useEffect(() => {
    func(roomId, password);
    fetchScrimDetails();
  }, [rmIdpWdSubmit, rankSubmitted, isSSUpload]);

  return (
    <>
      <div class="row gy-4 gx-5">
        <div class="col-lg-6">
          <div class="pe-lg-3">
            <div class="row">
              <div class="col-lg-8">
                <div class="card tournametParticipationCard tournamentSoloCard contestTournamentSoloCard">
                  <div class="card-header">
                    {func("title", scrimData)}
                    <h4 class="card-title mb-0">{scrimData?.title}</h4>

                    {status?.status == "Waiting" ? (
                      <>
                        <button
                          class="rulesLink fs-5"
                          onClick={() =>
                            navigate(
                              "/home/" +
                                routes.privateContest +
                                "/edit-contest/" +
                                scrimData?._id
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewbox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.32604 14.0439L2.23104 16.9639C2.189 17.0763 2.18018 17.1984 2.20565 17.3156C2.23113 17.4329 2.28981 17.5403 2.37469 17.6251C2.45958 17.7099 2.56708 17.7685 2.68435 17.7938C2.80162 17.8192 2.92371 17.8102 3.03604 17.7681L5.95521 16.6731C6.28933 16.5479 6.59279 16.3527 6.84521 16.1006L15.301 7.64474C15.301 7.64474 15.006 6.76058 14.1227 5.87641C13.2394 4.99308 12.3544 4.69808 12.3544 4.69808L3.89854 13.1539C3.64639 13.4063 3.45118 13.7098 3.32604 14.0439ZM13.5335 3.51891L14.686 2.36641C14.8927 2.15974 15.1685 2.02808 15.4569 2.07641C15.8627 2.14308 16.4835 2.34474 17.0685 2.93058C17.6544 3.51641 17.856 4.13641 17.9227 4.54224C17.971 4.83058 17.8394 5.10641 17.6327 5.31308L16.4794 6.46558C16.4794 6.46558 16.1852 5.58224 15.301 4.69891C14.4177 3.81391 13.5335 3.51891 13.5335 3.51891Z"
                              fill="white"
                            ></path>
                          </svg>
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div class="card-body pb-3">
                    <div class="row">
                      <div class="col-12">
                        <div class="card-content">
                          <div class="row justify-content-between py-2">
                            <div class="col-3">
                              <label for="" class="d-block h6">
                                {t('contest_details.date')}
                              </label>
                              <h6 class="bodyBoldFont mb-0">
                                {moment(scrimData?.date).format("DD/MM/y")}
                              </h6>
                            </div>
                            <div class="col-3 dividersX">
                              <label for="" class="d-block h6">
                              {t('contest_details.time')}
                              </label>
                              <h6 class="bodyBoldFont mb-0">
                                {moment(scrimData?.time).format("hh:mm A")}
                              </h6>
                            </div>
                            <div
                              class={`col-3 ${
                                scrimData?.titles?.[1]?.name
                                  ? "dividersRight"
                                  : ""
                              }`}
                            >
                              <label for="" class="d-block h6 mb-1 text-ellipsis_contest">
                                {scrimData?.titles?.[0]?.name}
                              </label>
                              <h6 class="bodyBoldFont mb-0 text-ellipsis_contest">
                                {scrimData?.titles?.[0]?.value}
                              </h6>
                            </div>
                            {scrimData?.titles?.[1]?.name ? (
                              <div class="col-3">
                                <label for="" class="d-block h6 mb-1 text-ellipsis_contest">
                                  {scrimData?.titles?.[1]?.name}
                                </label>
                                <h6 class="bodyBoldFont mb-0 text-ellipsis_contest">
                                  {scrimData?.titles?.[1]?.value}
                                </h6>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div class="row justify-content-between py-2 mt-1">
                            <div class="col-4">
                              <label for="" class="d-block h6">
                                {t('contest_details.players')}
                              </label>
                              <div class="d-flex align-items-center poolPrizeBox">
                                <h6 class="bodyBoldFont mb-0">
                                  {scrimData?.noOfPlayerCapacity}
                                </h6>
                              </div>
                            </div>
                            <div class="col-4 dividersX d-flex">
                              <div class="">
                                <label for="" class="d-block h6">
                                {t('tournaments.winners')}
                                </label>
                                <div class="d-flex align-items-center poolPrizeBox">
                                  <h6 class="bodyBoldFont mb-0">
                                    {scrimData?.noOfWinner}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="col-4 d-flex">
                              <div class="">
                                <label for="" class="d-block h6">
                                {t('contest.id')}
                                </label>
                                <div
                                  class="d-flex align-items-center useCoinBox"
                                  onClick={() => {
                                    // console.log("clecked infoioo")
                                    navigator.clipboard.writeText(
                                      scrimData?.shortCode
                                    );
                                    toastInfo(t('info_Copied'));
                    }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <h6 class="bodyBoldFont mb-0">
                                    {scrimData?.shortCode}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-12">
                              <div
                                class="progress"
                                role="progressbar"
                                aria-label="Basic example"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <div
                                  class="progress-bar bg-info"
                                  style={{
                                    width: `${
                                      (100 * scrimData?.noOfJoinedPlayer) /
                                      scrimData?.noOfPlayerCapacity
                                    }%`,
                                  }}
                                ></div>
                              </div>
                              <div class="d-flex justify-content-between mt-2">
                                <h6>
                                {t('contest_details.players_remaining',{players:scrimData?.noOfPlayerCapacity -
                                    scrimData?.noOfJoinedPlayer})}
                                </h6>
                                <h6>
                                {t('contest_details.players_joined',{players:scrimData?.noOfJoinedPlayer})}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="text-primary my-4 pt-2 bodyBoldFont">
              {t('private_contest.manage_your_contest')}
            </h4>
            <div class="row mb-3 g-3">
              <div class="col-md-4 col-6">
                <div
                  class={`card manageYourContestCard h-100 ${
                    !disabled ? "active" : ""
                  }`}
                >
                  <div class="card-body d-flex  justify-content-between flex-column">
                    <div class="card-title d-flex align-items-center justify-content-between mb-4">
                      <h5 class="fs-4 mb-0">{t('private_contest.room_id_password')}</h5>

                      <div>
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {scrimData.room?.id
                                ? t('private_contest.your_room_pass')
                                : t('error_please_enter_roomID_password')}
                            </Tooltip>
                          )}
                          placement="top"
                        >
                          <Link
                            to=""
                            data-bs-toggle="tooltip"
                            data-bs-title="Default tooltip"
                          >
                            <img className="icon-20" src={Info} alt="" />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      {/* <Link
                      to=''
                        data-bs-toggle="tooltip"
                        data-bs-title="Default tooltip"
                      >
                        <img src={Info} alt="" />
                      </Link> */}
                    </div>
                    {func("room", roomId)}
                    {scrimData.room?.id ? (
                      <>
                        <h4 class="small bodyBoldFont mb-2">
                        {t('contest.room_id')}: {scrimData?.room?.id}
                        </h4>
                        <h4 class="small bodyBoldFont mb-0">
                        {t('contest.password')}: {scrimData?.room?.password}
                        </h4>
                      </>
                    ) : (
                      <button
                        class={`btn btn-primary btn-sm ${
                          disabled ? "disabled" : ""
                        }`}
                        onClick={handlerAdd}
                      >
                        {t('private_contest.add')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-6">
                <div
                  class={`card manageYourContestCard h-100 ${
                    status?.status == "In Process" ? "active" : ""
                  }`}
                >
                  <div class="card-body d-flex  justify-content-between flex-column">
                    <div class="card-title d-flex align-items-center justify-content-between mb-4">
                      <h5 class="fs-4 mb-0">{t('private_contest.upload_screenshot')}</h5>
                      <div>
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {isSSUpload || scrimData?.picture?.length > 0
                                ? t('private_contest.screenshot_submiited')
                                : t('private_contest.please_upload_ss')}
                            </Tooltip>
                          )}
                          placement="top"
                        >
                          <Link
                            to=""
                            data-bs-toggle="tooltip"
                            data-bs-title="Default tooltip"
                          >
                            <img className="icon-20" src={Info} alt="" />
                          </Link>
                        </OverlayTrigger>
                      </div>
                    </div>
                    {func("picture", status?.status, "In Progress")}
                    {isSSUpload || scrimData?.picture?.length > 0 ? (
                      <p class="text-success mb-0">{t('private_contest.screenshot_submiited')}</p>
                    ) : !(status?.status == "In Progress") ? (
                      <button class="btn btn-primary btn-sm disabled">
                        {t('private_contest.upload')}
                      </button>
                    ) : (
                      <button
                        class="btn btn-primary btn-sm "
                        onClick={() => setShowUploadSSModal(true)}
                      >
                        {t('private_contest.upload')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-6">
                <div class="card manageYourContestCard h-100">
                  <div class="card-body d-flex  justify-content-between flex-column">
                    <div class="card-title d-flex align-items-center justify-content-between mb-4">
                      <h5 class="fs-4 mb-0">{t('private_contest.submit_rank')}</h5>
                      <div>
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {rankSubmitted || status?.status == "Completed"
                                ?  t('private_contest.ranks_submitted')
                                :t('private_contest.upload_ranks')}
                            </Tooltip>
                          )}
                          placement="top"
                        >
                          <Link
                            to=""
                            data-bs-toggle="tooltip"
                            data-bs-title="Default tooltip"
                          >
                            <img className="icon-20" src={Info} alt="" />
                          </Link>
                        </OverlayTrigger>
                      </div>
                    </div>
                    {rankSubmitted || status?.status == "Completed" ? (
                      <p class="text-success mb-0">{t('private_contest.ranks_submitted')}</p>
                    ) : scrimData?.picture?.length > 0 ? (
                      <button class="btn btn-primary btn-sm disabled">
                        {t('contest_details.pending')}...
                      </button>
                    ) : (
                      <button class="btn btn-primary btn-sm disabled">
                        {t('private_contest.add_rank')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!scrimData?.room?.id ? (
              <p class="small mb-4 pb-2">
                <span class="bodyBoldFont">{t('private_contest.note_please_Eneter_roomid_password_atleast_15_mins_before_the_game_begin')}</span> 
                {/* {t('error_please_enter_roomID_password')} */}
              </p>
            ) : (
              ""
            )}
            {func("status picture", status)}
            {status === "In Progress" && scrimData?.picture?.length == 0 ? (
              <p class="small mb-4 pb-2">
                <span class="bodyBoldFont">{t('private_contest.note')}</span>
                {t('private_contest.please_add_the_final_screenshot_after_the_match_is_completed')}
               </p>
            ) : (
              ""
            )}
            {status === "In Review" ? (
              <p class="small mb-4 pb-2">
                <span class="bodyBoldFont">{t('private_contest.note')} </span>
                {t('private_contest.note_please_add_rank_for_each_palyer_that_joined')}             
              </p>
            ) : (
              ""
            )}

            <h4 class="text-primary mb-3 pb-1 bodyBoldFont">{t('private_contest.rules')}</h4>
            <div class="card rulesCard">
              <div className="card-body">
                {/* {ReactHtmlParser(scrimData?.rules)} */}
                <div dangerouslySetInnerHTML={{ __html: scrimData?.rules }} />
              </div>
            </div>
          </div>
        </div>
        <LeaderBoard
          leaderBoardData={leaderBoardData}
          setRankSubmitted={setRankSubmitted}
          status={status?.status}
          setIsSSUpload={scrimData?.picture?.length > 0}
          state={id}
          code={scrimData?.shortCode}
        />
      </div>
      {showRoomPwdModal && (
        <RoomIdPwdModal
          showRoomPwdModal={showRoomPwdModal}
          setShowRoomPwdModal={setShowRoomPwdModal}
          roomId={roomId}
          password={password}
          setRoomId={setRoomId}
          setPassword={setPassword}
          setRmIdpWdSubmit={setRmIdpWdSubmit}
          state={id}
        />
      )}
      {showUploadSSModal && (
        <UploadSSModal
          showUploadSSModal={showUploadSSModal}
          setShowUploadSSModal={setShowUploadSSModal}
          files={files}
          setFiles={setFiles}
          picture={scrimData?.picture}
          setIsSSUpload={setIsSSUpload}
          state={id}
        />
      )}
    </>
  );
};

export default ManagePrivateContest;
