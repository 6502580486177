import React from "react";
import RankCollegiate from "../../assets/images/rank-collegiate.png";
import PointCollegiate from "../../assets/images/point-collegiate.png";
import Collegeicon from "../../assets/images/collegeicon.png";
import { useTranslation } from "react-i18next";

const CollegeCard = ({ collegeDetails, sethowCollegeDetails }) => {
  const {t} = useTranslation()
  return (
    <div
      class="card collgiateInnerCard border border-1 rounded-2"
      style={{ minHeight: "18.75rem" }}
    >
      <div class="card-header">
        <div class="card-icon">
          <div class="icon-col mb-1">
            <img class="icon-50" src={Collegeicon} alt="collegeicon" />
          </div>
        </div>
      </div>
      <div class="card-body d-flex justify-content-center align-items-center flex-column py-5">
        <div class="">
          <h5 class="text-center bodyNormalFont h5">{collegeDetails?.name}</h5>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="d-flex flex-column h-100 align-items-center text-center py-1 px-2">
              <div class="icon mb-1">
                <img
                  class="icon-30"
                  src={RankCollegiate}
                  alt="rank-collegiate%201"
                />
              </div>
              <h6 class="fs-7 mb-1 bodyNormalFont">{collegeDetails?.rank}</h6>
              <h6 class="mb-0 bodyNormalFont d11-profile-level">{t('Profile.rank')}</h6>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex flex-column h-100 align-items-center text-center py-1 px-2">
              <div class="icon mb-1">
                <img
                  class="icon-30"
                  src={RankCollegiate}
                  alt="rank-collegiate%201"
                />{" "}
              </div>
              <h6 class="fs-7 mb-1 bodyNormalFont">
                {collegeDetails?.members}
              </h6>
              <h6 class=" mb-0 bodyNormalFont d11-profile-level">{t('Profile.members')}</h6>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex flex-column h-100 align-items-center text-center py-1 px-2">
              <div class="icon mb-1">
                <img
                  class="icon-30"
                  src={PointCollegiate}
                  alt="point-collegiate%201"
                />
              </div>
              <h6 class="fs-7 mb-1 bodyNormalFont">{collegeDetails?.points}</h6>
              <h6 class=" mb-0 bodyNormalFont d11-profile-level">{t('Profile.points')}</h6>
            </div>
          </div>
          {sethowCollegeDetails ? (
            <div class="col-12 mt-3">
              <div class="d-grid">
                <button
                  class="btn btn-primary btn-arrow"
                  onClick={() => sethowCollegeDetails(true)}
                >
                  {t('Profile.view_details')}
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default CollegeCard;
