import React, { useEffect } from "react";
import CointStoreInfo from "./CointStoreInfo";
import { useLocation } from "react-router";
import { func } from "../../../Utilities/logFunc";

const CoinStore = () => {
  //   const location = useLocation();
  //   const { active } = location?.state;
  //   func(active, "lead");
  useEffect(() => {
    func("called");
  }, []);

  return (
    <div class="content">

<div className="content-inner">
      <div class="row gy-4">
        <CointStoreInfo />
      </div>
      </div>
    </div>
  );
};

export default CoinStore;
