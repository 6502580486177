import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from './images/header-logo.svg'
import Cookies from "js-cookie";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../Utilities/controller";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
const LandingNavbar = ({active, setActive}) => {
   const navigate = useNavigate()
   const {t} = useTranslation()
   const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
     Cookies.get("meLangName") || localStorage.getItem("meLangName")  || "English"
  );
  const [loader, setLoader] = useState(false);

  const currentLang = Cookies.get("meLng");

  const fetchLanguage = async () => {
    const body = {
      companyId: process.env.REACT_APP_COMPANY_ID,
    };
    try {
      const res = await getLanguage(body);
      // console.log("fetchLanguage",res?.data?.data)
      setLanguages(res?.data?.data);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchLanguage();
    const linkTag = document.getElementById('bootstrap_css');
    document.documentElement.dir =
      currentLang?.toLowerCase() == "ar" ? "rtl" : "ltr";
    if (currentLang?.toLowerCase() == "ar") {
      document.documentElement.setAttribute('lang', 'ar');
      linkTag?.setAttribute(
        'href',
        `${process.env.REACT_APP_CLIENT_BASE_URL}assets/css/bootstrap.rtl.min.css`
      );
    }
         
    document.title = t("title");
  }, [currentLang]); 
  
  return (
    <nav class="navbar navbar-expand-lg fixed-top text-white" style={{color:"#fff",backgroundColor:'#000'}}>
    <div class="container d-flex landing-rtl">
       <Link class="navbar-brand" to={`/`}><img src={Logo} class="navbar-logo" alt="" /></Link>
       <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
       <span class="navbar-toggler-icon"></span>
       </button>
       <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo02">

       <div class="dropdown customDropdown headerDropDown ms-xl-5 ms-5">
                <span
                  class="dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedLanguage}
                </span>

                <ul class="dropdown-menu">
                  {languages?.map((ele,ind) => (
                    <li key={ind}>
                      <span
                        class="dropdown-item"
                        onClick={(e) => {
                          setLoader(true);
                          i18next.changeLanguage(ele.code.toLowerCase());
                          setSelectedLanguage(ele.name);
                          localStorage.setItem(
                            "meLng",
                            ele.code.toLowerCase()
                          );
                          localStorage.setItem(
                            "meLangName",
                            ele.name
                          );
                          Cookies.set("meLng", ele.code.toLowerCase())
                          Cookies.set("meLangName",ele.name)

                          eventTracking(events.CHANGE_LANGUAGE,{
                            MG_language: ele.name
                          });
                          // const head = document.head;
                          // const link = document.createElement("link");
                          // link.type = "text/css";
                          // link.rel = "stylesheet";

                          // // Set the href based on the language
                          // link.href =
                          //   currentLang === "ar"
                          //     ? `"%PUBLIC_URL%/assets/css/bootstrap.rtl.min.css`
                          //     : `"%PUBLIC_URL%/assets/css/bootstrap.min.css`;

                          // // Clear existing stylesheets, if any
                          // const existingLinks = head.querySelectorAll(
                          //   'link[data-type="bootstrap"]'
                          // );
                          // existingLinks.forEach((existingLink) =>
                          //   existingLink.remove()
                          // );

                          // // Add the new stylesheet link
                          // link.dataset.type = "bootstrap";
                          // head.appendChild(link);
                          // console.log(`"%PUBLIC_URL%/assets/css/bootstrap.min.css`, `${process.env.REACT_APP_CLIENT_BASE_URL}assets/css/bootstrap.rtl.min.css`)
                          window.location.href = window.location.pathname
                          setLoader(false);
                        }}
                      >
                        {ele.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
      
          <ul class="navbar-nav main-navbar-nav mb-lg-0 d-flex align-items-center">
          <li class="nav-item">
                <span class={`nav-link ${active == 5 ? "active": ""}`} aria-current="page"  style={{color:"#fff", cursor:'pointer'}} onClick={()=> {
                 
                  navigate('/')
               }}>{t('landing.home')}</span>
             </li>
             {/* <li class="nav-item">
                <span class={`nav-link ${active == 0 ? "active": ""}`} aria-current="page"  style={{color:"#fff", cursor:'pointer'}} onClick={()=> {
                 

                  navigate('/about')
               }}>About D11</span>
             </li>
             <li class="nav-item">
                <span class={`nav-link ${active == 1 ? "active": ""}`} style={{color:"#fff", cursor:'pointer'}} onClick={()=> {
                 
                  navigate('/contact')
               }}>Contact Us</span>
             </li> */}
             <li class="nav-item">
                <span class={`nav-link ${active == 2 ? "active": ""}`} style={{color:"#fff", cursor:'pointer'}} onClick={()=> {
                 
                  navigate('/login',{state:1})
               }}>{t('landing.signup')}</span>
             </li>
             <li class="nav-item">
                <span class={`nav-link ${active == 3 ? "active": ""}`} style={{color:"#fff", cursor:'pointer'}} onClick={()=> {
                 
                  navigate('/login',{state:0})
                    }}>{t('landing.login')}</span>
             </li>
             {/* <li class="nav-item">
                <span class="mx-4" style={{color:"#fff", cursor:'pointer'}}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21 21" fill="none">
                   <g clip-path="url(#clip0_3879_96991)">
                      <path d="M20.7031 17.8633L16.8086 13.9688C16.6328 13.793 16.3945 13.6953 16.1445 13.6953H15.5078C16.5859 12.3164 17.2266 10.582 17.2266 8.69531C17.2266 4.20703 13.5898 0.570312 9.10156 0.570312C4.61328 0.570312 0.976562 4.20703 0.976562 8.69531C0.976562 13.1836 4.61328 16.8203 9.10156 16.8203C10.9883 16.8203 12.7227 16.1797 14.1016 15.1016V15.7383C14.1016 15.9883 14.1992 16.2266 14.375 16.4023L18.2695 20.2969C18.6367 20.6641 19.2305 20.6641 19.5938 20.2969L20.6992 19.1914C21.0664 18.8242 21.0664 18.2305 20.7031 17.8633ZM9.10156 13.6953C6.33984 13.6953 4.10156 11.4609 4.10156 8.69531C4.10156 5.93359 6.33594 3.69531 9.10156 3.69531C11.8633 3.69531 14.1016 5.92969 14.1016 8.69531C14.1016 11.457 11.8672 13.6953 9.10156 13.6953Z" fill="white" fill-opacity="0.28"/>
                   </g>
                   <defs>
                      <clipPath id="clip0_3879_96991">
                         <rect width="20" height="20" fill="white" transform="translate(0.976562 0.570312)"/>
                      </clipPath>
                   </defs>
                </svg>
             </span>
             </li> */}
          </ul>
          
       </div>
    </div>
 </nav>
  )
}

export default LandingNavbar