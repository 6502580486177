import React, { createContext, useContext, useEffect, useState } from 'react'
import { getAccountProfile, getConstantProfile, getHeaderApi, isUserPremium } from '../../Utilities/controller';
import { func } from '../../Utilities/logFunc';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import { logout } from '../../Utilities/logout';
import { useNavigate } from 'react-router';
import { toastError } from '../../Utilities/toast';

const ProfileDataContext = createContext()

const ProfileDataProvider = ({children}) => {
    const [profileData, setProfileData] = useState()
    const [loading, setLoading] = useState(false)
    const [nonPremiumUser, setNonPremiumUser] = useState()
    const [liteProfile, setLiteProfile] = useState()
    const [premiumDetails, setPremiumDetails] = useState()
    const navigate = useNavigate()
    const fetchAccountProfile = async () => {
      try {
        const data = await getConstantProfile();
        if(data?.data){
          setLiteProfile(data?.data)
          // console.log(">>>>>>>>setLiteProfile>>>>>>>>>>",data?.data)
          localStorage.setItem("profile",JSON.stringify(data?.data))
        }
       
      } catch (error) {
       toastError(error?.response?.errors?.[0]?.msg)
      }
    };
    const getAccountProfileDetails = async () => {
        setLoading(true)
        try {
          const res = await getHeaderApi();
          // console.log("profile data---------------", res?.data)
          if (res?.data) {
            setProfileData(res?.data);
            
            // localStorage.setItem("profile", JSON.stringify(res?.data));
            // localStorage.setItem(
            //   "currentD11",
              
            // );
         
                // let userId = JSON.parse(localStorage.getItem('profile'))?.item?.user_id
                // const resProfile = await getHeaderApi();
                // func1("premium user********",profileData)
                const res1 = await isUserPremium(res?.data?.item?.user_id)
                // func1(JSON.parse(localStorage.getItem('profile'))?.item?.user_id)
              localStorage.setItem("premiumUser",res1?.data?.data?.[0]?.param?.isPremium)
              localStorage.setItem("paymentMode",res1?.data?.data?.[0]?.param?.paymentMode)
              localStorage.setItem("isUserSubscribed",res1?.data?.data?.[0]?.param?.isUserSubscribed)
              localStorage.setItem("planName",res1?.data?.data?.[0]?.param?.lastPlanDetails?.tangerinePlanId || "Free")
              setPremiumDetails(res1?.data?.data?.[0]?.param)
              localStorage.setItem("subscriptionDetails", JSON.stringify(res1?.data?.data?.[0]?.param?.userInfo?.subscription) || {})
              // console.log(res1?.data?.data?.[0]?.param?.nonPremiumUser)
              setNonPremiumUser(res1?.data?.data?.[0]?.param?.nonPremiumUser)
              // localStorage.setItem("premiumUser",true)
            
              fetchAccountProfile()
              if (res?.data?.item?.isDetailsFilled === false) {
                // window.location.href = '/avatar';
                navigate('/avatar')
              }
            }
          
         
            setLoading(false)
         
        } catch (error) {
          // console.log(error?.config)
          toastError(error?.response?.data?.errors?.[0]?.msg)
      if (error?.response?.data?.errors?.[0]?.code == "auth.validation.signin.account_inactive" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired" )
      {
        logout(); 
        return;
      }
          func("profile error 42", error);
          setLoading(false)
        }
      };
    
      
      const updateProfileData = (newData)=>{
        setProfileData(newData)
      }
      useEffect(()=>{
        getAccountProfileDetails()
   

      },[])
      if(loading) return <Loader />
  return (
    <ProfileDataContext.Provider value={{profileData, updateProfileData, nonPremiumUser,fetchAccountProfile , liteProfile , premiumDetails}}>
        {children}
    </ProfileDataContext.Provider>
  )
}

export default ProfileDataProvider
export const useProfileDataContext = ()=>{
  
    return useContext(ProfileDataContext)
}