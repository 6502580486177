import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import CoinPack from "./CoinPack";
import Bundle from "./Bundle";
import Avtar from "./Avtar";
import EarnCoin from "./EarnCoin";
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";

const CointStoreInfo = () => {
  let [type, setType] = useState("terms-of-use");
  const {t} = useTranslation()
  const loadMoreBtnClick = (type) => {
    setType(type);
  };
  useEffect(()=>{
    EventTracking(); 
  },[]);
  const EventTracking=()=>{
    eventTracking(events.MENU.DIAMOND_STORE);
  }
  return (
    // <div class="content">
    <div class="col-12 commonTab earnCoinTypeTab" style={{backgroundColor:"black"}}>
      <ul class="nav nav-tabs row" id="earnCoinTypeTab" role="tablist">
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link active"
            id="packs-tab"
            data-bs-toggle="tab"
            data-bs-target="#packs-tab-pane"
            type="button"
            role="tab"
            aria-controls="solo-tab-pane"
            aria-selected="true"
          >
            {t('coin_store.packs')}
          </button>
        </li>
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link"
            id="bundles-tab"
            data-bs-toggle="tab"
            data-bs-target="#bundles-tab-pane"
            type="button"
            role="tab"
            aria-controls="duo-tab-pane"
            aria-selected="false"
          >
            {t('coin_store.bundles')}
          </button>
        </li>
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link"
            id="Avatars-tab"
            data-bs-toggle="tab"
            data-bs-target="#avatars-tab-pane"
            type="button"
            role="tab"
            aria-controls="squod-tab-pane"
            aria-selected="false"
          >
            {t('coin_store.avatars')}
          </button>
        </li>
        {/* <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link"
            id="earncoin-tab"
            data-bs-toggle="tab"
            data-bs-target="#earncoin-tab-pane"
            type="button"
            role="tab"
            aria-controls="earncoin-tab-pane"
            aria-selected="false"
          >
            {t('coin_store.earn_coins')}
          </button>
        </li> */}
      </ul>
      <div class="tab-content" id="earncoinTypeTabContent">
        <div
          class="tab-pane fade show active"
          id="packs-tab-pane"
          role="tabpanel"
          aria-labelledby="packs-tab"
          tabindex="0"
        >
          <CoinPack />
        </div>
        <div
          class="tab-pane fade"
          id="bundles-tab-pane"
          role="tabpanel"
          aria-labelledby="bundles-tab"
          tabindex="0"
        >
          <div class="row gx-3 gy-5">
            <Bundle />
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="avatars-tab-pane"
          role="tabpanel"
          aria-labelledby="avatars-tab"
          tabindex="0"
        >
          <div class="row gx-3 gy-5">
            <Avtar />
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="earncoin-tab-pane"
          role="tabpanel"
          aria-labelledby="earncoin-tab"
          tabindex="0"
        >
          <div class="row gx-3 gy-5">
            {/* <EarnCoin /> */}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default CointStoreInfo;
