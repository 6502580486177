import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import { func } from "../../Utilities/logFunc";

const BaseAuth = ({ children }) => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const handleClose = () => {
    let pathName = window.location.pathname;
    if (pathName === "/otp") navigate("/login");
    else {
      navigate("/");
    }
  };

  const handleShow = () => setShow(true);
  return (
    <>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="auth-modal modal fade "
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BaseAuth;
