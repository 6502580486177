import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { url } from "../../../Utilities/url";
import DatePicker from "../../Common/ProfileDatePicker";
import moment from "moment";
import { Formik, Form, Field } from "formik";
import {
  addUserInGameName,
  updateDobState,
} from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";
import { logout } from "../../../Utilities/logout";
import { useProfileDataContext } from "../ProfileContext";
import Error from "../../Common/Error";
import { useTranslation } from "react-i18next";
import { errors } from "../../../Utilities/error";
import { toastError } from "../../../Utilities/toast";

const BasicProfile = ({
  showBasicProfileModal,
  setShowBasicProfileModal,
  game,
  setShowContestConfirmationModal,
  setUserInGameName,
}) => {
  //  var user_data = JSON.parse(localStorage.getItem("profile"))?.item;
   const { profileData, updateProfileData, liteProfile } = useProfileDataContext()
  const [errorName, setErrorName] = useState(false)
  const {t} = useTranslation()
  const [dobError, setDobError] = useState(false)

  const [show, setShow] = useState(showBasicProfileModal);
  const [loading, setLoading] = useState(false)

  // let d = new Date()
  // let da = d.setFullYear(d.getFullYear() - 18)
  const [name, setName] = useState('')
  const [date, setDate] = useState()
  const handleClose = () => {
    setShowBasicProfileModal((prev) => false);
    setShow(false);
  };

  const [frmInitialValues, setFrmInitialValues] = useState({
    dateOfBirth: "",
    name: "",
  });

  const applyPromoCodeSchema = Yup.object().shape({
    dateOfBirth: Yup.date().required(t('date_of_birth_req')),
    name: Yup.string().required(t('in_name_game_is_required')),
  });

  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 18);

  const addInNameGameData = async (name, date) => {
    if(name.length <= 0){
      setErrorName(true)
      return
    }
    try {
      setLoading(true);
      let data = {
        user: profileData?.item?.user_id,
        game: localStorage.getItem("activeGameId"),
        userINGameName: name,
      };

      const res = await addUserInGameName(data);
      if (res?.data?.success) {
        localStorage.setItem("activeInNameGame", name);
        if(!JSON.parse(localStorage.getItem('profile'))?.item?.dateOfBirth)
          await addDOB(name, date);
          setLoading(false)
          setShowBasicProfileModal(false)
          setShowContestConfirmationModal(true)
      } else {
        setLoading(false);
        toastError(res?.data?.errors?.[0]?.msg);
      }
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("getTournament error", error?.response?.data?.errors?.[0]?.msg);
      toastError(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const addDOB = async (name, date) => {
    if(!date){
      setDobError(errors.dateOfBirth)
      return
    }
    try {
      let data = {
        //  gamerjiName: values?.name,
        dateOfBirth: moment(date),
        gameNames: [
          {
            game: localStorage.getItem("activeGameId"),
            uniqueIGN: name,
          },
        ],
      };

      const res = await updateDobState(data);

      if (res?.data?.success) {
        setUserInGameName(name)
        setShowContestConfirmationModal(true);
        setShowBasicProfileModal((prev) => false);
        setShow(false);
      } else {
        toastError(res?.data?.errors?.[0]?.msg);
      }
    } catch (error) {
      func("getTournament error", error?.response?.data?.errors?.[0]?.msg);
      if (
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
      )
        {logout();return;}
      toastError(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        {/* <Formik
          enableReinitialize={true}
          initialValues={frmInitialValues}
          validationSchema={applyPromoCodeSchema}
        >
          {({ errors, values }) => ( */}
            <div className="row justify-content-center">
              <div
                class="col-12 justify-content-center align-items-center"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3 class="bodyBoldFont mb-5 text-center">{t('basic_profile.basic_profile')}</h3>
                {
                  !JSON.parse(localStorage.getItem('profile'))?.item?.dateOfBirth ? 
                  <div className="col-md-6 col-12 mb-3">
                  <label for="dateOfBirth" className="form-label">
                  {t('basic_profile.dob')}
                  </label>
                  {/* <Field
                    type="date"
                    className="form-control"
                    id="dateOfBirth"
                    placeholder="What's your date of birth ?"
                    name="dateOfBirth"
                    max={moment(minDate).format("YYYY-MM-DD")}
                  /> */}
                   <DatePicker 
                                            name="dateOfBirth"
                                            value={date} 
                                            handlerChange={(e)=> {
                                                setDate(e.format())
                                            }} />
               {dobError && <Error error={"enter your date of birth"}/>}
                </div> :""
                }
               

                <div className="col-md-6 col-12 mb-5">
                  <label for="name" className="form-label">
                  {t('basic_profile.game_name',{game:localStorage.getItem("activeGame")})}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder={t('basic_profile.game_name',{game:localStorage.getItem("activeGame")})}
                    name="name"
                    onChange={(e)=> setName(e.target.value)}

                  />
                  {errorName && <Error error={t('enter_ingame_name')}/>}
                </div>

                <div className="col-md-6 col-12">
                  <button
                    className="btn btn-primary btn-arrow"
                    style={{ width: "100%" }}
                    type="submit"
                    onClick={() => {
                      addInNameGameData(name, date);
                    }}
                  >
                     {t('crm.submit')}
                  </button>
                </div>
              </div>
            </div>
          {/* )}
        </Formik> */}
      </Modal.Body>
    </Modal>
  );
};

export default BasicProfile;
