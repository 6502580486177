import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { gamerjiTournamentGet } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import TeamRegistration from "../../Common/TeamRegistration";

const TeamRegisterModal = ({
  showTeamRegisterModal,
  setShowTeamRegisterModal,
  postData,
  type,
  noOfPlayer,
  setShowTournamentConfirmationModal,
  game,
  setShowPremiumPopupTournament,
  userInGameName,
}) => {
  const [show, setShow] = useState(showTeamRegisterModal);
  const handleClose = () => {
    setShowTeamRegisterModal((prev) => false);
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="tournamentModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <TeamRegistration
          postData={postData}
          type={type}
          noOfPlayer={noOfPlayer}
          setShowTeamRegisterModal={setShowTeamRegisterModal}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
          game={game}
          setShowPremiumPopupTournament={setShowPremiumPopupTournament}
          userInGameName={userInGameName}
        />
      </Modal.Body>
    </Modal>
  );
};

export default TeamRegisterModal;
