import React, { useEffect, useReducer, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { Outlet } from 'react-router'
import { func } from '../../Utilities/logFunc'
import { isUserPremium } from '../../Utilities/controller'

import { toast } from 'react-toastify'
import ProfileDataProvider from './ProfileContext'
 

const HomePage = () => {
const [activeNav, setActiveNav]= useState('')
// localStorage.setItem("premiumUser",true)
const [isValid, setIsValid] = useState(false)
const [isActiveMenu, seActiveMenu] = useState(false)
useEffect(()=>{
  // console.log("home page")
  setIsValid(true)

},[isValid])
useEffect(()=>{
  // console.log("clicked*************")
  const handleOutsideClick = (event) => {
    const toastContainer = document.querySelector('.Toastify__toast-container');
    // console.log(toastContainer, toastContainer , toastContainer.contains(event.target))
    if (toastContainer && toastContainer.contains(event.target)) {
      // Click occurred outside the toast container, close the toast
      toast.dismiss();
    }
  };

  document.addEventListener('click', handleOutsideClick);

  return () => {
    document.removeEventListener('click', handleOutsideClick);
  };
 
},[])

  return (
    <ProfileDataProvider>
    <main class="dashboardMain">
      <Sidebar setActiveNav={setActiveNav} seActiveMenu={seActiveMenu} isActiveMenu={isActiveMenu} />

      <Navbar  activeNav={activeNav} seActiveMenu={seActiveMenu} isActiveMenu={isActiveMenu} />
      {
        isValid &&  <Outlet />
      }
     
    </main>
    </ProfileDataProvider>
  )
}

export default HomePage