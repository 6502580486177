import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { routes } from "../../../Utilities/routesFb";
import { Link, useNavigate } from "react-router-dom";
import { func } from "../../../Utilities/logFunc";
import moment from "moment";
import { getUserInGameName, joinScrim } from "../../../Utilities/controller";
import TeamRegisterScrim from "../PrivateContest/TeamRegisterScrim";
import { toast } from "react-toastify";
import BasicProfile from "../Tournament/BasicProfile";
import Loader from "../../Common/Loader";
import { useTranslation } from "react-i18next";
import { useProfileDataContext } from "../ProfileContext";
import { logout } from "../../../Utilities/logout";

const JoinScrim = ({
  showJoinScrimModal,
  setShowJoinScrimModal,
  item,
  setShowTeamRegisterScrimModal,
  setShowJVC,
}) => {
  const [show, setShow] = useState(showJoinScrimModal);
  const [captainData, setCaptainData] = useState({});
  const [basicProfile, setBasicProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation()
  const { profileData, updateProfileData, liteProfile } = useProfileDataContext()
  const handlerSubmit = async (e) => {
    func("submit habdler 18");
    e.preventDefault();
    if (item?.isJoined) {
      if (setShowJVC) setShowJVC(false);
      setShowJoinScrimModal(false);
      setShow(false);

      navigate("/home/scrim-details/" + item?._id);
    } else {
      try {
        
        let payload = {
          search: {
            user: profileData?.item?.user_id,
          },
        };
        setLoading(true);
        const res = await getUserInGameName(payload);
        // const game = localStorage.getItem()
        func("response", res);
        if (res?.data?.success) {
          // func(
          //   JSON.parse(localStorage.getItem("userInGameNames"))?.data,
          //   item?.game?._id
          // );
          let gameInName = res?.data?.data.filter(
            (ele) => ele?.game === item?.game?._id
          );
          func("ingamename", gameInName);
          setLoading(false);

          let payload = {
            scrimId: item?._id,
            userId: profileData?.item?.user_id,
            usersInGameName: gameInName[0]?.userINGameName,
            gamerJiName: gameInName[0]?.gamerjiName,
            mobileNo: liteProfile?.item?.phone
              ? liteProfile?.item?.phone
              : liteProfile?.item?.email,
          };
          func("item", item);

          if (item?.gameType?.players >=2 ) {
            func("item", item?.gameType?.isExtraPlayers);
            setShowTeamRegisterScrimModal(true);
            setShowJoinScrimModal(false);
            setShow(false);
          } else {
            const res = await joinScrim(payload);
            if (res) {
              setShowJoinScrimModal(false);
              setShow(false);
              if (setShowJVC) setShowJVC(false);
              navigate("/home/scrim-details/" + item?._id);
            }
          }
        } else {
          setLoading(false);
          setBasicProfile(true);
        }
      } catch (error) {
        // setShowJoinScrimModal(false)
        // setShow(false)
        setLoading(false);
        if (
          error?.response?.data?.errors?.[0]?.code ===
            "middlewares.token_parser.validation.token_invalid" ||
          error?.response?.data?.errors?.[0]?.code ===
            "middlewares.token_parser.validation.token_expired"
        ){
          logout();
          return;
        }
        console.log(error?.response.data?.errors[0]?.msg)
        // func("error 53", error?.response?.data?.errors?.[0]?.msg);
        // toastError(error?.response.data?.errors[0]?.msg)
        setBasicProfile(true);
      }
    }
  };

  // const getInNameGame = async () => {
  //   setLoading(true)
  //   try {
  //     let payload = {
  //       search: {
  //         user: JSON.parse(localStorage.getItem("userData"))?.user_id,
  //       },
  //     };
  //     const res = await getUserInGameName(payload)
  //     // const game = localStorage.getItem()
  //     func("response", res)
  //     if(res?.data?.success){
  //     func(JSON.parse(localStorage.getItem("userInGameNames"))?.data, item?.game?._id)
  //     let gameInName = res?.data?.data.filter((ele) => ele?.game === item?.game?._id)
  //     setCaptainData(gameInName[0])
  //     func("ingamename", gameInName)
  //     setLoading(false)
  //     return gameInName[0]
  //     }

  //   }
  //   catch (error) {
  //     func("error 73", error)
  //     setLoading(false)
  //     setBasicProfile(true)
  //     // toastError(error?.response.data?.errors[0]?.msg)
  //   }
  // }

  const handleClose = () => {
    setShowJoinScrimModal((prev) => false);
    setShow(false);
    console.warn(setShowJVC);
    if (setShowJVC) setShowJVC(false);
  };
  const findPercentage = (data) => {
    let totalPlayer = data?.noOfPlayerCapacity || 0;
    let totalPlayerJoined = data?.noOfJoinedPlayer || 0;

    let percentage = (totalPlayerJoined / totalPlayer) * 100;

    return percentage + "%";
  };
  useEffect(() => {
    localStorage.setItem("activeGame", item?.game?.name);
    localStorage.setItem("activeGameId", item?.game?._id);
  }, []);
  if (loading) return <Loader />;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="winningBreakupModal modal fade"
        centered
      >
        <Modal.Body>
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
            aria-label="Close"
          ></button>

          <div class="card tournametParticipationCard tournamentSoloCard contestTournamentSoloCard mt-5">
            <div class="card-header">
              <h4 class="card-title mb-0">{item?.title}</h4>
              {/* <a href="#" class="rulesLink fs-5">ID: 2HWEJV</a> */}
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="card-content">
                    <div class="row justify-content-between py-2">
                      <div class="col-3">
                        <label for="" class="d-block h6">
                          {t('contest.date')}
                        </label>
                        <h6 class="bodyBoldFont mb-0">
                          {/* 12/09/20223 */}
                          {moment(item?.date).format("DD/MM/YYYY")}
                        </h6>
                      </div>
                      <div class="col-3 dividersX">
                        <label for="" class="d-block h6">
                        {t('contest.time')}
                        </label>
                        <h6 class="bodyBoldFont mb-0">
                          {moment(item?.time).format("hh:mm A")}
                        </h6>
                      </div>
                      <div
                        class={`col-3 ${item?.title1 ? "dividersRight" : ""}`}
                      >
                        <label for="" class="d-block h6">
                          {item?.title1?.name}
                        </label>
                        <h6 class="bodyBoldFont mb-0">{item?.title1?.value}</h6>
                      </div>
                      {item?.title2 && (
                        <div class="col-3">
                          <label for="" class="d-block h6">
                            {item?.title2?.name}
                          </label>
                          <h6 class="bodyBoldFont mb-0">
                            {item?.title2?.value}
                          </h6>
                        </div>
                      )}
                    </div>
                    <div class="row justify-content-between py-2 mt-1">
                      <div class="col-4">
                        <label for="" class="d-block h6">
                        {t('contest_details.players')}
                        </label>
                        <div class="d-flex align-items-center poolPrizeBox">
                          <h6 class="bodyBoldFont mb-0">
                            {item?.noOfPlayerCapacity || 0}
                          </h6>
                        </div>
                      </div>
                      <div class="col-4 dividersX d-flex">
                        <div class="">
                          <label for="" class="d-block h6">
                          {t('contest.winners')}
                          </label>
                          <div class="d-flex align-items-center poolPrizeBox">
                            <h6 class="bodyBoldFont mb-0">
                              {item?.noOfWinner || 0}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 d-flex">
                        <div class="">
                          <label for="" class="d-block h6">
                          {t('contest.id')}
                          </label>
                          <div class="d-flex align-items-center useCoinBox">
                            <h6 class="bodyBoldFont mb-0">{item?.shortCode}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <div
                          class="progress"
                          role="progressbar"
                          aria-label="Basic example"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <div
                            class="progress-bar bg-info"
                            style={{
                              width: findPercentage(item),
                            }}
                          ></div>
                        </div>
                        <div class="d-flex justify-content-between mt-2">
                          <h6>
                          {t('contest_details.players_remaining',{players:item?.noOfPlayerCapacity - item?.noOfJoinedPlayer || 0})}
                          </h6>
                          <h6>
                          {t('contest_details.players_joined',{players:item?.noOfJoinedPlayer || 0})}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4">
              <div class=" text-white col-12 bg-secondary-subtle mt-4 d-flex">
                <div
                  class="col-6 dividersRight"
                  style={{
                    height: "46px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p class="mb-1">{t('private_contest.hosted_by')}</p>
                  <p class="mb-0">{item?.hostedBy}</p>
                </div>
                {item?.noOfPlayerCapacity - item?.noOfJoinedPlayer > 0 
                ?
                <div class="col-6">
                  <button
                    className="btn btn-primary"
                    style={{
                      height: "46px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0",
                    }}
                    onClick={(e) => handlerSubmit(e)}
                  >
                    {item?.isJoined ? t('tournaments.joined') : t('tournaments.join_now')}
                  </button>
                </div>  
              :
              <div class="col-6">
            { item?.isJoined?  <button
                className="btn btn-primary"
                style={{
                  height: "46px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0",
                }}
                onClick={(e) => handlerSubmit(e)}
              >
              { t('tournaments.joined')}
              </button>
              :
              <button
              className="btn btn-primary disabled"
              style={{
                height: "46px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0",
              }}
            >
              {t('tournaments.full')}
            </button>
              }
            </div>
              }
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {basicProfile && (
        <BasicProfile
          showBasicProfileModal={basicProfile}
          setShowBasicProfileModal={setBasicProfile}
          game={localStorage.getItem("activeGameId")}
          // setShowContestConfirmationModal={setShowContestConfirmationModal}
        />
      )}
    </>
  );
};

export default JoinScrim;
