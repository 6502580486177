import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import { func } from "../../../Utilities/logFunc";
import { getTransactionList } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import Loader from "../../Common/Loader";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { logout } from "../../../Utilities/logout";

const CoinTransaction = ({ type }) => {
  let [dataCoin, setLeaderBoardData] = useState([]);
  let [limit, setLimit] = useState(10);
  let [count, setCount] = useState(1);
  let [search, setSearch] = useState("");
  let [totalRecord, setTotalRecord] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const fetchLeaderBoardData = async () => {
    try {
      if (type === "coin") {
        setLoading(true);
        let data = {
          currencyType: "coin",
          skip: dataCoin.length,
          limit: 10,
        };

        const res = await getTransactionList(data);
        if (res?.data?.list) {
          setLeaderBoardData([...dataCoin, ...res?.data?.list]);
          setTotalRecord(res?.data?.count);
          setLoading(false);
        }
        func(res);
      }
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
    }
  };

  useEffect(() => {
    if (!dataCoin.length > 0) {
      fetchLeaderBoardData(count);
    }
  }, [type]);

  const loadMoreBtnClick = () => {
    // let totalCount = parseInt(count) + 1;
    // setCount(totalCount);
    fetchLeaderBoardData();
  };

  useEffect(() => {
    fetchLeaderBoardData(count);
  }, [search]);

  if (isLoading) return <Loader />;

  return (
    <div class="row date-container">
      <form class="col-xl-12 col-lg-12">
        <div
          class="accordion transactionaccordion d-grid"
          id="accordionPanelsStayOpenExample"
        >
          {dataCoin.length > 0 ? (
            dataCoin.map((value, i) => {
              let previousItem = i != 0 && dataCoin[i - 1];
              let currentDate = moment(value.createdAt).format("Do MMMM YYYY");
              let previousDate = previousItem
                ? moment(previousItem.createdAt).format("Do MMMM YYYY")
                : "";

              return (
                <>
                  {previousItem && currentDate != previousDate && (
                    <h6 class="text-center text-white mt-3">{currentDate}</h6>
                  )}
                  {i == 0 && (
                    <h6 class="text-center text-white mt-3">{currentDate}</h6>
                  )}

                  <div class="accordion-item transactionaccordion-item" style={{borderRadius:"100px"}}>
                    <h5 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#panelsStayOpen-collapseOne${i}`}
                        aria-expanded="true"
                        aria-controls={`panelsStayOpen-collapseOne${i}`}
                      >
                        <h6 class="mb-0 h5 text-white d-flex justify-content-center align-items-center">
                          <span
                            className="d-flex align-items-center"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="/assets/images/transaction.png"
                              class="me-2 icon-24"
                              alt="transaction"
                            />
                            <span className="mt-1">
                              {String(value?.transactionType) === "1" &&
                                value?.amount != 0 &&
                                "+"}
                              {String(value?.transactionType) === "2" &&
                                value?.amount != 0 &&
                                "-"}
                              {"  "}
                              {value?.paymentTypeId === 2 && value?.amount === 0
                                ? " Free"
                                : value?.amount}
                            </span>
                            <span class="mx-2">
                              <img
                                className="icon-24"
                                src={
                                  url?.imageUrl + value?.currency?.img?.default
                                }
                                alt="transaction"
                              />
                            </span>
                          </span>{" "}
                          <span class="">{value?.paymentType}</span>
                        </h6>
                      </button>
                    </h5>
                    <div
                      id={`panelsStayOpen-collapseOne${i}`}
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body text-white">
                        <div
                          class="mt-4 acc-body"
                          // style={{ marginTop: "2.5rem!important" }}
                        >
                          <h5 class="bodyBoldFont h5">
                            <span>{t("coin_store.transaction_id")} : </span>
                            {value?.transactionUniqueID}
                          </h5>
                          <h5 class="bodyBoldFont h5">
                            <span>{t("coin_store.transaction_date")} : </span>
                            {moment(value?.createdAt).format(
                              "DD MMMM YYYY hh:mm A"
                            )}
                          </h5>
                          {value?.contestDetails?.game && (
                            <h5 class="bodyBoldFont h5">
                              <span>{t("coin_store.game")} : </span>
                              {value?.contestDetails?.game?.name} -{" "}
                              {value?.contestDetails?.gameType?.name}
                            </h5>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <p className="text-center">{t("notFound.no_transaction_found")}</p>
          )}

          {dataCoin.length < totalRecord && (
            <div class="card-footer d-flex justify-content-center mt-5">
              <div
                class="btn btn-primary text-center"
                onClick={() => loadMoreBtnClick()}
              >
                {t("contest.load_more")}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default CoinTransaction;
