import React, { useEffect } from "react";
import ContestDetailsInfo from "./ContestDetailsInfo";
import ContestPlayers from "./ContestPlayers";
import { useParams } from "react-router";

const ContestDetails = () => {
  const params = useParams();
  const contestId = params?.id
    useEffect(()=>{
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[])
  return (
    <div class="content">
      <div className="content-inner">

      <div class="row gy-4">
        <ContestDetailsInfo contestId={contestId}/>
        <ContestPlayers contestId={contestId}/>
      </div>
      </div>
    </div>
  );
};

export default ContestDetails;
