import React, { useEffect } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { routes } from './Utilities/routesFb';
import { func } from 'prop-types';

const Protected = ({Component}) => {
    const navigate = useNavigate()
    useEffect(()=>{
        const isLoggedIn = localStorage.getItem("token");
       console.log(isLoggedIn,)
        if(!isLoggedIn){
            navigate("/"+routes.login)
        }
    },[])
  return (
    <>
    <Component />
  
    </>
  )
}

export default Protected