import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { routes } from "../../Utilities/routesFb";

import { func } from "../../Utilities/logFunc";
import { getHtml5Settings, getGameList } from "../../Utilities/controller";
import { url } from "../../Utilities/url";
import Loader from "../Common/Loader";
import GamePlayed from "../../assets/images/gamesPlayed-icon-svg.svg";
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import {
  convertNumberToMillions,
  kFormatter,
} from "../../Utilities/kFormatter";
import { useTranslation } from "react-i18next";

import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents"; 


const AllGames = ({ handleClickGame }) => {
  
  const {t} = useTranslation()
  const [freeGames, setFreeGames] = useState([]);
  const [allGames, setAllGames] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  useEffect(()=>{
    EventTracking(); 
  },[]);
  const EventTracking=()=>{
    eventTracking(events.MENU.All_GAMES);
  }

  
  const getHtml5SettingsList = async () => {
    func("called", "sfbdhget5html");
    let payload = { sortby: "order", sort: "asc" };
    try {
      const res = await getHtml5Settings(payload);
      if (res?.data?.list) {
        setFreeGames(res?.data);
      }
      func("freegames", res);
    } catch (error) {
      setLoader(false);
      if (
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
      )
        logout();
      //toastError(error?.response?.data?.errors?.[0]?.msg);
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const getAllGameList = async () => {
    try {
      let data = {
        pagination: {
          pageNo: 1,
          recordPerPage: 100,
          sortBy: "order",
          sortDirection: "asc",
        },
      };
      const res = await getGameList(data);
      if (res?.data?.data) {
        setAllGames(res?.data?.data);
      }
      func("gamelist", res);
    } catch (error) {
      setLoader(false);
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    setLoader(true);
    getHtml5SettingsList();
    getAllGameList();
    setLoader(false);
  }, []);

  const clickFreeHandler = async (name) => {
    navigate("/" + routes.home + "/" + routes.freeGames);
    // handleClickGame(name)
    // navigate("/" + routes.home +'/' +routes.gameTournament)
  };

  const clickHandler = (name, id, bannerImage, tournamentId) => {
    // handleClickGame(name, id);
    localStorage.setItem("activeGame", name);
    localStorage.setItem("activeGameId", id);
    localStorage.setItem("tournamentIdGame", tournamentId);

    localStorage.setItem("activeGameBannerImage", bannerImage?.default);
    navigate("/" + routes.home + "/" + routes.gameTournament);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div class="col-12">
          <div class="title mb-3">
            <h2 class="h3 d-flex align-items-center">
              <span class="titleIcon me-3">
                <img src="assets/images/game-icon-svg.svg" alt="" />
              </span>
              <span>{t('home.all_games')}</span>
            </h2>
          </div>
          <div class="row g-20 allGames-row">
            {freeGames?.list?.length > 0 &&
              freeGames?.list?.map((data, indp) => (
                <div class="col-xxl-five col-lg-3 col-md-4 col-6" key={indp}>
                  <div
                    class="card gameCard"
                    style={{ height: "100%" }}
                    onClick={() => clickFreeHandler(data?.title)}
                  >
                    <div class="card-body" style={{ paddingTop: "1rem" }}>
                      <div class="card-title text-center">
                        {func("lengh", data?.title?.length > 5)}

                        <h4 class="bodyExtraBoldFont mb-2">{data?.title}</h4>
                      </div>
                      <div class="card-img">
                        <img
                          class="w-100"
                          src={url.imageUrl + data?.featuredImage?.default}
                          alt={data?.title}
                        />
                      </div>
                      <div class="card-content">
                        <div class="d-flex align-items-center">
                          <span class="me-2 card-icon">
                            <img src={GamePlayed} alt="" />
                          </span>
                          <h4
                            class="mb-0"
                            style={{
                              display: "block",
                              // width: "150px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {kFormatter(freeGames?.totalJoinedPlayers)} {t('home.played')}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {allGames.length > 0 &&
              allGames.map((data, indp) => (
                <div class="col-xxl-five col-lg-3 col-md-4 col-6" key={indp}>
                  <div
                    class="card gameCard"
                    style={{ height: "100%" }}
                    onClick={() =>
                      clickHandler(
                        data?.name,
                        data?._id,
                        data?.bannerImage,
                        data?.tournamentGameID
                      )
                    }
                  >
                    <div class="card-body" style={{ paddingTop: "1rem" }}>
                      <div class="card-title text-center">
                        <h4
                          class="bodyExtraBoldFont mb-2"
                          style={{marginBottom:'1rem',display:'block',width:'100%',whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
                        >
                          {
                            // data?.name?.length > 17 ? <marquee>{data?.name}</marquee>
                            // :
                            data?.name
                          }
                        </h4>
                      </div>
                      <div class="card-img cursorPointer">
                        <img
                          class="w-100 rounded-3"
                          src={url.imageUrl + data?.featuredImage?.default}
                          alt={data?.name}
                        />
                      </div>
                      <div class="card-content">
                        <div class="d-flex align-items-center">
                          <span class="me-2 card-icon">
                            <img src={GamePlayed} alt="" />
                          </span>
                          <h4
                            class="mb-0"
                            style={{
                              display: "block",
                              width: "10rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {convertNumberToMillions(data?.playedCount)} {t('home.played')}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default AllGames;
