import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import { getGamerjiTournamentList } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { url } from "../../../Utilities/url";
import RulesModal from "../Tournament/RulesModal";
import WinningBreakUp from "../Tournament/WinningBreakUp";
import DownArrow from "../../../assets/images/downArrow-svg.svg";
import moment from "moment";
import CopyIcon from "../../../assets/images/copy-icon-svg.svg";
import { errors } from "../../../Utilities/error";
import { toast } from "react-toastify";
import Loader from "../../Common/Loader";
import NoFound from "../../../assets/images/noContestFound.png";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import { logout } from "../../../Utilities/logout";
import { toastInfo } from "../../../Utilities/toast";

const TournamentList = ({}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [contestList, setContestList] = useState([]);
  const gameName = localStorage.getItem("activeGame");
  const gameId = localStorage.getItem("activeGameId");
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [showWinningModal, setShowWinningModal] = useState(false);
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { profileData, updateProfileData } = useProfileDataContext();
  const [totalRecords, setTotalRecords] = useState(0);
  const getContestList = async (pageNo) => {
    setLoading(true);
    try {
      let userData = localStorage.getItem("userData");
      userData = JSON.parse(userData);
      // console.log(profileData)
      let data = {
        game: gameId,
        limit: 9,
        sortBy: "startDate",
        sort: "desc",
        user: profileData?.item?.user_id,
        skip: pageNo
      };

      const res = await getGamerjiTournamentList(data);

      if (res?.data?.list) {
        setContestList([...contestList,...res?.data?.list]);
        // console.log([...contestList,...res?.data?.list])
        console.log(res?.data?.count)
        setTotalPage(res?.data?.count);
      }
      func(res);
        setLoading(false);

    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  useEffect(() => {
    getContestList(0);
  }, []);

  const loadMoreBtnClick = () => {
    let totalCount = ((parseInt(page) + 1) *9) -1;
    setPage(totalCount);
    getContestList(totalCount);
  };

  const findPercentage = (data) => {
    let totalPlayer = data?.totalPlayers || 0;
    let totalPlayerJoined = data?.totalJoinedPlayers || 0;

    let percentage = (totalPlayerJoined / totalPlayer) * 100;

    return percentage + "%";
  };

  const handlerWinning = (data) => {
    setSelectedTournament(data);
    setShowWinningModal((prev) => true);
  };

  const handlerRules = (data) => {
    setSelectedTournament(data);
    setShowRulesModal((prev) => true);
  };

  const handleClickContestCard = (item) => {
    navigate("/home/tournament-details/" + item?._id);
  };

  return  (
    <>
      {
        isLoading ? <Loader /> :
      
     
      contestList?.length > 0 ? (
        <div class="row gx-3 gy-5">

          {contestList?.map((item, ind) => (
            <div class="col-lg-6" key={ind}>
              <div class="card tournametParticipationCard mb-5  ">
                <div class="card-header">
                  <div class="card-status">{item?.status}</div>
                  <h4 class="card-title mb-0">{item?.title}</h4>
                  <span
                    className="rulesLink"
                    onClick={() => {
                      handlerRules(item);
                    }}
                  >
                    {t("tournaments.view_rules")}
                  </span>
                </div>
                <div
                  class="card-body"
                  onClick={() => handleClickContestCard(item)}
                >
                  <div class="row">
                    <div class="col-4">
                      <div class="card-img">
                        <img
                          src={url?.imageUrl + item?.featuredImage?.default}
                          alt=""
                          class="img-fluid rounded-1 tH-360 w-100"
                        />
      

                        <div class="img-title text-uppercase bodyHeavyFont w-100 text-center h4 mb-0">
                          {item?.gameTypeAry?.name}
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="card-content">
                        <div class="row justify-content-between py-2 ps-3">
                          <div class="col-4">
                            <label for="" class="d-block h5">
                              {t("tournaments.date")}
                            </label>
                            <h5 class="bodyBoldFont mb-0">
                              {moment(item?.startDate).format("DD/MM/YYYY")}
                            </h5>
                          </div>
                          <div class="col-4 dividersX">
                            <label for="" class="d-block h5">
                              {t("tournaments.rounds")}
                            </label>
                            <h5 class="bodyBoldFont mb-0">
                              {item?.rounds?.length}
                            </h5>
                          </div>
                          <div class="col-4">
                            <label for="" class="d-block h5">
                              {t("tournaments.id")}
                            </label>
                            <h5 class="bodyBoldFont mb-0">{item?.shortCode}</h5>
                          </div>
                        </div>
                        <div class="bg-primary-dark py-2 mt-1">
                          <div class="row">
                            <div class="col-6 dividersRight ps-5">
                              <label for="" class="d-block h5">
                                {item?.titles?.[0]?.name}
                              </label>
                              <h5 class="bodyBoldFont mb-0">
                                {item?.titles?.[0]?.value}
                              </h5>
                            </div>
                            <div class="col-6 ps-5">
                              <label for="" class="d-block h5">
                                {item?.titles?.[1]?.name}
                              </label>
                              <h5 class="bodyBoldFont mb-0">
                                {item?.titles?.[1]?.value}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-between py-2 ps-3 mt-1">
                          <div class="col-4">
                            <label for="" class="d-block h5">
                              {t("tournaments.prize_pool")}
                            </label>
                            <div class="d-flex align-items-center poolPrizeBox">
                              {item?.prizePoolInfo?.[0]?.totalAmount ? (
                                <span class="icon me-2">
                                  <img style={{marginTop:'-3px'}}
                                    className="icon-16"
                                    src={
                                      url?.imageUrl +
                                      item?.currency?.outCurrency?.img?.default
                                    }
                                    alt=""
                                  />
                                </span>
                              ) : (
                                <></>
                              )}
{console.log(contestList.length, contestList)}
                              <h5 class="bodyBoldFont mb-0">
                                {/* {console.log(item?.prizePoolInfo?.[0]?.totalAmount)} */}
                                {item?.prizePoolInfo?.[0]?.totalAmount
                                  ? item?.prizePool
                                  : item?.rewardDisplayText}
                              </h5>
                            </div>
                          </div>
                          <div
                            class="col-4 dividersX"
                            onClick={() => {
                              handlerWinning(item);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <label for="" class="d-block h5">
                              {t("tournaments.winners")}
                            </label>

                            <div className="d-flex align-items-center useCoinBox">
                              <h5 class="bodyBoldFont mb-0 me-2">
                                {item?.totalWinners}{" "}
                              </h5>
                              <span className="icon me-2">
                                {/* <img src={DownArrow} alt="" /> */}
                                <i className="fas fa-chevron-down mx-2"></i>
                              </span>
                            </div>
                          </div>
                          <div class="col-4">
                            <label for="" class="d-block h5">
                              {t("tournaments.join_using")}
                            </label>
                            <div class="d-flex align-items-center useCoinBox">
                              {item?.entryFee > 0 ? (
                                <span class="icon me-2">
                                  <img style={{paddingTop:'2px'}}
                                    src={
                                      url?.imageUrl +
                                      item?.currency?.inCurrency?.img?.default
                                    }
                                    alt=""
                                  />
                                </span>
                              ) : (
                                ""
                              )}

                              <h5 class="bodyBoldFont mb-0" style={{lineHeight: 1.5}}>
                                {item?.entryFee > 0
                                  ? item?.entryFee
                                  : `${t("tournaments.free")}`}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-12">
                            <div
                              class="progress"
                              role="progressbar"
                              aria-label="Basic example"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                class="progress-bar bg-info"
                                style={{ width: findPercentage(item) }}
                              ></div>
                            </div>
                            <div class="d-flex justify-content-between mt-2">
                              <h5>
                                {t("tournaments.players_remaining", {
                                  players: `${
                                    item?.totalPlayers -
                                    item?.totalJoinedPlayers
                                  }`,
                                })}
                              </h5>
                              <h5>
                                {t("tournaments.players_joined", {
                                  players: `${item?.totalJoinedPlayers}`,
                                })}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer cardFooterType1 text-white">
                  <div class="bg-secondary-subtle pt-2 pb-1 box">
                    <div class="row justify-content-center py-2 mt-1">
                      <div class="col-4 text-left border-end px-4">
                        <label for="" class="d-block h6 mb-10">
                          {t("tournaments.room_id")}
                        </label>
                        <h6 class="bodyBoldFont mb-0 d-flex align-items-center justify-content-left">
                        {item?.contestsList?.[0]?.roomId.length > 8 ? (
                            <marquee id="copyText">{item?.contestsList?.[0]?.roomId || "-"}</marquee>
                          ) : (
                            <span id="copyText">{item?.contestsList?.[0]?.roomId || "-"}</span>
                          )}
                            {item?.contestsList?.[0]?.roomId ? (
                              <span class="icon ms-2" onclick="copyText();">
                                <img
                                 className="cursor icon-18"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item?.contestsList?.[0]?.roomId
                                    );
                                    toastInfo(t('info_Copied'));
                                  }}
                                  src={CopyIcon}
                                  alt=""
                                />
                              </span>
                            ) : (
                              ""
                            )}
                        </h6>
                      </div>
                      <div
                        class="col-4 text-left px-4"
                        style={{
                          borderRight: `${
                            item?.contestData?.column ? "1px solid #fff" : ""
                          }`,
                        }}
                      >
                        <label for="" class="d-block h6 mb-10">
                          {t("tournaments.password")}
                        </label>
                        <h6 class="bodyBoldFont mb-0 d-flex align-items-center justify-content-left">
                        {item?.contestsList?.[0]?.roomPassword.length > 8 ? (
                            <marquee id="copyText1"> {item?.contestsList?.[0]?.roomPassword}</marquee>
                          ) : (
                            <span id="copyText1">
                            {item?.contestsList?.[0]?.roomPassword || "-"}
                          </span>
                          )}
                          {item?.contestsList?.[0]?.roomPassword ? (
                            <span class="icon ms-2" onclick="copyText1();">
                              <img
                               className="cursor icon-18"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    item?.contestsList?.[0]?.roomPassword
                                  );
                                  toastInfo(t('info_Copied'));
                    }}
                                src={CopyIcon}
                                alt=""
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </h6>
                      </div>
                      {item?.contestData?.column ? (
                        <div class="col-2 text-left">
                          <label for="" class="d-block h6 mb-10">
                            {t("tournaments.slot")}
                          </label>
                          <h6 class="bodyBoldFont mb-0 d-flex align-items-left justify-content-left">
                            {item?.contestData?.column}
                          </h6>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {contestList?.length  < totalPage && (
            <div class="d-flex justify-content-center mt-5">
              <button
                class="btn btn-primary col-md-2 text-center mt-5"
                onClick={() => loadMoreBtnClick()}
              >
                {t("tournaments.load_more")}
              </button>
            </div>
          )}
        </div>
      ) : (
        <>
          <div class="text-center">
            <img className="img-fluid" src={NoFound} alt="" />
          </div>
        </>
      )
      }
      {showWinningModal && (
        <WinningBreakUp
          showWinningModal={showWinningModal}
          setShowWinningModal={setShowWinningModal}
          selectedTournament={selectedTournament}
        />
      )}
      {showRulesModal && (
        <RulesModal
          showRulesModal={showRulesModal}
          setShowRulesModal={setShowRulesModal}
          selectedTournament={selectedTournament}
        />
      )}
    </>
  );
};

export default TournamentList;
