import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import { func } from "../../../Utilities/logFunc";
import { getLeaderboardWeeklyList } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import Loader from "../../Common/Loader";
import Search from "../../../assets/images/search-icon-svg.svg";
import Casual from "../../../assets/images/d-casual.png";
import Master from "../../../assets/images/d-master.png";
import Pro from "../../../assets/images/d-pro.png";

import { useTranslation } from "react-i18next";
import { logout } from "../../../Utilities/logout";
import UserProfileModal from "../../Profile/UserProfileModal";
const ByWeek = ({ leaderboardType }) => {
  const {t} = useTranslation()
  let [leaderBoardData, setLeaderBoardData] = useState([]);
  let [limit, setLimit] = useState(15);
  let [count, setCount] = useState(1);
  let [search, setSearch] = useState("");
  let [totalRecord, setTotalRecord] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userId, setUserId] = useState("");

  const fetchLeaderBoardData = async (totalCount) => {
    try {
      if (leaderboardType === "byWeek") {
        setLoading(true);
        let data = {
          skip: 0,
          limit: parseInt(limit) * parseInt(totalCount),
          type: "byWeek",
          q: search || "",
        };

        const res = await getLeaderboardWeeklyList(data);
        if (res?.data?.list) {
          setLeaderBoardData(res?.data?.list);
          setTotalRecord(res?.data?.count);
          setLoading(false);
        }
        func(res);
      }
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };
  const leaderBoardSearch = () => {
    // if (search.length > 0)
    fetchLeaderBoardData(count);
  };
 
  useEffect(() => {
    if (!leaderBoardData.length > 0 || search.length == 0 ) {
      fetchLeaderBoardData(count);
    }
  }, [leaderboardType, search]);

  const checkPhoneNumber = (num) => {
    var trailingCharsIntactCount = 5;
    if (num?.length) {
      let str =
        new Array(num.length - trailingCharsIntactCount + 1).join("x") +
        num.slice(-trailingCharsIntactCount);
      return str;
    } else {
      let str = "xxxxx";
      return str;
    }
  };

  const loadMoreBtnClick = () => {
    let totalCount = parseInt(count) + 1;
    setCount(totalCount);
    fetchLeaderBoardData(totalCount);
  };

  // useEffect(() => {
  //   fetchLeaderBoardData(count);
  // }, [search]);

  return (
    <>
      <div
        class="tab-pane fade"
        id="byWeek-tab-pane"
        role="tabpanel"
        aria-labelledby="byWeek-tab"
        tabindex="0"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div class="d-flex">
              <form class="d-flex mb-4 pb-1 ms-auto searchForm" role="search">
                <input
                  class="form-control"
                  type="search"
                  placeholder={t('leaderboard.search')}
                  aria-label="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button onClick={() => leaderBoardSearch()}>
                  <img src={Search} alt="" />
                </button>
              </form>
            </div>
            <div class="card tournamentCard">
              <div class="card-header  p-3">
                <div class="row justify-content-between align-items-center">
                  <div class="col-sm-3 col-2 text-center">
                    <h3 class="bodyBoldFont mb-0 mt-2 text-center h4">{t('leaderboard.rank')}</h3>
                  </div>
                  <div class="col-sm-3 col-4">
                    <h3 class="bodyBoldFont mb-0 mt-2 h4">{t('leaderboard.team_name')}</h3>
                  </div>
                  <div class="col-3 text-center">
                    <h3 class="bodyBoldFont mb-0 mt-2 text-center h4">
                    {t('leaderboard.points')}
                    </h3>
                  </div>
                  <div class="col-3 text-center">
                    <h3 class="bodyBoldFont mb-0 mt-2 text-center h4">{t('leaderboard.level')}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card playersDetailCard mt-1 mb-4">
              <div class="card-body">
                <ul class="list-unstyled mb-0">
                  {leaderBoardData.length > 0 ? (
                    leaderBoardData.map((data, indp) => (
                      <li class="row justify-content-between align-items-center" key={indp}>
                        <div class="col-sm-3 col-2 text-center">
                          <h5 class="bodyNormalFont mb-0">{data?.rank || 0}</h5>
                        </div>
                        <div class={`col-sm-3 col-4 d-flex `} 
                        onClick={() => {
                          setShowUserDetails(true)
                          setUserId(data?._id)
                        }}
                        >
                          <div class="d-flex w-100">
                          <div class="icon me-2 d-block">
                              {data?.level?.featuredImage?.default && (
                                <div class="hexagon" id="hexagon">
                                <div className="polygon-image">
                                <img
                                  class="icon-40"
                                  src={
                                    url.imageUrl +
                                    data?.level?.featuredImage?.default
                                  }
                                  alt={data?.user?.gamerjiName}
                                />
                                </div>
                                </div> 
                              )}
                            </div>
                            <div className="d-flex w-100 justify-content-between align-items-center">
                              {/* <span> */}
                                <h6 class="text-golden text-ellipsis_leaderboard mt-2 mb-0">
                                  {
                                  // data?.user?.gamerjiName?.length > 33
                                  //   ? data?.user?.gamerjiName.substring(
                                  //       0,
                                  //       33 - 3
                                  //     ) + "..."
                                  //   : 
                                    data?.user?.gamerjiName}
                                </h6>
                                {/* <h6 class="mb-0">
                                  {checkPhoneNumber(data?.user?.phone)}
                                </h6> */}
                              {/* </span> */}
                              {data?.isPremium ? (
                                <img
                                  className="icon-premium ms-2"
                                  src={
                                    data?.planName == process.env.REACT_APP_BRONZE ? Casual : data?.planName == process.env.REACT_APP_SILVER?  Pro  : data?.planName == process.env.REACT_APP_GOLD ? Master : ""
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-3 text-center">
                          <h5 class="bodyNormalFont mb-0">
                            {data?.points || 0}
                          </h5>
                        </div>
                        <div class="col-3 text-center">
                          <h5 class="bodyNormalFont mb-0">
                            {data?.level?.num || 0}
                          </h5>
                        </div>
                      </li>
                    ))
                  ) : (
                    <h3 className="mt-2 text-center">{t('notFound.no_records_found')}</h3>
                  )}
                </ul>
              </div>
              {leaderBoardData.length < totalRecord && (
                <div class="card-footer d-flex justify-content-center">
                  <button
                    class="btn btn-primary col-md-2 text-center"
                    onClick={() => loadMoreBtnClick()}
                  >
                     {t('tournaments.load_more')}
                  </button>
                </div>
              )}

              {showUserDetails && (
                <UserProfileModal
                  showUserDetails={showUserDetails}
                  setShowUserDetails={setShowUserDetails}
                  userId={userId}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ByWeek;
