import React, { useState } from 'react'
import LandingPage from './LandingPage'



const Contact = () => {
   const [active, setActive] = useState(1)

  return (
    <LandingPage active={active}>
   <div class="container">
         <section class="section-explore">
            <div class="row ">
               <div class="explore-text-area">
                  <h1 class="heading-primary text-center"> <span class="mr-2">Level Up</span> Your Brand with D11 <br />
                     Gaming! 
                  </h1>
                  <p class="text-center">Welcome to the revolutionary world of gaming! We at D11 Gaming are dedicated to connecting with<br />
                     the youth audience in the Middle East. Our mission is to provide a fun and engaging environment for<br />
                     gamers to connect, explore, and compete. We offer a wide range of services, including community<br />
                     building, tournament management, brand engagement and marketing. Our experienced team of <br />
                     professionals can help you create a unique gaming experience for your brand.
                  </p>
               </div>
            </div>
         </section>
      </div>
      <div class="container">
         <section class="row submit-form d-flex justify-content-center align-items-center align-content-center">
            <div class="col-8 ">
               <form class="row g-3 ">
                  <p class="text-center col-12">If you are interested in doing business with us, please fill out the form below and one of<br />
                     our representatives will be in touch with you shortly. Thank you for your interest in D11 Gaming!<br />
                  </p>
                  <div class="col-md-6">
                     <input type="text" class="form-control" placeholder="Name" aria-label="First name" />
                  </div>
                  <div class="col-md-6">
                     <input type="email" class="form-control" placeholder="Email" aria-label="Last name" />
                  </div>
                  <div class="col-md-6">
                     <input type="number" class="form-control" placeholder="Phone" aria-label="First name" />
                  </div>
                  <div class="col-md-6">
                     <input type="text" class="form-control" placeholder="Company" aria-label="Last name" />
                  </div>
                  <div class="col-12">
                     <textarea class="form-control" placeholder="" id="floatingTextarea2" style={{height: "100px"}}></textarea>
                  </div>
                  <div class="col-12 d-flex align-items-center justify-content-center">
                     <button type="submit" class="btn1 btn1-submit">Send Message</button>
                  </div>
               </form>
            </div>
         </section>
      </div>
      
     
    </LandingPage>
  )
}

export default Contact