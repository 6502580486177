import React from 'react'
import Friend from '../../../assets/images/shareFriends-svg.svg'
import { RWebShare } from "react-web-share";
import { url } from '../../../Utilities/url';
import { useTranslation } from "react-i18next";

const FriendsShare = ({code}) => {
  const {t} = useTranslation()

  return (
    <RWebShare
            data={{
              text: t('Profile.join_text',{name:"Mobily"}),
              url:`${url.client_base_url}home?code=${code}`,
              title:  t('Profile.join_me',{name:"Mobily"}),
            }}
            onClick={() => console.info("share successful!",`${url.client_base_url}home?code=${code}`
            )}
          >
    <div class="card shareFriendsCard cursor" >
              <div class="card-icon text-center">
                <img src={Friend} alt="" />
              </div>
              <div class="card-title text-center mt-3">
                <h4>{t('contest_details.share_with_friends')}</h4>
              </div>
            </div>
            </RWebShare>
  )
}

export default FriendsShare