const client_base_url = process.env.REACT_APP_CLIENT_BASE_URL;
const base_url = process.env.REACT_APP_BASE_URL;
const saas_api_urll = process.env.REACT_APP_SAAS_API_URL;
const s3_base_url = process.env.REACT_APP_S3_BASE_URL;
const eass_url = process.env.REACT_APP_CLIENT_BASE_URL;
// export const SAAS_API_URL ="https://gamerji-api-by-eaas-testing.gamerji.cloud/"; //staging

export const url = {
  apiUrl: base_url + "api/",
  saasApiUrl: saas_api_urll + "api/",
  publicUrl: base_url + "api/public/",
  imageUrl: s3_base_url + "/",
  gamesUrl: saas_api_urll + "api/game/",
  gamesTypesUrl: saas_api_urll + "api/gameType/",
  apiUrlV2: base_url + "api/v2/",
  //scrim
  scrim_url: saas_api_urll + "api/v2/scrim/",
  saasApiUrlV2: saas_api_urll + "api/v2/",
  client_base_url:client_base_url,
  saasApiD11Url: saas_api_urll + "api/mobily/",



};
