// firebaseAnalytics.js
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent ,setUserId} from 'firebase/analytics';
const firebaseConfig = {
  apiKey: "AIzaSyBHcVLZmmHQUAdMBH_VGdbZ2HL71l7eMIM",
  authDomain: "mobily-42da3.firebaseapp.com",
  projectId: "mobily-42da3",
  storageBucket: "mobily-42da3.appspot.com",
  messagingSenderId: "264862393055",
  appId: "1:264862393055:web:4c6da82ee000bac90e3f6a",
  measurementId: "G-7LNL9ELYYQ"
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

//setUserId(analytics, "GU-222222");

//log event
const eventTracking=(event,parameters)=>{
  //MG_UserID, MG_TimeStamp
  var currentDate = new Date().toTimeString();
  var profileData = JSON.parse(localStorage.getItem("profile"));
  console.log(profileData);
  var customParam ={...parameters,MG_TimeStamp:currentDate };
  if(profileData!=null && profileData?.item?.user_id){
    customParam.MG_UserID = profileData?.item?.user_id;
  }else if(profileData!=null && profileData?.item?._id){
    customParam.MG_UserID = profileData?.item?._id;
  }
  console.log("Event Triggered",event,customParam)
  logEvent(analytics, event, customParam);
}

export { analytics, logEvent,eventTracking };


 


