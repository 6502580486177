import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { url } from "../../../Utilities/url";
import { accountCheckEmail, getHeaderApi } from "../../../Utilities/controller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { logout } from "../../../Utilities/logout";
import { errors } from "../../../Utilities/error";
import { useProfileDataContext } from "../ProfileContext";
import { toastError } from "../../../Utilities/toast";

const EmailVerificationModal = ({
  emailVerificationModal,
  setEmailVerificationModal,
  sendRequest,
}) => {
  const [show, setShow] = useState(emailVerificationModal);
  const { t } = useTranslation();
  const [timerCount, setTimer] = useState(30);
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => {
    setEmailVerificationModal(false);
    setShow(false);
    sendRequest(false);
  };
  const { fetchAccountProfile } = useProfileDataContext()
  const {liteProfile} = useProfileDataContext()
  const handleSubmit = async (values) => {
    try {
      if (frmInitialValues?.type === "otpRequest") {
        if (
          values?.email != "" &&
          values?.email != "null" &&
          values?.email != "undefined" &&
          values?.email != null &&
          values?.email != undefined
        ) {
          let data = {
            type: "otpRequest",
            email: values?.email,
          };

          const res = await accountCheckEmail(data);
        
          if (res?.data?.success) {
            
            let fromdataobj = { ...frmInitialValues };
            fromdataobj.isSubmitEmail = true;
            fromdataobj.type = "validateOTP";
            setFrmInitialValues(fromdataobj);
          } else {
            toastError(res?.data?.errors?.[0]?.msg);
          }
        } else {
          toastError(t('error_Please_enter_a_valid_email_address'));
        }
      } else {
        if (
          values?.otp != "" &&
          values?.otp != "null" &&
          values?.otp != "undefined" &&
          values?.otp != null &&
          values?.otp != undefined
        ) {
          let data = {
            type: "validateOTP",
            email: values?.email,
            otp: values?.otp,
          };

          const res = await accountCheckEmail(data);

          if (res?.data?.success) {
            fetchAccountProfile()
            sendRequest(true);
            setEmailVerificationModal(false);
            setShow(false);
          } else {
            toastError(res?.data?.errors?.[0]?.msg);
          }
        } else {
          toastError(t('error_enter_code'));
        }
      }
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      // if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
      // logout()
      setEmailVerificationModal(false);
      sendRequest(false);
      setShow(false);
      toastError(error?.response?.data?.errors?.[0]?.msg);
    }
    showHideResendOTP();
  };

    // This function should set the OTP Timer of 30 seconds.
    const setCountDownTimer = () => {
      setTimer(timerCount - 1);
    };

    // This function should show/hide Resend OTP Button Visibility.
    const showHideResendOTP = () => {
      setTimer(30);
    };
    useEffect(() => {
      if (timerCount !== 0) {
        setTimeout(() => {
          setCountDownTimer();
        }, 1000);
      }
    }, [timerCount]);

   // This function should call the Resend OTP API
   const callToResendOTPAPI = async (otp) => {
    let payload = {
      email: frmInitialValues?.email,
      type: "otpRequest",
      otp:""
    };
    setLoading(true);
    try {
      const res = await accountCheckEmail(payload);
      console.log("otp response:", res);
      if (res?.data?.success) {
        setTimer(30);
      }
    } catch (error) {
      console.log("error", error);
      if (
        error?.response?.data?.errors?.[0]?.code ==
        "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
        "middlewares.token_parser.validation.token_expired"
      )
        {logout();return;}
      toastError(error?.response?.data?.errors?.[0]?.msg);
    }
    setLoading(false);
  };

  const [frmInitialValues, setFrmInitialValues] = useState({
    email: liteProfile?.item?.email,
    isSubmitEmail: false,
    otp: "",
    type: "otpRequest",
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <Formik
          enableReinitialize={true}
          initialValues={frmInitialValues}
          //   validationSchema={applyPromoCodeSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            // handleSubmit,
            errors,
            values,
            // setFieldValue,
            // validateField,
            // validateForm,
          }) => (
            <Form>
              <div
                class="col-12 justify-content-center align-items-center"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3 class="bodyBoldFont mb-5 text-center">
                  {t('Profile.email')}
                </h3>

                {frmInitialValues?.isSubmitEmail && (
                  <span className="mb-5">
                    {t('reward_store.we_sent_otp')}{" "}
                    <span className="bodyBoldFont">
                      {frmInitialValues?.email}
                    </span>
                  </span>
                )}

                <div className="col-sm-6 col-10 mb-5">
                  {!frmInitialValues?.isSubmitEmail ? (
                    <>
                      <label for="email" className="form-label">
                        {t('Profile.email')}
                      </label>
                      <Field
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder={t('Profile.enter_your_email_id')}
                        name="email"
                        value={frmInitialValues.email}
                        onChange={(e) => {
                          let fromdataobj = { ...frmInitialValues };
                          fromdataobj.email = e.target.value;
                          setFrmInitialValues(fromdataobj);
                        }}
                      />
                      {errors.email ? <div>{errors.email}</div> : null}
                    </>
                  ) : (
                    <>
                      <label for="otp" className="form-label">
                      {t('reward_store.otp')}
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="otp"
                        placeholder={t('reward_store.enter_otp')}
                        name="otp"
                        onChange={(e) => {
                          let fromdataobj = { ...frmInitialValues };
                          fromdataobj.otp = e.target.value;
                          setFrmInitialValues(fromdataobj);
                        }}
                        maxLength={6}
                        onKeyPress={(e) => {
                          if (new RegExp(/[0-9]/).test(e.key)) {
                          } else e.preventDefault();
                        }}
                      />
                      {errors.otp ? <div>{errors.otp}</div> : null}

                     
                    </>
                  )}
                </div>

                <div className="col-sm-6 col-10">
                  <button
                    className="btn btn-primary btn-arrow"
                    style={{ width: "100%" }}
                  >
                     {t("login.submit")}
                  </button>
                </div>
                {frmInitialValues?.isSubmitEmail && <div class="links mt-4">
                    <h5 className="d11-text-center">
                      {timerCount == 0 ? (
                        <span
                          class="link-offset-2 link-underline link-underline-opacity-0 verification-link"
                          style={{
                            color: "var(--accent-color)",
                            cursor: "pointer",
                          }}
                          onClick={(e) => callToResendOTPAPI(e)}
                        >
                          {t("login.resend")}
                        </span>
                      ) : null}
                    </h5>
                    <h5 className="d11-text-center">
                      {timerCount != 0 ? (
                        <span
                          class="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend"
                          onClick={(e) => callToResendOTPAPI(e)}
                        >
                          {/* {t("login.request_one")} {timerCount} {t("login.seconds")} */}
                          {t("login.request_one", { n: timerCount })}
                        </span>
                      ) : null}
                    </h5>
                  </div>}
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default EmailVerificationModal;
