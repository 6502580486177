import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import D11Gold from "../../assets/images/Master.png";
import { useTranslation } from "react-i18next";

const BaseModalSubscription = ({
  children,
  showModal,
  setShowModal,
  text,
  buttonHandler,
  handlerClose,
}) => {
  const [show, setShow] = useState(showModal);
  const { t } = useTranslation();

  const handleClose = () => {
    setShowModal((prev) => false);
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handlerClose}
        ></button>
        <div className="row justify-content-center ">
          <div
            className="col-12 d-flex flex-column justify-content-center"
            style={{ alignItems: "center" }}
          >
            <img className="icon-120" src={D11Gold} />
            <h2 className="d11-premium mt-2">{t("premium.premium")}</h2>
            {children}
            <div className="col-lg-6 mt-0">
              <div className="d-grid">
                <button
                  className="btn d11-premium-button mt-5"
                  onClick={buttonHandler}
                >
                  {text}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BaseModalSubscription;
