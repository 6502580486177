export const routes = {
  login: "login",
  otp: "otp",
  avatar: "avatar",
  home: "home",
  gameTournament: "tournaments",
  tournamentDetails: "tournament-details/:id",
  worldOfEsports: "world-of-esports",
  news: "esports-news",
  newsDetails: "news-details",
  topProfiles: "top-profile",
  popularVideos: "popular-video",
  myContest: "my-contest",
  privateContest: "private-contest",
  leaderBoard: "leader-board",
  managePrivateContest: "manage-contest/:id",
  gameContest: "contest",
  contestDetails: "contest-details/:id",
  signupotp: "signupotp",
  coinStore: "coin-store",
  createPrivateContest: "create-contest",
  editPrivateContest: "edit-contest/:id",
  customerCare: "customer-care",
  raiseACompaint: "raise-a-complaint",

  rewardStore: "reward-store",
  scrimDetails: "scrim-details/:id",
  freeGames: "free-games",
  legality: "legality",
  myProfile: "my-profile",
  paymentStatus: "payment-status/:id",
  ticketDetails: 'ticket-details/:id',
  getSubscriptionTypeList: 'get-premium',
  paymentStatusSubscription: "payment-status-subscription/:trxId",
  userProfile: "user-profile/:id",
  landingPAge: 'landing-page',
  contact: 'contact',
  about: 'about',
  forgetPin: 'forget-pin',
  setpin: 'setpin',
  termsAndCondition: 'termsAndCondition',
  policy: 'policy'
};
