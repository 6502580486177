import React, { useEffect, useState } from "react";
import { url } from "../../../Utilities/url";
import moment from "moment";
import { func } from "../../../Utilities/logFunc";
import { requestWalletUsageLimit } from "../../../Utilities/controller";
import { useNavigate } from "react-router";
import DownArrow from "../../../assets/images/downArrow-svg.svg";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import { useTranslation } from "react-i18next";

import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";
import { toastInfo } from "../../../Utilities/toast";

const ContestStructure = ({
  handleJoinContest,
  handlerWinning,
  tournaments,
  setShowRulesModal,
  setShowJoinModal,
  setShowJVC,
}) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation()
  const remainingPlayers =
    tournaments?.totalPlayers - tournaments?.totalJoinedPlayers;

  return (
    <div class="card tournametParticipationCard tournamentSoloCard">
      <div class="card-header">
        <h4 class="card-title mb-0">
          {func("length", tournaments?.title?.length)}
          {tournaments?.title?.length > 25 ? (
            <marquee>{tournaments?.title}</marquee>
          ) : (
            tournaments?.title
          )}
        </h4>
        <span
          class="rulesLink"
          onClick={() => {
            navigator.clipboard.writeText(tournaments?.shortCode);
            toastInfo(t('info_Copied'));
          }}
        >
          {t('tournaments.id')}: {tournaments?.shortCode}
        </span>
      </div>
      <div class="card-body" style={{}}>
        <div class="row">
          <div class="col-12">
            <div class="card-content">
              <div
                class="row justify-content-between py-2"
                style={{ backgroundColor: "var(--primary-color-dark)" }}
              >
                <div class="col-3 ">
                  <label for="" class="d-block h6">
                    {t('tournaments.date')}
                  </label>
                  <h6 class="bodyBoldFont mb-0">
                    {moment(tournaments?.date).format("D/M/YYYY")}
                  </h6>
                </div>
                <div class="col-3 dividersX">
                  <label for="" class="d-block h6">
                    {t('tournaments.time')}

                  </label>
                  <h6 class="bodyBoldFont mb-0">
                    {moment(tournaments?.dateTime).format("hh:mm A")}
                  </h6>
                </div>
                <div class="col-3 dividersRight">
                  <label for="" class="d-block h6 mb-1 text-ellipsis_contest text-line-height">
                    {
                      // tournaments?.titles?.[0]?.name?.length > 9
                      //   ? tournaments?.titles?.[0]?.name?.substring(0, 9 - 3) +
                      //     "..."
                      //   : 
                      tournaments?.titles?.[0]?.name}
                    {/* {tournaments?.titles[0]?.name} */}
                  </label>
                  <h6 class="bodyBoldFont mb-0 text-ellipsis_contest text-line-height " >
                    {
                      // tournaments?.titles?.[0]?.value?.length > 9
                      //   ? tournaments?.titles?.[0]?.value?.substring(0, 9 - 3) +
                      //     "..."
                      //   : 
                      tournaments?.titles?.[0]?.value
                    }
                    {/* {tournaments?.titles[0]?.value} */}
                  </h6>
                </div>
                <div class="col-3">
                  <label for="" class="d-block h6 mb-1 text-ellipsis_contest text-line-height">
                    {
                      // tournaments?.titles?.[1]?.name?.length > 9
                      //   ? tournaments?.titles?.[1]?.name?.substring(0, 9 - 3) +
                      //     "..."
                      //   : 
                      tournaments?.titles?.[1]?.name}
                    {/* {tournaments?.titles[1]?.name} */}
                  </label>
                  <h6 class="bodyBoldFont mb-0 text-ellipsis_contest text-line-height" >
                    {
                      // tournaments?.titles?.[1]?.value?.length > 9
                      //   ? tournaments?.titles?.[1]?.value?.substring(0, 9 - 3) +
                      //     "..."
                      // : 
                      tournaments?.titles?.[1]?.value}
                    {/* {tournaments?.titles[1]?.value} */}
                  </h6>
                </div>
              </div>
              <div class="row justify-content-between py-2 mt-1">
                <div class="col-4">
                  <label for="" class="d-block h6">
                    {t('tournaments.prize_pool')}
                  </label>
                  <div class="d-flex align-items-center useCoinBox">
                    <span class="icon me-2">
                      <img
                        src={
                          url.imageUrl +
                          tournaments?.currency?.[0]?.outCurrency?.img?.default
                        }
                        alt=""
                      />
                    </span>
                    <h6 class="bodyBoldFont mb-0">{tournaments?.prizePool}</h6>
                  </div>
                </div>
                {tournaments?.winningModel == "perKill" ? (
                  <div class="col-4 dividersX d-flex">
                    <div class="">
                      <label for="" class="d-block h6">
                        {t('tournaments.per_kill')}
                      </label>
                      <div class="d-flex align-items-center useCoinBox">
                        <span class="icon me-2">
                          {tournaments?.currency[0]?.outCurrency?.code ==
                            "INR" ? (
                            "₹"
                          ) : (
                            <img
                              src={
                                url.imageUrl +
                                tournaments?.currency[0]?.outCurrency?.img
                                  ?.default
                              }
                              alt=""
                            />
                          )}
                        </span>
                        <h6 class="bodyBoldFont mb-0">
                          {" "}
                          {tournaments?.killPoints}
                        </h6>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="col-4 dividersX"
                    onClick={() => handlerWinning(tournaments)}
                  >
                    <label for="" className="d-block h6">
                      {t('tournaments.winners')}
                    </label>

                    <div className="d-flex align-items-center useCoinBox">
                      <h6 className="bodyBoldFont mb-0">
                        {tournaments?.totalWinners}
                      </h6>
                      {/* <span className="icon me-2">
                      <img
                        src={
                          DownArrow
                        }
                        alt=""
                      />
                    </span> */}
                      <span class="icon me-2">
                        <i className="fas fa-chevron-down mx-2"></i>
                      </span>
                    </div>
                  </div>
                )}
                <div class="col-4 d-flex">
                  <div class="">
                    <label for="" class="d-block h6">
                      {t('tournaments.join_using')}
                    </label>
                    <div class="d-flex align-items-center useCoinBox">
                      {tournaments?.entryFee > 0 ? (
                        <span class="icon me-2">
                          <img style={{ paddingTop: '2px' }}
                            src={
                              url.imageUrl +
                              tournaments?.currency[0]?.inCurrency?.img?.default
                            }
                            alt=""
                          />
                        </span>
                      ) : (
                        ""
                      )}

                      <h6 class="bodyBoldFont mb-0" style={{ lineHeight: 1.5 }}>
                        {tournaments?.entryFee > 0
                          ? tournaments?.entryFee
                          : t('tournaments.free')}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <div
                    class="progress"
                    role="progressbar"
                    aria-label="Basic example"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      class="progress-bar bg-info"
                      style={{
                        width: `${(100 * tournaments?.totalJoinedPlayers) /
                          tournaments?.totalPlayers
                          }%`,
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between mt-2">
                    <h5>{t('tournaments.players_remaining', { players: remainingPlayers })}</h5>
                    <h5>{t('tournaments.players_joined', { players: tournaments?.totalJoinedPlayers })} </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tournaments?.totalPlayers - tournaments?.totalJoinedPlayers > 0 ?        
        <div class="card-footer d-grid">
        {tournaments?.isJoined ? (
          <button
            class="btn btn-primary"
            onClick={() => {
              if (setShowJVC) setShowJVC(false);
              if (setShowJoinModal) setShowJoinModal(false);
              //event
              navigate("/home/contest-details/" + tournaments?._id);
            }}
          >
            {t('tournaments.joined')}
          </button>
        ) :
          (
            <button
              class="btn btn-primary"
              onClick={() => {
                //event
                eventTracking(events.CONTEST_LIST.JOIN_NOW, {
                  MG_GameName: tournaments?.gameAry?.[0]?.name,
                  MG_GameType: tournaments?.gameTypeAry?.name,
                  MG_GameID: tournaments?.gameAry?.[0]?._id,
                  MG_ContestID: tournaments?._id
                });
                handleJoinContest(tournaments)
              }}
            >
              {t('tournaments.join_now')}
            </button>
          )}
      </div>   
      :
      <div class="card-footer d-grid">
        { tournaments?.isJoined && remainingPlayers == 0 ? (
             <button
             class="btn btn-primary"
             onClick={() => {
               if (setShowJVC) setShowJVC(false);
               if (setShowJoinModal) setShowJoinModal(false);
               //event
               navigate("/home/contest-details/" + tournaments?._id);
             }}
           >
             {t('tournaments.joined')}
           </button>         
          ) : (
            <button
            class="btn btn-primary disabled"
          // onClick={() =>
          //   navigate("/home/contest-details/" + tournaments?._id)
          // }
          >
            {t('tournaments.full')}
          </button>
          )}
      </div>
      }
    </div>
  );
};

export default ContestStructure;
