import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { func } from "../../../Utilities/logFunc";
import {
  addContestRate,
  getContestDetails,
  getContestRate,
  getContestUserList,
} from "../../../Utilities/controller";
import moment from "moment";
import { url } from "../../../Utilities/url";
import Loader from "../../Common/Loader";
import Sad from "../../../assets/images/sad-icon-svg.svg";
import NotGood from "../../../assets/images/notGood-icon-svg.svg";
import Ok from "../../../assets/images/ok-icon-svg.svg";
import Happy from "../../../assets/images/happy-icon-svg.svg";
import VeryHappy from "../../../assets/images/veryHappy-icon-svg.svg";
import UserName from "../../../assets/images/username-icon-svg.svg";
import Rank from "../../../assets/images/Rank.png";
import Kills from "../../../assets/images/Kill.png";
import DownArrow from "../../../assets/images/downArrow-svg.svg";
import FriendsShare from "../PrivateContest/FriendsShare";
import WinningBreakUp from "../Tournament/WinningBreakUp";
import CopyIcon from "../../../assets/images/copy-icon-svg.svg";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import Casual from "../../../assets/images/d-casual.png";
import Master from "../../../assets/images/d-master.png";
import Pro from "../../../assets/images/d-pro.png";

import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import { logout } from "../../../Utilities/logout";
import { toastInfo } from "../../../Utilities/toast";

const ContestDetailsInfo = () => {
  const { id } = useParams();
  // var user_data = JSON.parse(localStorage.getItem("userData"));
  const { profileData } = useProfileDataContext();
  const { t } = useTranslation();

  const [contestDetailData, setContestDetailData] = useState([]);
  const [contestCaptainData, setContestCaptianData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [eventRate, setEventRate] = useState(null);
  const [showWinningModal, setShowWinningModal] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const remainingPlayers =
    contestDetailData?.totalPlayers - contestDetailData?.totalJoinedPlayers;

  useEffect(() => {
    fetchContestDetails();
    fetchContestUserData();
    fetchContestRate();
  }, []);

  const fetchContestDetails = async () => {
    setLoading(true);
    const payload = {
      search: profileData?.item?.user_id,
    };
    try {
      const res = await getContestDetails(id, payload);
      if (res?.data?.success) {
        setContestDetailData(res?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      func("fetchContestDetails error", error?.response);
      setLoading(false);
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
    }
  };

  const fetchContestUserData = async () => {
    setLoading(true);
    try {
      const payload = {
        pagination: {
          pageNo: 1,
          recordPerPage: 10,
          sortBy: "rank",
          sortDirection: "asc",
        },
        search: {
          user: profileData?.item?.user_id,
        },
      };
      const res = await getContestUserList(id, payload);
      if (res?.data?.success) {
        setContestCaptianData(res?.data?.captainData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      func("fetchContestUserData error", error?.response);
      setLoading(false);
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
    }
  };

  const fetchContestRate = async () => {
    try {
      let data = {
        contest: id,
      };
      const res = await getContestRate(data, id);
      // console.log("fetchContestRate=========", res);
      setEventRate(res?.data?.data);
    } catch (error) {
      func("fetchContestRate err", error?.response?.data?.errors?.[0]?.msg);
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
          || error?.response?.data?.errors?.[0]?.code === "auth.validation.signin.account_inactive"
      ){
        logout();
        return;
      }
    }
  };

  const rating = async (rate) => {
    try {
      let data = {
        rate: rate,
        contest: id,
      };
      const res = await addContestRate(data);
      fetchContestRate();
      func("rating", res);
    } catch (error) {
      func("rating err", error?.response?.data?.errors?.[0]?.msg);
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
    }
  };
  const handlerWinning = (data) => {
    setSelectedTournament(contestDetailData);
    setShowWinningModal((prev) => true);
  };
  return (
    <>
      {" "}
      {isLoading ? (
        <Loader />
      ) : (
        <div class="col-lg-5 pe-lg-5">
          <div class="title">
            <h3 class="bodyBoldFont mb-3">
              {t("contest_details.contest_details")}
            </h3>
          </div>
          <div class="card tournametParticipationCard tournamentSoloCard contestTournamentSoloCard">
            <div class="card-header">
              <h4 class="card-title mb-0">{contestDetailData?.title}</h4>
              <span
                class="rulesLink"
                onClick={() => {
                  navigator.clipboard.writeText(contestDetailData?.shortCode);
                  toastInfo(t('info_Copied'));
                }}
              >
                {t("contest_details.id")}: {contestDetailData?.shortCode}
              </span>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="card-content">
                    <div class="row justify-content-between py-2">
                      <div class="col-3">
                        <label for="" class="d-block h6">
                          {t("contest_details.date")}
                        </label>
                        <h6 class="bodyBoldFont mb-0">
                          {moment(contestDetailData?.date).format("DD/MM/YYYY")}
                        </h6>
                      </div>
                      <div class="col-3 dividersX">
                        <label for="" class="d-block h6">
                          {t("contest_details.time")}
                        </label>
                        <h6 class="bodyBoldFont mb-0">
                          {moment(contestDetailData?.time).format("h:mm A")}
                        </h6>
                      </div>
                      <div class="col-3 dividersRight">
                        <label for="" class="d-block h6 mb-1 text-ellipsis_contest text-line-height">
                          {contestDetailData?.titles?.[0]?.name}
                        </label>
                        <h6 class="bodyBoldFont mb-0 text-ellipsis_contest text-line-height">
                          {contestDetailData?.titles?.[0]?.value}
                        </h6>
                      </div>
                      <div class="col-3">
                        <label for="" class="d-block h6 mb-1 text-ellipsis_contest text-line-height">
                          {contestDetailData?.titles?.[1]?.name}
                        </label>
                        <h6 class="bodyBoldFont mb-0 text-ellipsis_contest text-line-height">
                          {" "}
                          {contestDetailData?.titles?.[1]?.value}
                        </h6>
                      </div>
                    </div>
                    <div class="row justify-content-between py-2 mt-1">
                      <div class="col-4">
                        <label for="" class="d-block h6">
                          {t("contest_details.prize_pool")}
                        </label>
                        <div class="d-flex align-items-center poolPrizeBox">
                          <span class="icon me-2">
                            <img style={{marginTop:'-2px'}}
                              className="icon-20"
                              src={
                                url?.imageUrl +
                                contestDetailData?.currency?.[0]?.outCurrency
                                  ?.img?.default
                              }
                              alt=""
                            />
                          </span>
                          <h6 class="bodyBoldFont mb-0">
                            {contestDetailData?.prizePool}
                          </h6>
                        </div>
                      </div>
                      {contestDetailData?.winningModel !== "perRank" ? (
                        <div class="col-4 dividersX d-flex">
                          <div class="">
                            <label for="" class="d-block h6">
                              {t("contest.per_kill")}
                            </label>
                            <div class="d-flex align-items-center poolPrizeBox">
                              <span class="icon me-2">
                                {contestDetailData?.currency?.[0]?.outCurrency
                                  ?.code == "INR" ? (
                                  "₹"
                                ) : (
                                  <img style={{marginTop:'-3px'}}
                                    src={
                                      url.imageUrl +
                                      contestDetailData?.currency?.[0]
                                        ?.outCurrency?.img?.default
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <h6 class="bodyBoldFont mb-0">
                                {" "}
                                {contestDetailData?.killPoints}
                              </h6>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="col-4 dividersX"
                          onClick={handlerWinning}
                        >
                          <label for="" className="d-block h6">
                            {t("tournaments.winners")}
                          </label>

                          <div className="d-flex align-items-center poolPrizeBox">
                            <h6 className="bodyBoldFont mb-0 ">
                              {contestDetailData?.totalWinners}
                            </h6>
                            {/* <span className="icon me-2">
                              <img src={DownArrow} alt="" />
                            </span> */}
                            <i className="fas fa-chevron-down mx-2"></i>
                          </div>
                        </div>
                      )}
                      <div class="col-4 d-flex">
                        <div
                        // class="ms-auto"
                        >
                          <label for="" class="d-block h6">
                            {t("contest.join_using")}
                          </label>
                          <div class="d-flex align-items-center useCoinBox">
                            <span class="icon me-2">
                              {contestDetailData?.entryFee > 0 && (
                                <img style={{paddingTop:'2px'}}
                                  src={
                                    url?.imageUrl +
                                    contestDetailData?.currency?.[0]?.inCurrency
                                      ?.img?.default
                                  }
                                  alt=""
                                />
                              )}
                            </span>
                            <h6 class="bodyBoldFont mb-0" style={{lineHeight: 1.5}}>
                              {contestDetailData?.entryFee > 0
                                ? contestDetailData?.entryFee
                                : t("contest_details.free")}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <div
                          class="progress"
                          role="progressbar"
                          aria-label="Basic example"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <div
                            class="progress-bar bg-info"
                            style={{
                              width: `${
                                (100 * contestDetailData?.totalJoinedPlayers) /
                                contestDetailData?.totalPlayers
                              }%`,
                            }}
                          ></div>
                        </div>
                        <div class="d-flex justify-content-between mt-2">
                          <h5>
                            {t("contest_details.players_remaining", {
                              players: remainingPlayers,
                            })}
                          </h5>
                          <h5>
                            {t("contest_details.players_joined", {
                              players: contestDetailData?.totalJoinedPlayers,
                            })}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-white">
              <div class="bg-secondary-subtle pt-2 pb-1 box">
                <div class="row justify-content-center py-2 mt-1">
                  <div
                    class="col-4 text-left px-4"
                    style={
                      document.documentElement.dir == "rtl"
                        ? { borderLeft: "1px solid white" }
                        : { borderRight: "1px solid white" }
                    }
                  >
                    <label for="" class="d-block h6 mb-10">
                      {t("contest.room_id")}
                    </label>
                    <h6 class="bodyBoldFont mb-0 d-flex align-items-center">
                      {contestDetailData?.roomId?.length > 8 ? 
                      <marquee id="copyText">{contestDetailData?.roomId}</marquee>
                       :
                       contestDetailData?.roomId || "-"
                      }
                      {contestDetailData?.roomId ? (
                        <span class="icon ms-2" onclick="copyText();">
                          <img
                            src={CopyIcon}
                            alt=""
                            className="cursorPointer icon-20"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                contestDetailData?.roomId
                              );
                              toastInfo(t('info_Copied'));
                            }}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </h6>
                  </div>
                  <div class="col-4 text-left px-4">
                    <label for="" class="d-block h6 mb-10">
                      {t("contest.password")}
                    </label>
                    <h6 class="bodyBoldFont mb-0 d-flex align-items-center">
                      {contestDetailData?.roomPassword?.length > 8 ? 
                      <marquee id="copyText">{contestDetailData?.roomPassword}</marquee>
                       :
                       contestDetailData?.roomPassword || "-"
                      }
                      {contestDetailData?.roomPassword ? (
                        <span class="icon ms-2" onclick="copyText();">
                          <img
                            src={CopyIcon}
                            alt=""
                            className="cursorPointer icon-20"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                contestDetailData?.roomPassword
                              );
                              toastInfo(t('info_Copied'));
                            }}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card userProfileCard mt-5">
            <div class="card-header">
              <div class="row justify-content-between align-items-center">
                <div class="col-lg-5 col-4 d-flex">
                  <div class="text-center">
                    <span class="icon mb-2 d-block">
                      <img className="icon-30" src={UserName} alt="" />
                    </span>
                    <h5 class="bodyNormalFont mb-0">
                      {t("contest_details.mobily_username")}
                    </h5>
                  </div>
                </div>
                <div class="col-lg-6 col-8">
                  <div class="d-flex justify-content-between">
                    <div class="col text-center">
                      <span class="icon mb-2 d-block">
                        <img className="icon-30" src={Kills} alt="" />
                      </span>
                      <h5 class="bodyNormalFont mb-0">
                        {t("contest_details.kills")}
                      </h5>
                    </div>
                    <div class="col text-center">
                      <span class="icon mb-2 d-block">
                        <img className="icon-30" src={Rank} alt="" />
                      </span>
                      <h5 class="bodyNormalFont mb-0">
                        {t("contest_details.rank")}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-between align-items-center">
                <div class="col-lg-5 col-4">
                  <div class="d-flex align-items-center">
                    <span class="icon me-2 d-block">
                      <img
                        class="icon-50"
                        src={
                          url.imageUrl +
                          contestCaptainData?.userData?.userlevel?.level
                            ?.featuredImage?.default
                        }
                        alt=""
                      />
                    </span>
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h5
                        class="bodyNormalFont mb-0"
                        style={{
                          display: "block",
                          width: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {contestCaptainData?.userData?.gamerjiName}
                      </h5>
                      {contestCaptainData?.isPremium ? (
                        <img
                          className="icon-50 ms-3"
                          src={
                            localStorage.getItem("planName") ==
                            process.env.REACT_APP_BRONZE
                              ? Casual
                              : localStorage.getItem("planName") ==
                                process.env.REACT_APP_SILVER
                              ? Pro
                              : localStorage.getItem("planName") ==
                                process.env.REACT_APP_GOLD
                              ? Master
                              : ""
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-8">
                  <div class="d-flex justify-content-between">
                    <div class="col text-center">
                      <h5 class="bodyNormalFont mb-0">
                        {contestCaptainData?.kills > 0
                          ? contestCaptainData?.kills
                          : "-"}
                      </h5>
                    </div>
                    <div class="col text-center">
                      <h5 class="bodyNormalFont mb-0">
                        {contestCaptainData?.rank > 0
                          ? contestCaptainData?.rank
                          : "-"}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FriendsShare code={contestDetailData?.shortCode} />
          <div class="card feedbackCard mt-4">
            <div class="card-title text-center mb-5">
              <h4 class="m-0">
                {eventRate?.rate
                  ? t("contest_details.thanks_for_the_rating")
                  : t("contest_details.rate_mobily")}
              </h4>
            </div>
            <ul class="d-flex align-items-center justify-content-center list-unstyled m-0">
              {eventRate?.rate ? (
                eventRate?.rate === 1 ? (
                  <li class="mx-2">
                    <img className="icon-50" src={Sad} alt="" />
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <li class="mx-2">
                  <span>
                    <img
                      className="icon-50"
                      src={Sad}
                      alt=""
                      onClick={() => rating(1)}
                    />
                  </span>
                </li>
              )}

              {eventRate?.rate ? (
                eventRate?.rate === 2 ? (
                  <li class="mx-2">
                    <img className="icon-50" src={NotGood} alt="" />
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <li class="mx-2">
                  <span>
                    <img
                      className="icon-50"
                      src={NotGood}
                      alt=""
                      onClick={() => rating(2)}
                    />
                  </span>
                </li>
              )}

              {eventRate?.rate ? (
                eventRate?.rate === 3 ? (
                  <li class="mx-2">
                    <img className="icon-50" src={Ok} alt="" />
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <li class="mx-2">
                  <span>
                    <img
                      className="icon-50"
                      src={Ok}
                      onClick={() => rating(3)}
                    />
                  </span>
                </li>
              )}

              {eventRate?.rate ? (
                eventRate?.rate === 4 ? (
                  <li class="mx-2">
                    <img className="icon-50" src={Happy} alt="" />
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <li class="mx-2">
                  <span>
                    <img
                      className="icon-50"
                      src={Happy}
                      alt=""
                      onClick={() => rating(4)}
                    />
                  </span>
                </li>
              )}

              {eventRate?.rate ? (
                eventRate?.rate === 5 ? (
                  <li class="mx-2">
                    <img className="icon-50" src={VeryHappy} alt="" />
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <li class="mx-2">
                  <span>
                    <img
                      className="icon-50"
                      src={VeryHappy}
                      alt=""
                      onClick={() => rating(5)}
                    />
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      {showWinningModal && (
        <WinningBreakUp
          showWinningModal={showWinningModal}
          setShowWinningModal={setShowWinningModal}
          selectedTournament={selectedTournament}
        />
      )}
    </>
  );
};

export default ContestDetailsInfo;
