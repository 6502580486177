import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import { func } from "../../../Utilities/logFunc";
import { getTermsOfUse } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import { logout } from "../../../Utilities/logout";

const TermsOfUse = ({ type }) => {
  let [termsOfUseData, setTermsOfUseData] = useState([]);

  const fetchTermsOfUseData = async () => {
    try {
      if (type === "terms-of-use") {
        const res = await getTermsOfUse();
        if (res?.data?.item) {
          setTermsOfUseData(res?.data?.item);
        }
        func(res);
      }
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    fetchTermsOfUseData();
  }, [type]);

  return (
    <>
      {termsOfUseData?.description && (
        <div
          dangerouslySetInnerHTML={{ __html: termsOfUseData?.description }}
        />
      )}
    </>
  );
};

export default TermsOfUse;
