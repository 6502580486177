export const logout = () => {
    localStorage.removeItem("country");
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.removeItem("gamerjiToken");
    localStorage.removeItem("activeGame");
    localStorage.removeItem("activeGameId");

    // navigate("/" + routes.login);
    localStorage.clear();
    window.history.replaceState(null, "", "/");
    window.location.href = "/";
  };