import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import {
  // createStripeTransaction,
  updateTelrSubscriptionTransaction,
} from "../../Utilities/controller";
import "../../assets/css/PaymentStatus.css";
import { Modal } from "react-bootstrap";
import { func } from "../../Utilities/logFunc";
import { toast } from "react-toastify";
import Loader from "../Common/Loader";
// import Pending from "../../assets/images/pending-payment.png";
// import Fail from "../../assets/images/fail-payment.png";
// import Success from "../../assets/images/success-payment.png";
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents"; 
import moment from "moment";
import { logout } from "../../Utilities/logout";

const PaymentStatusSubscription = (props) => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  let { id, trxId } = useParams();
  const [searchParams] = useSearchParams();
  const [transactionData, setTansactionData] = useState({});
  const [show, setShow] = useState(true);
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    setShow(false);
    navigate("/home");
  };
  useEffect(() => {
    // let timer = setTimeout(() => {

      fetchCoinStoreData();
    // }, 6000);
    // return () => {
    //   clearTimeout(timer);
    // };
  }, [trxId]);

  const fetchCoinStoreData = async () => {
    setLoader(true);
    try {
      let body = {
        // subscriptionId: id,
        // clientSecret: searchParams.get("payment_intent"),
        transactionUniqueID: trxId
      };
      const res = await updateTelrSubscriptionTransaction(body);
      if (res.data.success) {
        setTansactionData(res?.data?.data);
        //MG_UserID, MG_TimeStamp,MG_Status
        func("transaction data 40 ****", res?.data?.data);
        func("**********************", res?.data?.data?.paymentStatus);
        setLoader(false);
        eventTracking(events.PAYMENT_STATUS_POPUP,{
          MG_Status:res?.data?.data?.paymentStatus,
        });
      } else {
      }
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      setLoader(false);
      toast(error?.response?.data?.errors?.[0]?.msg);
    }
  };
  //  if(loader) <Loader />
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="content text-center">
          <div class="row justify-content-center">
            <div class="col-12">
              <div className="pg_status_main_component">
                {/* <ToastContainer /> */}

                <div className="pg_status_body">
                  {/* <div className="pg_status_title_component">
                    <div className="pg_title">Payment Status</div>
                </div> */}
                  <div className="pg_ps_status_component">
                    <div
                      style={{ color: "#fff", padding: "20px" }}
                      className={
                        transactionData?.paymentStatus === "SUCCESS"
                          ? "pg_status_background_success"
                          : transactionData?.paymentStatus === "FAILED"
                          ? "pg_status_background_failed"
                          : transactionData?.paymentStatus === "DECLINED" ||
                            transactionData?.paymentStatus === "CANCELLED"
                          ? "pg_status_background_failed"
                          : "pg_status_background_pending"
                      }
                    >
                      {/* Status Icon */}
                      {/* <img className="pg_status_icon" src={
                            transactionData?.paymentStatus === 'SUCCESS' ? Success
                                : transactionData?.paymentStatus === 'FAILED' ? Fail
                                    : transactionData?.paymentStatus === 'DECLINED' || transactionData?.paymentStatus === 'CANCELLED' ? Fail
                                        : Pending
                        } /> */}

                      {/* Title */}
                      <div className="pg_status_title">
                        {loader
                          ? "Processing"
                          : transactionData?.paymentStatus === "SUCCESS"
                          ? "Success!"
                          : transactionData?.paymentStatus === "FAILED"
                          ? "Failure!"
                          : transactionData?.paymentStatus === "DECLINED" || 
                            transactionData?.paymentStatus === "CANCELLED"
                          ? "User Cancelled"
                          : "Processing..."}
                      </div>

                      {/* Amount */}
                      {transactionData?.paymentStatus && (
                        <>
                          <div className="pg_status_amount">
                            {transactionData?.currency?.symbol}{" "}
                            {transactionData?.amount}
                          </div>

                          {/* Date Time */}
                          <div className="pg_status_date">
                            {/* {new Date(
                              transactionData?.paymentTimestamp
                            ).toLocaleString()} */}
                            {moment(transactionData?.paymentTimestamp).format("Do MMMM YYYY hh:mm A")}
                          </div>

                          <div className="pg_status_transaction">
                          {t('premium.transaction_id')} : {transactionData?.transactionId}
                          </div>
                        </>
                      )}

                      {transactionData?.paymentStatus === "SUCCESS" ? (
                        <div className="pg_status_transaction">
                          {t('premium.your_subscription_success',{amount:transactionData.coinAmount})}
                        </div>
                      ) : transactionData?.paymentStatus === "FAILED" || transactionData?.paymentStatus === "DECLINED" ? (
                        <div className="pg_status_transaction">
                          {t('premium.cant_proceed')} 
                        </div>
                      ) : (
                        t('premium.transaction_inprogess')
                      )}
                    </div>
                    <button
                      className={`btn btn-primary main-btn next mt-4 mb-4 ml-2 ${
                        transactionData?.paymentStatus !== "SUCCESS" &&
                        transactionData?.paymentStatus !== "FAILED" &&
                        transactionData?.paymentStatus !== "DECLINED" && 
                        transactionData?.paymentStatus !== 'CANCELLED'  
                          ? "disabled"
                          : ""
                      }`}
                      style={{ width: "100%", border: "1px solid" }}
                      onClick={() => {
                        // navigate("/home");
                        window.location.href = '/home'
                      }}
                    >
                     {t('premium.done')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default PaymentStatusSubscription;
