import React, { useEffect, useReducer, useState } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../Utilities/logFunc";
import {
  getAllFreeAvatar,
  requestUpdateProfile,
} from "../../Utilities/controller";
import { errors } from "../../Utilities/error";
import { routes } from "../../Utilities/routesFb";
import { useNavigate } from "react-router";
import { url } from "../../Utilities/url";
import Loader from "../Common/Loader";
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import LoginLandingPage from "../LandingPage/LoginLandingPage";
import { useTranslation } from "react-i18next";
import { encryptKey } from "../../Utilities/encryptDecrypt";
import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents"; 
import { INITIAL_STATE, loginReducer } from "./loginReducer";
import { toastSuccess } from "../../Utilities/toast";

const SetPin = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [userName, setUserName] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [pinError, setPinError] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const { t } = useTranslation();
  const [loginInput, dispatchInput] = useReducer(loginReducer, INITIAL_STATE);

  const onChangeHandler = (e) => {
    setUserName((prev) => e.target.value);
    setError("");
  };

  const handleClose = () => {
    setShow(false);
    logout();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(pin, confirmPin);
    if (pin?.length < 6 || confirmPin?.length < 6) {
      setPinError(t('error_pin_length'));
    } else if (pin !== confirmPin) {
      setPinError(t('error_pin_mismatch'));
    } else {
      callCreateProfile();
    }
  };

  const callCreateProfile = async () => {
    console.log(encryptKey(pin));
    const encPin = await encryptKey(pin);
    var payload = {
      pin: encPin,
    };

    func("==========", payload);
    setLoading(true);
    try {
      const res = await requestUpdateProfile(payload);
      func("callCreateProfile response:", res);
      if (res?.success) {
           //analytics Tracking
           var profileData = JSON.parse(localStorage.getItem("loginInfo"));
           eventTracking(events.LOGIN.RESET_PIN,{
            MG_MobileNumber: profileData?.username,
            MG_CountryCode: profileData?.phoneCode,
          });

        toastSuccess(res?.msg);
        window.location.href = "/home"
      }
    } catch (error) {
      func("error", error);
      setError(error?.response?.data?.errors?.[0]?.msg);
    }
    setLoading(false);
  };

  if (isLoading) return <Loader />;
  return (
    <LoginLandingPage>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="auth-modal avatarModal modal fade "
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <div class="row justify-content-center py-4">
            <div class="col-12">
              <div class="tab-content auth-content text-center">
                <h2 class="text-nowrap verification_heading d11-codecpro d11-otp-text text-center lh-1">
                  Reset Pin
                </h2>
                <div class="row justify-content-center mt-5">
                  <div class="col-6 mt-0">
                    <div class="d-grid">
                      <label
                        for="inputMobileNumber text-align-left"
                        className="form-label"
                        style={{ textAlign: "left" }}
                      >
                        Add your pin
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        style={{
                          // "-webkit-text-security": "circle",
                          fontSize: "1.1rem",
                        }}
                        id="inputMobileNumber"
                        placeholder={t("login.enter_pin")}
                        name="pin"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                        onChange={(e) => {
                          let userInput = e.target.value;
                          if (userInput.length <= 6 && !isNaN(userInput)) {
                            setPin(userInput);
                          }
                        }}
                        maxLength={6}
                        value={pin}
                      />
                    </div>
                    <div class="d-grid mt-2">
                      <label
                        for="inputMobileNumber"
                        className="form-label mt-4"
                        style={{ textAlign: "left" }}
                      >
                        Confirm your pin
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        style={{
                          // "-webkit-text-security": "circle",
                          fontSize: "1.1rem",
                        }}
                        id="inputMobileNumber"
                        placeholder={t("login.enter_pin")}
                        name="pin"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                        onChange={(e) => {
                          let userInput = e.target.value;
                          if (userInput.length <= 6 && !isNaN(userInput)) {
                            setConfirmPin(userInput);
                          }
                        }}
                        maxLength={6}
                        value={confirmPin}
                      />
                    </div>
                  </div>
                  {pinError && <p className="error">{pinError}</p>}
                </div>

                <div class="row justify-content-center mt-5">
                  <div class="col-lg-5 mt-0">
                    <div class="d-grid">
                      <button
                        type="submit"
                        class="btn btn-primary btn-arrow"
                        onClick={(e) => submitHandler(e)}
                      >
                        {" "}
                        {t("login.submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </LoginLandingPage>
  );
};

export default SetPin;
