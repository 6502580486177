import React, { useEffect, useState } from "react";
import CustomerCareForm from "./CustomerCareForm";
import { useLocation, useNavigate } from "react-router";
import { func } from "../../../Utilities/logFunc";
import TicketNumber from "../../../assets/images/ticket.png";

import Calender from "../../../assets/images/date.png";
import Status from "../../../assets/images/status.png";
import { Link } from "react-router-dom";
import { routes } from "../../../Utilities/routesFb";
import { url } from "../../../Utilities/url";
import { getTicket } from "../../../Utilities/controller";
import Loader from "../../Common/Loader";
import TicketIcon from "../../../assets/images/ticket.png";
import TelephoneIcon from "../../../assets/images/telephone-svgrepo-com.svg";

import moment from "moment";
import Pagination from "../../Common/Pagination";
import NonPremiumCRMModal from "../../Subscription/NonPremiumCRMModal";
import { useTranslation } from "react-i18next";
import { logout } from "../../../Utilities/logout";
import { toast } from "react-toastify";
import { toastError } from "../../../Utilities/toast";

const CustomerCare = () => {
  const { t } = useTranslation();
  const location = useLocation();
  // const { active } = location.state;
  // func(active, "lead");
  const [ticketData, setTicketData] = useState([]);
  const [skipCount, setSkipCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const premiumUser = JSON.parse(localStorage.getItem("premiumUser"));
  const [showPremiumPopup, setShowPremiumPopup] = useState(false);
  const fetchTicket = async (skipCount) => {
    setLoading(true);
    func("skip count", skipCount);
    try {
      let payload = {
        skip: skipCount,
        limit: 16,
      };
      const res = await getTicket(payload);
      if (res?.data?.list) {
        setTicketData(res?.data?.list);
        setTotalRecords(res?.data?.count);
        setTotalPages(Math.ceil(res?.data?.count / 12));
        setLoading(false);
      }
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }

      func("error", error?.response?.data?.errors?.[0]?.code);
      toastError(error?.response?.data?.errors?.[0]?.msg)
      if (error?.response?.data?.errors?.[0]?.code == "auth.validation.signin.account_inactive" )
    {  logout() }
      setLoading(false);
      
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSkipCount((prev) => (page - 1) * 12);
    fetchTicket((page - 1) * 12);
  };
  useEffect(() => {
    func("called");
    fetchTicket(0);
    // console.log(!premiumUser || localStorage.getItem("planName") != 'Master')
    if (!premiumUser || localStorage.getItem("planName") != process.env.REACT_APP_GOLD) setShowPremiumPopup(true);
  }, []);

  const getTicketStatus = (ticket) => {
    if (ticket?.status) {
      switch (ticket?.status) {
        case "1":
          return "PENDING";
        case "2":
          return "REPLIED";
        case "3":
          return "IN PROGRESS";
        case "4":
          return "APPROVED";
        case "5":
          return "REJECTED";
        case "6":
          return "AUTO APPROVED";
        default:
          break;
      }
    } else {
      return "PENDING";
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div style={{ position: "relative", paddingBottom: "40px" }}>
      <div class="row gy-5">
        {/* <CustomerCareForm /> */}
        <div class="col-12">
          <div class="mb-5 pb-3 row justify-content-between align-items-center">
            <div class="col">
              <h2 class="h3 bodyExtraBoldFont mb-0">
                {t("crm.your_complaints")}
              </h2>
            </div>
            <div class="col-xxl-3 col-xl-4 col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="d-grid">
                <Link
                  to={routes.raiseACompaint}
                  class="btn btn-arrow btn-secondary"
                >
                  {t("crm.raise_a_complaint")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-4">
        {ticketData.length > 0 ? (
          ticketData.map((ele, ind) => (
            <div class="col-xxl-3 col-lg-4 col-md-6" key={ind}>
              <div class="card complaintCard">
                <div
                  class="card-body"
                  onClick={() =>
                    navigate("ticket-details/" + ele?._id, {
                      state: ele?.callTime,
                    })
                  }
                >
                  {premiumUser && ele?.callTime ? (
                    <div class="row g-2 align-items-stretch">
                      <div class="col-3">
                        <div class="d-flex flex-column h-100 align-items-center bg-secondary rounded-2 text-center py-1 px-1 ">
                          <div class="icon mb-1">
                            <img class="icon-50" src={TicketNumber} alt="" />
                          </div>
                          <h6 class="fs-7 mb-1 bodyNormalFont text-truncate w-100">
                            {t("crm.ticket_number")}
                          </h6>
                          <h6 class="fs-6 mb-0 bodyNormalFont">{ele?.code}</h6>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="d-flex flex-column h-100 align-items-center bg-secondary rounded-2 text-center py-1 px-1 ">
                          <div class="icon mb-1">
                            <img class="icon-50" src={Calender} alt="" />
                          </div>
                          <h6 class="fs-7 mb-1 bodyNormalFont">{t("crm.date")}</h6>
                          <h6 class="fs-6 mb-0 bodyNormalFont">
                            {moment(ele?.createdAt).format("DD/MM/YYYY")}
                          </h6>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="d-flex flex-column h-100 align-items-center bg-secondary rounded-2 text-center py-1 px-1 ">
                          <div class="icon mb-1">
                            <img class="icon-20" src={TelephoneIcon} alt="" />
                          </div>
                          <h6 class="fs-7 mb-1 bodyNormalFont">
                            {" "}
                            {t("crm.call_back")}
                          </h6>
                          <h6 class="fs-65 mb-0 bodyNormalFont">
                            {ele?.callTime}
                          </h6>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="d-flex flex-column h-100 align-items-center bg-secondary rounded-2 text-center py-1 px-1 ">
                          <div class="icon mb-1">
                            <img class="icon-50" src={Status} alt="" />
                          </div>
                          <h6 class="fs-7 mb-1 bodyNormalFont">
                            {t("crm.status")}
                          </h6>
                          <h6 class="fs-6 mb-0 bodyNormalFont w-100">
                            {
                              // getTicketStatus(ele)?.length > 8 ? (
                              //   <marquee>{getTicketStatus(ele)}</marquee>
                              // ) : (
                              getTicketStatus(ele)
                              // )
                            }
                          </h6>
                        </div>
                      </div>
                      <div class="col-12" style={{ height: "50px" }}>
                        <div class="d-flex flex-column h-100 bg-secondary rounded-bottom-2 p-2 ">
                          <h6 class="fs-6 mb-2 bodyNormalFont">
                            {t("crm.subject")}: {ele?.category?.name}
                          </h6>
                          <h6 class="fs-6 mb-0 bodyNormalFont d11-ticket-text">
                            {ele?.description}
                          </h6>
                          {/* {ele?.screenShot?.default && (
                        <img
                          src={url?.imageUrl + ele?.screenShot?.default}
                          alt=""
                          class="img-fluid rounded-1"
                        />
                      )} */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div class="row g-2 align-items-stretch">
                      <div class="col-4">
                        <div class="d-flex flex-column h-100 align-items-center bg-secondary rounded-2 text-center py-1 px-2 ">
                          <div class="icon mb-1">
                            <img class="icon-50" src={TicketNumber} alt="" />
                          </div>
                          <h6 class="fs-7 mb-1 bodyNormalFont text-truncate w-100">
                            {t("crm.ticket_number")}
                          </h6>
                          <h6 class="fs-6 mb-0 bodyNormalFont">{ele?.code}</h6>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="d-flex flex-column h-100 align-items-center bg-secondary rounded-2 text-center py-1 px-2 ">
                          <div class="icon mb-1">
                            <img class="icon-50" src={Calender} alt="" />
                          </div>
                          <h6 class="fs-7 mb-1 bodyNormalFont">
                            {t("crm.date")}
                          </h6>
                          <h6 class="fs-6 mb-0 bodyNormalFont">
                            {moment(ele?.createdAt).format("DD/MM/YYYY")}
                          </h6>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="d-flex flex-column h-100 align-items-center bg-secondary rounded-2 text-center py-1 px-2 ">
                          <div class="icon mb-1">
                            <img class="icon-50" src={Status} alt="" />
                          </div>
                          <h6 class="fs-7 mb-1 bodyNormalFont">
                            {t("crm.status")}
                          </h6>
                          <h6 class="fs-6 mb-0 bodyNormalFont w-100">
                            {
                              // getTicketStatus(ele)?.length > 8 ? (
                              //   <marquee>{getTicketStatus(ele)}</marquee>
                              // ) : (
                              getTicketStatus(ele)
                              // )
                            }
                          </h6>
                        </div>
                      </div>
                      <div
                        class="col-12"
                        style={{ height: "50px" }}
                        onClick={() => navigate("ticket-details/" + ele?._id)}
                      >
                        <div class="d-flex flex-column h-100 bg-secondary rounded-bottom-2 p-2 ">
                          <h6 class="fs-6 mb-2 bodyNormalFont">
                            {t("crm.subject")}: {ele?.category?.name}
                          </h6>
                          <h6 class="fs-6 mb-0 bodyNormalFont d11-ticket-text">
                            {ele?.description}
                          </h6>
                          {/* {ele?.screenShot?.default && (
                      <img
                        src={url?.imageUrl + ele?.screenShot?.default}
                        alt=""
                        class="img-fluid rounded-1"
                      />
                    )} */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div class="row justify-content-center">
            <div
              class="col-12 justify-content-center align-items-center"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <img
                style={{ width: "100px" }}
                src={TicketIcon}
                alt=""
                class="img-fluid rounded-1"
              />
              <h3 class="bodyBoldFont text-center">
                {t("notFound.no_ticket_found")}
              </h3>
              <h6 class="bodyBoldFont mb-5 text-center">
                {t("crm.look_like_not_complaint")}
              </h6>
            </div>
          </div>
        )}
      </div>
      {totalPages > 1 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      ) : (
        ""
      )}
      {showPremiumPopup && (
        <NonPremiumCRMModal
          showModal={showPremiumPopup}
          setShowModal={setShowPremiumPopup}
        />
      )}
    </div>
  );
};

export default CustomerCare;
