import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateRoomIdandPassword } from "../../../Utilities/controller";
import { errors } from "../../../Utilities/error";
import Error from "../../Common/Error";
import { useTranslation } from "react-i18next";

const RoomIdPwdModal = ({
  showRoomPwdModal,
  setShowRoomPwdModal,
  roomId,
  password,
  setRoomId,
  setPassword,
  setRmIdpWdSubmit,
  state,
}) => {
  const [show, setShow] = useState(showRoomPwdModal);
  const [errorRoom, setErrorRoom] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const {t} = useTranslation()

  const handleClose = () => {
    setPassword("");
    setRoomId("");
    setShowRoomPwdModal((prev) => false);
    setShow(false);
  };
  const handlerSubmit = async () => {
    let id = state;
    if (!roomId || roomId.length <= 0) {
      setErrorRoom(errors?.error_please_enter_roomID);
    } else setErrorRoom("");
    if (!password || password.lengh <= 0)
      setErrorPassword(errors?.error_please_enter_password);
    else if (roomId.length > 16 || password.length > 16)
      setErrorPassword("Max length should be 16");
    else {
      let res = await updateRoomIdandPassword({
        id: id, //  scrim id
        data: {
          roomId: roomId,
          password: password,
        },
      });
      if (res) {
        setRoomId(roomId);
        setPassword(password);
        setShowRoomPwdModal((prev) => false);
        setShow(false);
        setRmIdpWdSubmit(true);
      }
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="addRoomIdPassword modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div class="row justify-content-center">
          <div class="col-12">
            <h3 class="bodyBoldFont mb-0 text-center">{t('private_contest.room_id_password')}</h3>
            <div class="row justify-content-center my-4 py-4">
              <div class="col-lg-5">
                <div class="box rounded-2 bg-secondary-subtle py-2">
                  <div class="row justify-content-center">
                    <div class="col-3 text-center">
                      <h5 class="small mb-1">{t('private_contest.room_id')}</h5>
                      <div class="px-2">
                        <input
                          type="text"
                          class="form-control form-control-xs bg-white rounded-1 text-center text-black px-0"
                          value={roomId}
                          onChange={(e) => setRoomId((prev) => e.target.value)}
                        />
                        {errorRoom && <Error error={errorRoom} />}
                      </div>
                    </div>
                    <div class="col-2 position-relative">
                      <div class="verticalDivider verticalDivider-center"></div>
                    </div>
                    <div class="col-3 text-center">
                      <h5 class="small mb-1">{t('private_contest.password')}</h5>
                      <div class="px-2">
                        <input
                          type="text"
                          class="form-control form-control-xs bg-white rounded-1 text-center text-black px-0"
                          value={password}
                          onChange={(e) =>
                            setPassword((prev) => e.target.value)
                          }
                        />
                        {errorPassword && <Error error={errorPassword} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <div class="d-grid">
                  <button
                    class="btn btn-primary btn-arrow"
                    onClick={handlerSubmit}
                  >
                    {t('private_contest.submit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RoomIdPwdModal;
