import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { json, useNavigate } from "react-router";

import { func } from "../../../Utilities/logFunc";
import { userProfileList, followUser } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import Loader from "../../Common/Loader";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { logout } from "../../../Utilities/logout";
import { toastError } from "../../../Utilities/toast";

const TopProfiles = () => {
  let [userProfileData, setUserProfileData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation()

  const fetchTopProfiles = async (totalCount) => {
    setLoading(true);
    try {
      let data = {
        skip: 0,
        limit: 5,
        type: ["appUser"],
        sort: "desc",
        sortBy: "followers",
        action: "topProfile",
      };

      const res = await userProfileList(data);
      if (res?.data?.list) {
        setUserProfileData(res?.data?.list);
        setLoading(false);
      }
      func(res);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      // toastError(error?.response?.data?.errors[0]?.msg);
      if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired" || error?.response?.data?.errors?.[0]?.code == "auth.validation.signin.account_inactive")
      logout()
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopProfiles();
  }, []);

  const userFollow = async (item) => {
    setLoading(true);
    let data;
    if (item?.isFollow) {
      data = {
        action: "unfollow",
        followingUser: item?._id,
      };
    } else {
      data = {
        action: "follow",
        followingUser: item?._id,
      };
    }

    try {
      const res = await followUser(data);
      if (res?.data?.success) {
        fetchTopProfiles();
      }
      func(res);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      setLoading(false)
      toastError(error?.response?.data?.errors?.[0]?.msg)
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        userProfileData?.length > 0 && (
          <div class="col-12">
            <div class="mb-3 d-flex align-items-center justify-content-between">
              <h2 class="h3 bodyBoldFont mb-0">{t('woe.top_profiles')}</h2>
              <Link
                to="/home/world-of-esports/top-profile"
                // onClick={() => navigate("/home/world-of-esports/top-profile")}
              >
               {t('woe.view_all')}
              </Link>
            </div>
            <div class="row g-20">
              {userProfileData.length > 0 &&
                userProfileData.map((data, indp) => (
                  <div class="col-lg mw-lg-20 col-md-4 col-6">
                    <div class="card topProfileCard">
                      <div class="card-body text-center">
                        <div class="card-img mb-3">
                          <img
                            src="/assets/images/profile-icon-medium.png"
                            alt=""
                          />
                        </div>
                        <div class="card-title">
                          <h6 class="mb-2 text-ellipsis_contest">{data?.gamerjiName}</h6>
                          <h6 class="small mb-3">
                            {data?.followers || 0} {t('woe.followers')}
                          </h6>
                        </div>
                        <button
                          class="btn btn-primary btn-sm px-5"
                          style={{ lineHeight: "none" }}
                          onClick={() => userFollow(data)}
                          disabled = {data?._id == JSON.parse(localStorage.getItem('profile'))?.item?._id}
                        >
                          {data?.isFollow ? t('woe.following') : t('woe.follow')}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default TopProfiles;
