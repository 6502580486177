import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../../Utilities/logFunc";
import moment from "moment";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";

import {
  favGameList,
  getAccountProfile,
  getGameList,
  requestUpdateProfile,
} from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";
import { errors } from "../../../Utilities/error";
import { logout } from "../../../Utilities/logout";
import { useProfileDataContext } from "../ProfileContext";
import { toastError, toastSuccess } from "../../../Utilities/toast";

const FavoriteGames = ({ showFavoriteGame, setShowFavoriteGame }) => {
  const [show, setShow] = useState(showFavoriteGame);
  const [allGames, setAllGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState([]);
  var arrIds = [];
  const { liteProfile } = useProfileDataContext();
  const { t } = useTranslation();

  const handleClose = () => {
    setShowFavoriteGame(false);
    setShow(false);
  };

  const getAllGameList = async () => {
    try {
      let data = {
        pagination: {
          pageNo: 1,
          recordPerPage: 100,
          sortBy: "order",
          sortDirection: "asc",
        },
      };
      const res = await favGameList(data);
      // console.log("****",res)
      if (res?.data?.data) {
        let allGames = res?.data?.data;
        var tempArr = [];

        for (let index = 0; index < allGames.length; index++) {
          var element = { ...allGames[index] };

          if (
            JSON.parse(localStorage.getItem("profile"))?.item?.favoriteGames
          ) {
            let index = JSON.parse(
              localStorage.getItem("profile")
            )?.item?.favoriteGames.findIndex((item) => {
              if (item == element._id) {
                return true;
              }
            });

            if (index < 0) {
              element.selected = false;
            } else {
              element.selected = true;
            }
          }

          tempArr.push(element);
        }
        func("tempArr", tempArr);
        setAllGames(tempArr);
      }
      func("gamelist", res);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    getAllGameList();
  }, []);

  const clickHandler = (id, status, index) => {
    var tempArr = [...allGames];
    tempArr[index].selected = !tempArr[index].selected;
    setAllGames(tempArr);

    tempArr.forEach((element) => {
      if (element.selected == true) {
        arrIds.push(element._id);
        setSelectedGame(arrIds);
      }
    });

    // if (status === "add") {
    //   setSelectedGame((prevChecked) => [...prevChecked, id]);
    // } else {
    //   setSelectedGame((prevChecked) =>
    //     prevChecked.filter((item) => item !== id)
    //   );
    // }
  };

  const handlerSubmit = async () => {
    try {
      let data = {
        favoriteGames: selectedGame,
      };
      func("data", data);
      let favgame = localStorage.getItem("profile")?.item?.favoriteGames;
      if (data?.favoriteGames?.length == 0) {
        func("dsfdsgv", data);

        toastError(t('error_Select_atleast_one_game'));
      } else if (data?.favoriteGames?.length <= favgame?.length) {
        func("datsdfdgvfbfgbvbfa", data);

        toastError(t('error_Please_select_at_least_one_more_game'));
      } else {
        func("dasdfsfdgfd1212ta", data);
        const res = await requestUpdateProfile(data);
        if (res?.success) {
          const res = await getAccountProfile();
          localStorage.setItem("profile", JSON.stringify(res?.data));

          setShowFavoriteGame(false);
          setShow(false);
          toastSuccess(t('success_Favourite_Game_updated_successfully'));
          // window.location.reload(false);
        } else {
          toastError(res?.errors?.[0]?.msg);
        }

        func("gamelist", res);
      }
    } catch (error) {
      setShowFavoriteGame(false);
      setShow(false);
      func("avatar 13", error);
      if (
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
      )
        logout();
      toastError(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div class="px-5">
          <h3 class="bodyBoldFont mb-5 text-center">
            {t("right_hand_drop_down.select_your_favourite_game")}
          </h3>
          <div className="row favorite-game-section g-3">
            {allGames.length > 0 &&
              allGames.map((data, indp) => (
                <div class="col-lg-3 col-md-4 col-6" key={indp}>
                  <div
                    class={`card gameCard ${data.selected ? "active" : ""}`}
                    onClick={() =>
                      clickHandler(
                        data?._id,
                        selectedGame.includes(data?._id) ? "remove" : "add",
                        indp
                      )
                    }
                  >
                    <div class="card-body">
                      <div class="card-img" style={{ cursor: "pointer" }}>
                        <img
                          class="w-100"
                          style={{ height: "11.125rem" }}
                          src={url.imageUrl + data?.featuredImage?.default}
                          alt={data?.name}
                        />
                        <div class="card-content" style={{ bottom: "0.5rem" }}>
                          <div class="d-flex align-items-center mx-2">
                            <h6 class="bodyExtraBoldFont">
                              {data?.name?.length > 17 ? (
                                <marquee>{data?.name}</marquee>
                              ) : (
                                data?.name
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="row justify-content-center">
            <div className="col-6">
              <button
                className="btn btn-primary btn-arrow mt-5"
                style={{ width: "100%" }}
                onClick={handlerSubmit}
              >
                {t("right_hand_drop_down.submit")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FavoriteGames;
