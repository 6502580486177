import React, { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { getBanners } from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import { routes } from "../../Utilities/routesFb";
import { url } from "../../Utilities/url";
import Loader from "../Common/Loader";
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import test from "../../../src/assets/images/test.png"
import testtwo from "../../../src/assets/images/test2.png"

import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents"; 
import { toastError } from "../../Utilities/toast";
const Hero = () => {
  const [banners, setBanners] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const fetchBanner = async () => {
    setLoader(true);
    try {
      let data = {
        skip: 0,
        limit: 100,
      };

      const res = await getBanners(data);
      if (res?.data?.list) {
        setBanners(res?.data?.list);
        setLoader(false);
      }
      func(res);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired" ||
        error?.response?.data?.errors?.[0]?.code == "auth.validation.signin.account_inactive"
      )
       { logout(); 
      return;}
      toastError(error?.response?.data?.errors?.[0]?.msg);
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (banners.length > 0) {
      banners.sort((a, b) => a.order - b.order);
    }
  }, [banners]);

  useEffect(() => {
    fetchBanner();
  }, []);

  const handleSlideClick = (event) => {
    console.log(event);
    eventTracking(events.BANNER,{
      MG_BannerID:event._id
    });

    let screen = event?.screen?.code && event?.screen?.code;
    switch (screen) {
      case "contestsList":
        break;
      case "profile":
        break;
      case "applyPromoCode":
        break;
      case "addABalanceWallet":
        break;
      case "URLRedirect":
        window.open(
          event?.url,
          "_blank" // <- This is what makes it open in a new window.
        );
        break;
      case "tournamentLists":
        break;
      case "coinStore":
        break;
      case "leaderboard":
        break;
      case "applyPromoCodePopup":
        break;
      case "dailyLoginRewards":
        break;
      case "editProfile":
        break;
      case "esportsNewsDetails":
        break;
      case "gamerjiPoints":
        break;
      case "more":
        break;
      case "viewAllTopProfiles":
        break;
      case "rewardStore":
        break;
      case "myRewards":
        break;
      case "collegeLeagues":
        break;
      case "worldOfEsports":
        break;
      case "friends":
        break;
      case "ReferAFriend":
        break;
      case "htmlCategories":
        break;
      default:
        break;
    }
  };
  function bannerRedirection(banner_data) {
    //console.log("banner_data", banner_data);
    var url_redirection = '';
    switch (banner_data?.screen?.code) {
      case 'account':
        url_redirection = '/account';
        break;
  
      case 'addBalance':
        url_redirection = '';
        break;
  
      case 'addChangeUsernamePopup':
        url_redirection = '';
        break;
  
      case 'allGames':
        url_redirection = '/home';
        break;
  
      case 'applyPromoCodePopup':
        url_redirection = '';
        break;
  
      case 'coinStore':
        url_redirection = `${routes.home}/${routes.coinStore}`;
        break;
  
      case 'collegeLeagues':
        url_redirection = '';
        break;
  
      case 'collegeLeaguesDetails':
        url_redirection = '/collegiate-details';
        break;
  
      case 'contestDetails':
        url_redirection = `${routes.home}/${routes.contestDetails}`;
        break;
  
      case 'contestsList':
        let gameType = banner_data?.gameType
        let gameArray = new Array(gameType);
        //  console.log(banner_data?.gameType?.game)
        localStorage.setItem("activeGameId", banner_data?.gameType?.game?._id);
        localStorage.setItem("activeGame", banner_data?.gameType?.game?.name);
        localStorage.setItem("activeGameTypeName", banner_data?.gameType?.name);
        localStorage.setItem("activeGameType", JSON.stringify(gameArray));
        // url_redirection = `${routes.home}/${routes.gameTournament}`;
        navigate( `/${routes.home}/${routes.gameTournament}`, { state: gameType?.name });
        break;
  
      case 'customerCare':
        url_redirection = `${routes.home}/${routes.customerCare}`;
        break;
  
      case 'dailyLoginRewards':
        url_redirection = '';
        break;
  
      case 'dailyLoginRewardsPopup':
        url_redirection = '';
        break;
  
      case 'dobAndStateValidation':
        url_redirection = '';
        break;
  
      case 'editProfile':
        url_redirection = `${routes.home}/${routes.myProfile}`;
        break;
  
      // Logic for World of eSports Sub Screens
      case 'esportsNewsDetails':
        url_redirection = `${routes.home}/${routes.newsDetails}`;
        break;
  
      case 'friends':
        url_redirection = '';
        break;
  
      case 'gameType':
        url_redirection = '';
        break;
  
      case 'gamerjiPoints':
        url_redirection = ''
  
      case 'htmlCategories':
        url_redirection = '';
        break;
  
      case 'htmlGames':
        url_redirection = '';
        break;
  
      case 'howToPlay':
        url_redirection = '';
        break;
  
      case 'infoPopup':
        url_redirection = '';
        break;
  
      case 'joinContestWalletPopup':
        url_redirection = '';
        break;
  
      case 'joinTournamentWalletPopup':
        url_redirection = '';
        break;
  
      case 'joinViaInviteCodePopup':
        url_redirection = '';
        break;
  
      case 'leaderboard':
        url_redirection = '/home/leader-board';
        break;
  
      case 'legality':
        url_redirection = '';
        break;
  
      case 'linkBankOrUpiAccount':
        url_redirection = '';
        break;
  
      case 'mobileAndEmailVerify':
        url_redirection = '';
        break;
  
      case 'more':
        url_redirection = '/more';
        break;
  
      case 'myContests':
        localStorage.setItem("activeGameId", banner_data?.game?._id);
        localStorage.setItem("activeGame", banner_data?.game?.name);
        localStorage.setItem("activeGameType", JSON.stringify(banner_data?.gameType));
        url_redirection = '/my-contest';
        break;
  
      case 'myRecentTransactions':
        url_redirection =`${routes.home}` ;
        break;
  
      case 'myRewards':
        url_redirection = '/home/reward-store';
        break;
  
      case 'otherUserProfile':
        url_redirection = '/user-profile';
        break;
  
      case 'paymentGateway':
        url_redirection = '';
        break;
  
      case 'paymentOptions':
        url_redirection = '';
        break;
  
      case 'prizePoolPopup':
        url_redirection = '';
        break;
  
      case 'profile':
        url_redirection = '/home/my-profile';
        break;
  
      case 'profileInsights':
        url_redirection = '';
        break;
  
      case 'raiseAComplaint':
        url_redirection = '/home/customer-care';
        break;
  
      case 'redeemRewardsPopup':
        url_redirection = '';
        break;
  
      case 'rewardStore':
        url_redirection = '/home/reward-store';
        break;
  
      case 'rulesPopup':
        url_redirection = '';
        break;
  
      case 'selectFavoriteGamePopup':
        url_redirection = '';
        break;
  
      case 'singleContest':
        url_redirection = '';
        break;
  
      case 'singleTournament':
        url_redirection = '';
        break;
  
      case 'squadRegistration':
        url_redirection = '';
        break;
  
      case 'streamOnGamerji':
        url_redirection = '';
        break;
  
      case 'ticketDetails':
        url_redirection = '/hpme/customer-care';
        break;
  
      case 'tournamentDetails':
        url_redirection = '/home/tounament-details';
        break;
  
        case 'tournamentLists':
       
        localStorage.setItem("activeGameId", banner_data?.gameType?.game?._id);
        localStorage.setItem("activeGame", banner_data?.gameType?.game?.name);
        localStorage.setItem("tournamentIdGame", banner_data?.gameType?.game?.tournamentGameID);
        localStorage.setItem("activeGameType", JSON.stringify(banner_data?.gameType));
        localStorage.setItem("activeGameTypeName", banner_data?.gameType?.name);
        // url_redirection = `${routes.home}/${routes.gameTournament}`;
        navigate( `/${routes.home}/${routes.gameTournament}`, { state: "tournamentLists" });
        break;
  
      case 'tournamentTimingPopup':
        url_redirection = '';
        break;
  
      case 'URLRedirect':
        window.open(
          banner_data?.url,
          "_blank" // <- This is what makes it open in a new window.
        );
        break;
  
      case 'updateEmailPopup':
        url_redirection = '';
        break;
  
      case 'verifyEmail':
        url_redirection = '';
        break;
  
      case 'videos':
        url_redirection = '';
        break;
  
      // 
      case 'viewAllEsportsNews':
        url_redirection = `${routes.home}/${routes.news}`;
        break;
  
      case 'viewAllMedals':
        url_redirection = '';
        break;
  
      // 
      case 'viewAllTopProfiles':
        url_redirection = `${routes.home}/${routes.topProfiles}`;
        break;
  
      // 
      case 'viewAllVideos':
        url_redirection = '';
        break;
  
      case 'withdrawal':
        url_redirection = '';
        break;
  
      case 'worldOfEsports':
        url_redirection = `${routes.home}/${routes.worldOfEsports}`;
        break;
  
      case 'directDownloadAPKs':
        url_redirection = '';
        break;
  
    }
    return url_redirection;
  }
  const   onClickBannerAdOwlCarousel = (value) => {
    // console.log(value)

    let data = {
      type: "2",
      banner: value._id,
      platform: "webapp",
    }
    console.log('Click:value ', value);

    // bannerAdLogAPICall(data);
    if (value.url === "" || value.url == null ) {
      var url_redirection = bannerRedirection(value);
      console.log('Banner URL: ', url_redirection);
       window.location.href = url_redirection;
    } else {
      // window.open(value.url, '_blank');
      var url_redirection = bannerRedirection(value);
      console.log('Banner URL: ', url_redirection);
      window.location.href = url_redirection;
    }
  };
  return loader ? (
    <Loader />
  ) : (
    <div className="col-12">
      <Swiper
        className="dashBoardClubSlider commonSlider"
        spaceBetween={20}
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
        navigation
        pagination={{ clickable: true, el: ".swiper-pagination" }}
        breakpoints={{
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
        }}
      >
        {banners.length > 0 &&
          banners.map((data, ind) => (
            <>
            <SwiperSlide
              class="swiper-slide"
              key={ind}
              onClick={() => handleSlideClick(data)}
            >
              {" "}
                <img
                  className="rounded10"
                  key={ind}
                  src={url.imageUrl + data?.img?.default}
                  alt={data?.screen?.name}
                  onClick={() => onClickBannerAdOwlCarousel(data)}
                />
              
            </SwiperSlide>
             
            </>
          ))}
        <div class="swiper-pagination"></div>
      </Swiper>
    </div>
  );
};

export default Hero;
