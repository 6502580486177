import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import FeaturedTournament from "./FeaturedTournament";
import AllGames from "./AllGames";

import { func, func1 } from "../../Utilities/logFunc";
import { getAccountDailyReward, getAccountProfile, getDailyReward, isUserPremium } from "../../Utilities/controller";
import DailyLoginModal from "../Profile/DailyLoginModal";
import DailyLoginRewardModal from "./DailyLoginReward/DailyLoginRewardModal";
import JoinViaInviteCodeModal from "./JoinViaCode/JoinViaInviteCodeModal";
import TangerinePaymentStatus from "../Subscription/TangerinePaymentStatus";
import { useSearchParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useProfileDataContext } from "./ProfileContext";
import { logout } from "../../Utilities/logout";
import { toastError } from "../../Utilities/toast";

const Main = ({ handleClickGame }) => {
  const [accountDailyReward, setAccountDailyReward] = useState(null);
  const [showDLR, setShowDLR] = useState(false);
const [dailyRewardList, setDailyRewardList] = useState([]);
const [showJVC, setShowJVC] = useState(true)
  const [searchParams] = useSearchParams()
  const { profileData, updateProfileData } = useProfileDataContext()
  const [isTangerineSubscription, setIsTangerineSubscription ] = useState(false)
  const [tangerineParams, setTangerineParams ] = useState({})
  const location = useLocation();
    useEffect(() => {
 
    })
    

  const fetchDailyRewardList = async (pageNo) => {
    try {
      //   console.log("user_data", user_data);

      let data = {
        skip: 0,
        limit: 100,
        day: accountDailyReward?.currentDay,
        sortBy: "day",
        sort: "asc",
      };

      const res = await getDailyReward(data);

      if (res?.data?.list) {
        setDailyRewardList(res?.data?.list);
      }
      func(res);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };
 
  
  const fetchAccountDailyRewardList = async () => {
    try {
      const res = await getAccountDailyReward();

      if (res?.data?.success) {
        setAccountDailyReward(res?.data?.item);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      toastError(error?.response?.data?.errors?.[0]?.msg)
      if (error?.response?.data?.errors?.[0]?.code == "auth.validation.signin.account_inactive" )
      logout()
    }
  };
  // const fetchGetSettings = ()=>{
  //   try{
  //     const res = await getSettingsList()
  //   }
  // }
  useEffect(() => {
    if(localStorage.getItem('token'))
    {
      fetchAccountDailyRewardList()
      fetchDailyRewardList()
    }
  

    if(searchParams.get("correlatorId")) {
      const params = {};
      for (let entry of searchParams.entries()) {
        // console.log(entry[1])
        params[entry[0]] = entry[1].replaceAll(' ', '+')
        // params.push(entry);
      }
      setTangerineParams(params)
      setIsTangerineSubscription(true);
    }
    if (localStorage.getItem('token') && searchParams.get('code'))
    localStorage.removeItem('code')
    // fetch()
  }, []);
  return (
    <div class="content">
      <div className="content-inner">
      <div class="row gy-4">
        {/* <ToastContainer /> */}
        {isTangerineSubscription && <TangerinePaymentStatus show={isTangerineSubscription} tangerineParams={tangerineParams} closePaymentPopUp={() => setIsTangerineSubscription(false)}/> }
        <Hero />
        <FeaturedTournament />
        <AllGames handleClickGame={handleClickGame} />
        {
          dailyRewardList.length >= accountDailyReward?.currentDay && accountDailyReward?.isDailyRewardPopup  ? <DailyLoginRewardModal showDLR={true} setShowDLR={setShowDLR} />:""
        }
        {
          searchParams.get('code') && 
          <JoinViaInviteCodeModal showJVC={showJVC} setShowJVC={setShowJVC} code ={searchParams.get('code')} />
        }
        
        </div>
      </div>
    </div>
  );
};

export default Main;
